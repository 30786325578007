<template>
  <v-container id="body" fluid class="d-flex justify-center">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="login-logo-bg d-flex flex-column justify-center align-center"
      >
        <img src="../assets/logo_eb.svg" alt="logo Exploding Bands" />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="login-form-bg d-flex flex-column justify-center align-center"
      >
        <v-card
          v-if="!lockedOut"
          id="container-login"
          class="d-flex flex-column w-100 custom-card-width"
          @keydown.enter="checkUsersLogin()"
        >
          <v-card-title class="text-center my-10">
            <v-row align="center" justify="center">
              <v-col cols="12">
                <h1>Bentornato</h1>
              </v-col>
              <v-col cols="12">
                <h5 class="text-h7 font-weight-regular">
                  Accedi alla piattaforma con le tue
                  credenziali
                </h5>
              </v-col>
            </v-row>
          </v-card-title>
          <label>Username</label>
          <v-text-field
            v-model="username"
            label="Username"
            outlined
            single-line
            class="white mb-10"
            hide-details
          />
          <label>Password</label>
          <v-text-field
            v-model="password"
            label="Password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            outlined
            single-line
            class="white"
            hide-details
            @click:append="show1 = !show1"
          />
          <div
            id="passwordReset"
            class="d-flex justify-space-between align-center"
          >
            <v-checkbox
              v-model="rememberMe"
              label="Ricordami"
              style="visibility:hidden"
            />
            <v-btn
              id="passwordclickReset"
              text
              class="body-2 "
              @click="passwordReset = true"
            >
              Recupera password
            </v-btn>
          </div>
          <div id="action">
            <v-btn
              id="btnAccedi"
              color="primary"
              rounded
              @click="checkUsersLogin()"
            >
              Accedi
            </v-btn>
          </div>
          <div>
            <v-alert
              v-model="errorLogin"
              type="error"
              outlined
              class="mt-6"
            >
              Il campo username/email o password sono errati
            </v-alert>
            <v-alert
              v-model="voidField"
              outlined
              type="warning"
              class="mt-6"
            >
              I campi non possono essere vuoti
            </v-alert>
            <v-alert
              v-if="remaining_tentatives != 3"
              icon="mdi-alert"
              outlined
              type="warning"
              class="mt-6"
            >
              Rimangono {{ remaining_tentatives }} tentativi di
              accesso
            </v-alert>
          </div>
        </v-card>
        <v-card v-else id="container-login">
          <v-card-title id="titleAccesso " class="text-center">
            Sei stato bloccato dal sistema.
          </v-card-title>
          <v-card-text>
            Siamo spiacenti, hai esaurito i tentativi disponibili
            per il login. Per essere sbloccato, contatta
            l'amministrazione di Grifo Multimedia.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="passwordReset" width="600">
      <v-card>
        <v-card-title> Reset della Password </v-card-title>
        <div id="containerTextField">
          <v-form ref="form" v-model="valid" lazy-validation>
            <p class="mb-5">
              Inserisci l'indirizzo e-mail utilizzato per la tua
              registrazione, e ti invieremo su quello un link per
              il reset della password.
            </p>
            <v-text-field
              v-model="recoverPassword"
              persistent-hint
              label="Indirizzo email"
              :rules="[rules]"
              @keyup="delayValidation()"
            />
          </v-form>
        </div>
        <div id="containerbuttonResetPassword">
          <v-btn
            id="buttonResetPassword"
            rounded
            :disabled="!emailIsValid(recoverPassword)"
            color="primary"
            @click="resetPassword()"
          >
            Invia
          </v-btn>
          <v-btn
            id="closeButtonResetPassword"
            rounded
            color="red"
            @click="passwordReset = false"
          >
            Chiudi
          </v-btn>
        </div>
        <div id="alertEmail">
          <v-alert
            v-model="successEmailReset"
            outlined
            type="success"
          >
            Ti abbiamo inviato le istruzioni tramite email per
            reimpostare la password
          </v-alert>
        </div>
      </v-card>
    </v-dialog>
    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbar"
      :color="color_snackbar"
      :timeout="timeout"
    >
      {{ customMsg }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
    <FullScreenLoading :retrieving-templates-prop="retrievingTemplates" />
    <!--- SNACKBAR --->
  </v-container>
</template>

<script>
import axios from 'axios';
import FullScreenLoading from '@/components/FullScreenLoading.vue';
import user from '@/helpers/mixins/user';

export default {
  components: { FullScreenLoading },
  mixins: [user],
  data() {
    return {
      remaining_tentatives: 3,
      snackbar: false,
      color_snackbar: 'primary',
      timeout: '5000',
      customMsg: '',
      valid: true,
      username: '',
      iduserToEventuallyLock: '',
      password: '',
      timer: 1000,
      users: [],
      show1: false,
      passwordReset: false,
      retrievingTemplates: false,
      recoverPassword: '',
      successEmailReset: false,
      errorLogin: false,
      voidField: false,
      rules: true,
      rememberMe: false,
    };
  },
  computed: {
    lockedOut() {
      return this.remaining_tentatives == 0 || sessionStorage.lockedEB;
    },
  },
  /* beforeRouteEnter(to, from, next) {
        next(() => {
            /* document
                .getElementsByTagName("main")[0]
                .classList.remove("scrollable", "scrollbar-grey");
        });
    }, */
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (to.path == '/login') this.$nextTick(this.routeLoaded);
    },
    passwordReset(val) {
      if (val) {
        this.recoverPassword = '';
        this.successEmailReset = false;
      }
    },
  },
  beforeCreate() {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    window.scrollTo(0, 0);
  },
  /* created() {
        axios.post(process.env.VUE_APP_API_URL + "/users/all").then(res => {
            this.users = res.data.user;
            console.log(this.users);
            this.dataLoaded = true; // Imposto il caricamento a completato
        });
    }, */
  mounted() {
    this.routeLoaded();
  },
  methods: {
    redirectDefaultView(role) {
      const route = user.methods.getDefaultPage(role);

      this.$router.push(`${route}`).catch((e) => {
        console.error(e);
      });
    },
    routeLoaded() {
      if (document.getElementsByTagName('main').length > 0) {
        document
          .getElementsByTagName('main')[0]
          .classList.remove('scrollable');
      }
    },
    delayValidation() {
      if (this.recoverPassword === '') {
        this.$refs.form.resetValidation();
      }
      // Delaying the function execute
      if (this.timer) {
        window.clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if (this.recoverPassword !== '') {
          this.$refs.form.validate();
          this.rules = this.emailIsValid(this.recoverPassword)
            ? true
            : 'Email non valida';
        }
      }, 1000);
    },

    checkUsersLogin() {
      const { username } = this;
      const { password } = this;
      if (username === '' || password === '') {
        this.voidField = true;
      } else {
        this.retrievingTemplates = true;
        axios
          .post(`${process.env.VUE_APP_API_URL}/user/login`, {
            username,
            password,
          })
          .then((res) => {
            if (String(res.status).match(/^2/)) {
              if (res.data.user.locked === 1) {
                this.remaining_tentatives = 0;
                sessionStorage.lockedEB = true;
              } else {
                this.voidField = false;
                this.errorLogin = false;
                this.remaining_tentatives = 3;
                let { photo } = res.data.user;
                if (
                  photo.toLowerCase() === 'photo'
                                    || photo === ''
                ) {
                  photo = require('@/assets/avatar_utenti_default.png');
                }

                const token = res.data.access_token;
                const nameSurname = `${res.data.user.name} ${res.data.user.surname}`;
                const { user } = res.data;
                user.userId = res.data.user.id;
                user.nameSurname = nameSurname;
                this.$store.commit('login', {
                  user,
                  token,
                });
                this.redirectDefaultView(user.role);
              }
            }
          })
          .catch((error) => {
            this.errorLogin = false;
            if (
              error
                            && error.response
                            && (error.response.status === 401
                                || (error.response.data
                                    && error.response.data.message
                                    && error.response.data.message
                                        === 'Invalid Credentials'))
            ) {
              this.voidField = false;
              this.errorLogin = true;
              const userToEventuallyLock = username;
              if (this.remaining_tentatives === 1) {
                // Ero all'ultimo tentativo disponibile
                if (userToEventuallyLock !== '') {
                  axios
                    .post(
                      `${process.env.VUE_APP_API_URL}/user/update`,
                      {
                        username: userToEventuallyLock,
                        locked: 1,
                      },
                    )
                    .catch((err) => {
                      this.customMsg = `Errore nella modifica dell'utente:${err}`;
                      this.color_snackbar = 'red darken-1';
                      this.snackbar = true;
                    });
                }
                sessionStorage.lockedEB = true;
              }
              this.remaining_tentatives -= 1;
            }
            if (!this.errorLogin) {
              this.customMsg = `Errore nella connessione al server per il login. Controllare che il server stia funzionando correttamente. Errore dettagliato: \n${error}`;
              this.color_snackbar = 'red darken-1';
              this.snackbar = true;
              console.log(error);
            }
          })
          .finally(() => {
            this.retrievingTemplates = false;
          });
      }
    },

    /* searchEmail(stringEmail) {
            //this.apiUserAll();
            for (var i = 0; i < this.users.length; i++) {
                var email = this.users[i].email;

                if (stringEmail == email) {
                    return true;
                }
            }
            return false;
        }, */
    emailIsValid(email) {
      const regex_email_valida = /^\w+([.-]?\w+)+@\w+([.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;
      return regex_email_valida.test(email);
    },
    resetPassword() {
      const email = this.recoverPassword;
      /* var delay = (function() {
                var timer = 0;
                return function(callback, ms) {
                    clearTimeout(timer);
                    timer = setTimeout(callback, ms);
                };
            })(); */

      if (email !== '') {
        this.retrievingTemplates = true;
        axios
          .post(`${process.env.VUE_APP_API_URL}/recover/resetpwd`, {
            email,
          })
          .then((res) => {
            if (String(res.status).match(/^2/)) {
              // delay(email, 250);
              this.alertEmailExisting = false;
              this.warningEmail = false;
              this.successEmailReset = true;
            }
          })
          .catch((error) => {
            console.error(error);

            this.customMsg = error;
            this.color_snackbar = 'red darken-1';
            this.snackbar = true;
          })
          .finally(() => {
            this.retrievingTemplates = false;
          });
      } else if (email == '') {
        this.alertEmailExisting = false;
        this.successEmailReset = false;
        this.warningEmail = true;
      } else {
        this.warningEmail = false;
        this.successEmailReset = false;
        this.alertEmailExisting = true;
      }
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#body {
    /* background: url("./../assets/4Ad7ukUd.jpeg") center center; */
    background-color: #4934c8;
    height: 100vh;
    max-width: 100vw;
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
}
/* #action {
    display: flex;
    justify-content: center;
    margin-top: 15px;
} */
.scrollable {
    max-height: none;
    overflow-y: hidden;
}

#btnAccedi {
    width: 100%;
    height: 3rem;
    font-weight: 600;
    border-radius: 8px !important;
    background: rgb(124, 44, 255);
    background: linear-gradient(
        180deg,
        rgba(124, 44, 255, 1) 0%,
        rgba(40, 22, 255, 1) 100%
    );
}

#passwordReset {
    width: 100%;
    padding: 0 0 30px 0;
    text-align: center;
}
#passwordclickReset {
    cursor: pointer;
    font-weight: 500;
    color: #4934c8;
    text-transform: capitalize;
}
#containerTextField {
    width: 500px;
    margin: auto;
    margin-top: 20px;
}
#containerbuttonResetPassword {
    display: flex;

    justify-content: space-around;
    margin: 30px 180px 0 180px;
}
#buttonResetPassword {
    text-transform: capitalize;
    width: 120px;
}
#buttonResetPassword:disabled {
    background-color: lightgrey;
}
#closeButtonResetPassword {
    color: #fff;
    text-transform: capitalize;
}
#alertEmail {
    width: 450px;
    padding: 20px;
    margin: auto;
}

#titleAccesso {
    text-align: center;
    font-size: 1em !important;
}

#container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

#container > .half-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
}

.login-form-bg {
    background: url('../assets/login-form-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fbf9ff;
}

.login-logo-bg {
    background: url('../assets/login-logo-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #4e5ac3;
}

h1 {
    font-size: 2.125rem;
    font-weight: 800;
}

.custom-card-width {
    width: 35em; /* 448px in em */
    background-color: transparent;
    box-shadow: none !important;
}
</style>
