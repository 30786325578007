<template>
  <div>
    <v-toolbar class="sticky_toolbar" align="center" height="80">
      <v-form ref="form">
        <v-text-field
          v-model="reportName"
          hide-details
          label="Nome report"
          :rules="[rules.required]"
          class="mx-3"
          outlined
        />
      </v-form>

      <v-spacer />

      <v-form ref="form2">
        <v-autocomplete
          v-model="customer_id"
          hide-details
          :rules="[rulesCustomer.required]"
          class="mx-3"
          :items="items"
          no-data-text="Nessuna azienda disponibile"
          label="Azienda"
          outlined
        />
      </v-form>

      <v-btn
        class="mr-5 white--text disabled_btn"
        :disabled="idReport == -1"
        rounded
        color="#F8184C"
        @click="dialogNextStep = true"
      >
        <v-icon>mdi-delete</v-icon>Elimina
      </v-btn>

      <v-btn
        :disabled="reportSaved"
        class="ml-5 white--text save_btn"
        rounded
        color="#5E4DDC"
        width="125px"
        @click="saveReport"
      >
        <v-icon small>
          mdi-content-save
        </v-icon>Salva
      </v-btn>
      <v-dialog v-model="dialogNextStep" persistent width="400">
        <v-card>
          <v-card-title>
            Eliminazione del report
          </v-card-title>
          <v-card-text>
            Sei davvero sicuro di voler eliminare questo report?
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn
              icon
              text
              color="primary"
              @click="dialogNextStep = false"
            >
              No
            </v-btn>
            <v-btn
              icon
              text
              color="primary"
              @click="deleteReport()"
            >
              Si
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbar"
      :color="color_snackbar"
      :timeout="timeout"
    >
      {{ customMsg }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
    <!--- SNACKBAR --->
  </div>
</template>

<script>
import Axios from 'axios';

export default {
  name: 'WizardHeader',
  data() {
    return {
      snackbar: false,
      color_snackbar: 'primary',
      timeout: '5000',
      customMsg: '',
      pageLoaded: true,
      typingTimer: 0, // timer identifier
      doneTypingInterval: 1500,
      dialogNextStep: false,
      items: [],
      rules: {
        required: (value) => !!value || 'Inserisci un nome!',
      },
      rulesCustomer: {
        required: (value) => value != -1 || "Seleziona un'azienda!",
      },
    };
  },
  computed: {
    reportName: {
      get() {
        return this.$store.getters.report.name;
      },
      set(val) {
        this.$store.commit('updateReport', {
          titolo_report: val,
        });
        if (!this.$store.getters.report.draft) this.$store.commit('updateIsDraft', true);
      },
    },
    customer_id: {
      get() {
        return this.$store.getters.report.customer_id;
      },
      set(val) {
        this.$store.commit('updateReport', {
          customer_id: val,
        });
        if (!this.$store.getters.report.draft) this.$store.commit('updateIsDraft', true);
      },
    },
    idReport: {
      get() {
        return this.$store.getters.report.id;
      },
    },
    lastSave: {
      get() {
        return this.$store.getters.report.lastSave;
      },
    },
    reportSaved: {
      get() {
        return !this.$store.getters.report.draft;
      },
    },
    reportCompleted: {
      get() {
        return this.$store.getters.reportCompleted;
      },
    },
    campagneSelezionate() {
      return (
        this.$store.getters.slides.filter(
          (slide) => slide.type != 'titolo'
                        && slide.type != 'canvas'
                        && slide.type != 'riepilogo',
        ).length > 0
      );
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (to.path == '/wizard') {
        this.items = this.$store.getters['companies/getCompanies'];
      }
    },
  },
  mounted() {
    this.items = this.$store.getters['companies/getCompanies'];
  },
  methods: {
    deleteReport() {
      Axios.post(`${process.env.VUE_APP_API_URL}/report/delete`, {
        id: this.idReport,
      })
        .then(() => {
          this.$store.commit('resetState');
          this.$router.push('/').catch(() => {});
        })
        .catch((err) => {
          this.customMsg = `Errore nell'eliminazione del report: ${err}`;
          this.color_snackbar = 'red darken-1';
          this.snackbar = true;
        });
    },
    saveReport() {
      if (this.reportName.trim() == '') {
        this.customMsg = 'Inserisci un nome per il report!';
        this.color_snackbar = 'red darken-1';
        this.snackbar = true;
        this.$refs.form.validate();
        return;
      }
      if (this.customer_id == -1) {
        this.customMsg = "Seleziona un'azienda per il report!";
        this.color_snackbar = 'red darken-1';
        this.snackbar = true;
        this.$refs.form2.validate();
        return;
      }

      const d = new Date();
      const dateFormatted = `${this.buildDate(d)
      } ${
        d.getHours()
      }:${
        d.getMinutes()
      }:${
        d.getSeconds()}`;

      // Creo il corpo della richiesta
      const body = {
        customer_id: this.$store.getters.report.customer_id,
        author_id: this.$store.getters.idUser,
        draft: false,
        complete: this.reportCompleted,
        report_name: this.reportName,
        updated_at: dateFormatted, // 2020-02-02 16:30
        json_structure: this.$store.getters.report,
      };
      // Se il report è stato già creato, inserisco il parametro che permette di fare l'update (report_id)
      if (this.idReport != -1) body.report_id = this.idReport;
      // Setto l'id report solo se effettivamente esiste, altrimenti avrò sempre una creazione nuova!
      // Se report non esiste, inserisco il parametro created_at la prima volta
      else body.created_at = dateFormatted; // 2020-02-02 16:30

      // Report non ancora esistente
      if (this.idReport == -1) {
        // invio il corpo, creo un primo report nel db, e associo l'id a quello appena creato
        Axios.post(`${process.env.VUE_APP_API_URL}/report/save`, body)
          .then((res) => {
            this.$store.commit('saveReport', res.data.result.id);
            // delete body.created_at;
            body.report_id = body.json_structure.id = res.data.result.id;
            this.$emit('update-saving', true);

            Axios.post(
              `${process.env.VUE_APP_API_URL}/report/save`,
              body,
            )
              .then((res) => {
                this.$store.commit(
                  'saveReport',
                  res.data.result.id,
                );
                this.$store.commit('updateReport', {
                  author_id: body.author_id,
                });
              })
              .finally(() => {
                this.$emit('update-saving', false);
              })
              .catch((err) => {
                this.customMsg = `Errore nel salvataggio del report: ${err}`;
                this.color_snackbar = 'red darken-1';
                this.snackbar = true;
              });
          })
          .catch((err) => {
            this.customMsg = `Errore nel salvataggio del report: ${err}`;
            this.color_snackbar = 'red darken-1';
            this.snackbar = true;
          });
      } else {
        body.author_id = this.$store.getters.report.author_id;
        this.$emit('update-saving', true);

        Axios.post(`${process.env.VUE_APP_API_URL}/report/save`, body)
          .then((res) => {
            this.$store.commit('saveReport', res.data.result.id);
          })
          .finally(() => this.$emit('update-saving', false))
          .catch((err) => {
            this.customMsg = `Errore nel salvataggio del report: ${err}`;
            this.color_snackbar = 'red darken-1';
            this.snackbar = true;
          });
      }
    },
    buildDate(d) {
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join('-');
    },
  },
};
</script>

<style scoped>
.disabled_btn.v-btn.v-btn--disabled,
.save_btn.v-btn--disabled {
    background-color: lightgrey;
}
.sticky_toolbar {
    position: sticky;
    /* top: 48px; */
    z-index: 2;
    background: white;
    -webkit-box-shadow: 0px 10px 18px -8px rgba(143, 132, 143, 0.5);
    -moz-box-shadow: 0px 10px 18px -8px rgba(143, 132, 143, 0.5);
    box-shadow: 0px 10px 18px -8px rgba(143, 132, 143, 0.5);
}
</style>
