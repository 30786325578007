<template>
  <div>
    <div>
      <h3>Slide {{ index + 1 }}</h3>
      <v-divider class="eb_custom_divider" inset />
    </div>
    <div class="font-weight-bold">
      Titolo slide
    </div>
    <v-text-field
      v-model="titolo_slide"
      label="Titolo slide"
      :value="titolo_slide"
      single-line
      outlined
      :disabled="disabled"
      @keyup="$store.commit('updateIsDraft', true)"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TitleSlide',
  props: {
    id_campagna: {
      default: '',
    },
    type: {},
    index: {
      default: -1,
    },
  },
  computed: {
    titolo_slide: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .slides[this.index].titolo_slide;
      },
      set(value) {
        this.$store.commit('updateSlide', {
          id_campagna: this.id_campagna,
          index: this.index,
          titolo_slide: value,
        });
      },
    },
    confirmed: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .confirmed;
      },
    },
    disabled() {
      return this
        .confirmed /* ||
                (this.type == "yt_banner" && this.index == 0) ||
                (this.type == "web_banner" && this.index == 0) ||
                this.index == 1 */;
    },
  },
};
</script>

<style></style>
