import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';
import companies from './modules/companies/index';
// eslint-disable-next-line import/extensions
import mixinUser from '@/helpers/mixins/user';

Vue.use(Vuex);

const getDefaultState = () => ({
  currentUser: {
    userId: '',
    name: '',
    surname: '',
    username: '',
    company_id: '',
    company_name: 'Company_Not_F0und!',
    email: '',
    role: '',
    sub_role: '',
    user_meta: '',
    token: '',
    photo: '',
  },
  report: {
    id: -1,
    name: '',
    author_id: -1,
    customer_id: -1,
    lastSave: null,
    draft: true,
    lastPdfGenerated: '',
    slides: [
      {
        id_campagna: 'eb_titolo',
        campaign_name: 'Titolo',
        type: 'titolo',
        titolo_slide: '',
        sottotitolo_slide: '',
        confirmed: false,
      },
      {
        id_campagna: 'eb_canvas',
        type: 'canvas',
        campaign_name: 'Canvas',
        confirmed: false,
        checked: false,
        titolo_slide: '',
        repeaterItems: [],
        accesses: 0,
      },
      {
        id_campagna: 'eb_riepilogo',
        campaign_name: 'Riepilogo',
        slides: [{ titolo_slide: 'RIEPILOGO NUMERI' }],
        repeaterItems: [],
        type: 'riepilogo',
        confirmed: false,
        checked: false,
      },
    ],
  },
  buildingPdf: false,
  buildingPdfState: 0,
  isSomethingLoading: false,
  snackbar: {
    visible: false,
    message: '',
    timeout: 5000,
    color: 'green',
  },
});
export default new Vuex.Store({
  modules: {
    companies,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: getDefaultState(),
  mutations: {
    updateCurrentProfile(state, payload) {
      const properties = Object.keys(state.currentUser);

      properties.forEach((prop) => {
        if (payload[prop]) state.currentUser[prop] = payload[prop];
      });
      if (payload.name && payload.surname) state.currentUser.nameSurname = `${payload.name} ${payload.surname}`;
    },
    resetState(state) {
      const tempCurrentUser = state.currentUser;
      const newState = getDefaultState();
      newState.currentUser = tempCurrentUser;
      Object.assign(state, newState);

      // this.replaceState({ report: report, buildingPdf: false });
    },
    changeStatusSomethingLoading(state, status = !state.isSomethingLoading) {
      state.isSomethingLoading = status;
    },
    // Login Section
    login(state, {
      user, token,
    }) {
      state.currentUser.token = token;
      const properties = Object.keys(state.currentUser);

      properties.forEach((prop) => {
        if (user[prop]) state.currentUser[prop] = user[prop];
      });

      state.currentUser.role = mixinUser.methods.getRoleFromSubrole(user.role);
      state.currentUser.sub_role = user.role;

      localStorage.setItem('ExplodingBands_token', token);
    },
    logout(state) {
      localStorage.removeItem('ExplodingBands_token');
      delete axios.defaults.headers.common.Authorization;
      state.currentUser.nameSurname = '';
      state.currentUser.role = '';
      state.currentUser.token = '';
      state.currentUser.idUser = '';
      this.commit('resetState');
    },

    // Report Section
    saveReport(state, id) {
      state.report.draft = false;
      state.report.lastSave = new Date().getTime();
      state.report.id = id;
    },
    updateUserMeta(state, userMeta) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(userMeta)) {
        state.currentUser.user_meta[key] = value;
      }
    },
    updateTemplates(state, slides) {
      slides.forEach((el, index) => {
        state.report.slides.splice(index + 1, 0, el);
      });
    },
    loadExistentReport(state, jsonReport) {
      state.report = jsonReport;
    },
    toggleSlide(state, payload) {
      this.getters.getSlideById(payload.campaignId).checked = payload.toggle;
    },
    toggleBuildingPdf(state, toggle) {
      state.buildingPdf = toggle;
    },
    updateBuildingPdfState(state, payload) {
      if (payload.completed === 100) {
        setTimeout(() => {
          payload.completed = 0;
          payload.processedTemplates = '';
        }, 2500);
      }
      state.buildingPdfState = payload;
    },
    updateConfirmed(state, payload) {
      this.getters.getSlideById(payload.id_campagna).confirmed = payload.toggleValue;
      state.report.draft = true;
    },
    updateSlide(state, payload) {
      // Se slide titolo o canvas o riepilogo non avranno id, le riconosco dal tipo
      const slide = this.getters.getSlideById(payload.id_campagna);

      if (payload.confirmed !== undefined) {
        slide.confirmed = payload.confirmed;
      }
      if (payload.titolo_slide !== undefined) {
        if (payload.index !== undefined) {
          slide.slides[payload.index].titolo_slide = payload.titolo_slide;
        } else slide.titolo_slide = payload.titolo_slide;
      }
      if (payload.sottotitolo_slide !== undefined) {
        slide.sottotitolo_slide = payload.sottotitolo_slide;
      }
      if (payload.pos !== undefined) {
        slide.data.pos = payload.pos;
      }
      if (payload.click_link !== undefined) {
        slide.click_link = parseInt(payload.click_link);
      }
      if (payload.impression !== undefined) {
        slide.impression = parseInt(payload.impression);
      }
      if (payload.accesses !== undefined) {
        slide.accesses = parseInt(payload.accesses);
      }
      if (payload.new_name !== undefined) {
        slide.videoPreview = payload.new_name;
      }
    },
    updateIsDraft(state, status) {
      state.report.draft = status;
    },
    updateLastPdfGenerated(state, url) {
      if (url !== undefined && url !== '') {
        state.report.lastPdfGenerated = url;
      }
    },
    toggleConfirmAllSlides(state, status) {
      // status rappresenta stato in cui devono entrare successivamente
      // può essere stringa vuota, vero o falso
      let i = 0;

      if (status === '') {
        state.report.slides.forEach((slide) => {
          if (slide.checked !== undefined) {
            if (slide.checked && !slide.confirmed) {
              slide.confirmed = true;
              i += 1;
            }
          } else if (!slide.confirmed) {
            slide.confirmed = true;
            i += 1;
          }
        });
        if (i > 0) this.commit('updateIsDraft', true);
      } else {
        // lo status sarà vero o falso
        state.report.slides.forEach((slide) => {
          if (slide.checked !== undefined) {
            if (slide.checked) {
              slide.confirmed = status;
              i++;
            }
          } else {
            slide.confirmed = status;
            i += 1;
          }
        });
        if (i > 0) this.commit('updateIsDraft', true);
      }
    },
    updateReport(state, payload) {
      if (payload.titolo_report !== undefined) {
        this.getters.report.name = payload.titolo_report;
      }
      if (payload.customer_id !== undefined) {
        this.getters.report.customer_id = payload.customer_id;
      }
      if (payload.author_id !== undefined) {
        state.report.author_id = payload.author_id;
      }
    },
    removeRow(state, payload) {
      const slide = this.getters.getSlideById(payload.id_campagna);
      slide.data.pos.splice(payload.arrIndex, 1);
    },
    addItem(state, payload) {
      const { repeaterItems } = this.getters.getSlideById(payload.id);
      const index = repeaterItems[repeaterItems.length - 1] === undefined
        ? 0
        : repeaterItems[repeaterItems.length - 1].index + 1;

      repeaterItems.push({
        index,
        item: payload.item,
        value: payload.value,
      });
    },
    removeRepeaterItem(state, payload) {
      const repeater = this.getters.getSlideById(payload.id).repeaterItems;
      const itemIndex = repeater.findIndex(
        (item) => item.index == payload.index,
      );
      repeater.splice(itemIndex, 1);
    },
    commitRepeaterItem(state, payload) {
      const repeater = this.getters.getRepeaterByIndex(
        payload.id,
        payload.index,
      );
      if (payload.item !== undefined) repeater.item = payload.item;
      if (payload.value !== undefined) repeater.value = payload.value;
    },
    toggleSnackbar(state, payload) {
      if (payload.status === false) {
        this.getters.snackbar.visible = false;
      } else {
        this.getters.snackbar.visible = true;
        this.getters.snackbar.message = payload.message;
        this.getters.snackbar.color = payload.color;
      }
    },
  },
  getters: {
    snackbar: (state) => state.snackbar,
    currentUser: (state) => state.currentUser,
    isLoggedIn: (state) => !!state.currentUser.token,
    nameSurname: (state) => state.currentUser.nameSurname,
    isSomethingLoading: (state) => state.isSomethingLoading,
    ruolo: (state) => state.currentUser.role,
    photo: (state) => state.currentUser.photo,
    slides: (state) => state.report.slides,
    idUser: (state) => state.currentUser.userId,
    author_id: (state) => state.report.author_id,
    getSlideById: (state) => (id) => state.report.slides.find((slide) => slide.id_campagna === id),
    getRepeaterByIndex: (state) => (id, index) => {
      // Mi ritorna l'item di un repeater
      const { repeaterItems } = state.report.slides.find(
        (slide) => slide.id_campagna === id,
      );
      const indexItem = repeaterItems.findIndex(
        (item) => item.index === index,
      );

      return repeaterItems[indexItem];
    },
    report: (state) => state.report,
    reportCompleted: (state) => {
      let reportCompleted = true;
      state.report.slides.forEach((slide) => {
        if (slide.checked !== undefined) {
          if (slide.checked && !slide.confirmed) {
            reportCompleted = false;
          }
        } else if (!slide.confirmed) reportCompleted = false;
      });
      return reportCompleted;
    },
    buildingPdf: (state) => state.buildingPdf,
    buildingPdfState: (state) => state.buildingPdfState,
    getSlideTitolo: (state) => state.report.slideTitolo,
    getSlideCanvas: (state) => state.report.slideCanvas,
    getSlideRiepilogo: (state) => state.report.slideRiepilogo,
  },
  actions: {},
});
