const SUCCESS_MESSAGE_SNACKBAR = 'primary';
const ERROR_SNACKBAR = 'red darken-1';

export default {
  SUCCESS_MESSAGE_SNACKBAR,
  ERROR_SNACKBAR,
  PREVIEWS_SIZE: {
    'fb_feed': [{
      width: 320,
      height: 575,
    }],
    'fb+ig_feed': [{
      width: 320,
      height: 594,
    }],
    'ig_feed': [{
      width: 320,
      height: 488,
    }],
    'ig_story': [{
      width: 320,
      height: 594,
    }],
    'preroll': [{
      width: 614,
      height: 666,
    }],
    'discovery': [{
      width: 614,
      height: 666,
    }],
    'static_discovery': [{
      width: 724,
      height: 1286,
    }],
    'yt_banner': [{
      width: 300,
      height: 250,
    },
    {
      width: 468,
      height: 60,
    },
    {
      width: 728,
      height: 90,
    }],
    'web_banner': [{
      width: 300,
      height: 250,
    },
    {
      width: 468,
      height: 60,
    },
    {
      width: 728,
      height: 90,
    }],
    'tt': [{
      width: 426,
      height: 757,
    }],
  },
  ROLES: {
    ADMINISTRATOR: 'administrator',
    MANAGER: 'manager',
    MANAGER_PRO: 'manager_pro',
    MANAGER_BASIC: 'manager_basic',
    MAIL_ONLY_MANAGER: 'mail_only_manager',
  },
  LABEL_ROLES: {
    administrator: 'Amministratore',
    manager: 'Manager',
    manager_pro: 'Manager PRO',
    manager_basic: 'Manager Basic',
    mail_only_manager: 'Manager Solo Mail',
  },
  MANAGER_TYPES: {
    PRO: { value: 'manager_pro', text: 'Manager PRO' },
    BASIC: { value: 'manager_basic', text: 'Manager Basic' },
    MAIL_ONLY: { value: 'mail_only_manager', text: 'Solo Mail' },
  },
};
