import Vue from 'vue';
import VueRouter from 'vue-router';
// import { component } from "vue/types/umd";
import Campagne from '../views/Campagne.vue';
// import Clienti from '../views/Clienti.vue';
import Report from '../views/Report.vue';
import Utenti from '../views/Utenti.vue';
import Wizard from '../views/Wizard.vue';
import PageNotFound from '../components/PageNotFound.vue';
import Impostazioni from '../views/Impostazioni.vue';
import Login from '../views/Login.vue';
import ResetPswd from '../views/Resetpswd.vue';
import store from '../store';
// eslint-disable-next-line import/extensions
import constants from '@/constants';
import DashboardView from '@/views/DashboardView.vue';
import Profilo from '@/views/Profilo.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/dashboard',
    name: 'Campagne dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'Campagne report',
    component: Campagne,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //  path: '/clienti',
  //  name: 'Clienti',
  //  // route level code-splitting
  //  // this generates a separate chunk (about.[hash].js) for this route
  //  // which is lazy-loaded when the route is visited.
  //  /* component: () =>
  //          import(/* webpackChunkName: "about"  "../views/About.vue") */
  //  component: Clienti,
  //  meta: {
  //    requiresAuth: true,
  //  },
  // },
  {
    path: '/profilo',
    name: 'Profilo',
    component: Profilo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/utenti',
    name: 'Utenti',
    component: Utenti,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/wizard',
    name: 'Wizard',
    props: true,
    component: Wizard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/impostazioni',
    name: 'Impostazioni',
    component: Impostazioni,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/reset_password',
    name: 'Resetpswd',
    component: ResetPswd,
  },
  {
    path: '*',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      store.getters.isLoggedIn
      && localStorage.getItem('ExplodingBands_token') !== null
    ) {
      const { role } = store.getters.currentUser;
      if (
        (
          ((role === constants.ROLES.MANAGER
        || role === constants.ROLES.MANAGER_PRO
        || role === constants.ROLES.MANAGER_BASIC)
          && (to.path === '/dashboard' || to.path === '/profilo'))
          || role === constants.ROLES.ADMINISTRATOR)) return next();
      return next('/unauthorized');
    } return next('/login');
  }
  return next();
});
export default router;
