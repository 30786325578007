import Axios from 'axios';

export default {
  data() {
    return {
      BASEURL: '',
      mergedArr: [],
      fbTotCampaigns: 0,
      igTotCampaigns: 0,
      googleTotCampaigns: 0,
      tiktokTotCampaigns: 0,
      errors: [],
    };
  },
  created() {
    this.BASEURL = process.env.VUE_APP_API_URL;
  },
  methods: {
    async getCampaigns() {
      const inizio = new Date();
      const campaingsGoogle = Axios.get(`${this.BASEURL}/google/campaigns`, {});
      const campaingsMeta = Axios.get(`${this.BASEURL}/facebook/campaigns`, {});
      const campaingsTikTok = Axios.get(`${this.BASEURL}/tiktok/campaigns`, {});

      await Axios.all([campaingsMeta, campaingsGoogle, campaingsTikTok])
        .then(
          await Axios.spread(
            (...responses) => {
              if (process.env.NODE_ENV !== 'production') {
                const fine = new Date();
                console.log(
                  `Durata chiamate totali: ${fine - inizio}`,
                );
              }
              const rispostaFB = responses[0]; // FB
              const rispostaYT = responses[1]; // YT
              const rispostaTT = responses[2]; // TT

              const responsesArr = [rispostaFB, rispostaYT, rispostaTT];
              const apiErrors = [];
              responsesArr.forEach((res) => {
                if (res.data.campaigns_errors.length > 0) {
                  let errorMsg = `Nella chiamata per ottenere le campagne
                ci sono stati dei problemi.
              <br/>Ecco la risposta da parte di <b>${res.data.social}</b>: <ul>`;

                  res.data.campaigns_errors.forEach((error) => {
                    errorMsg
                      += `<li>${
                        error.account_name
                      }: ${
                        JSON.stringify(error.error_msg)
                      }</li><br/>`;
                  });

                  errorMsg += '</ul>';
                  apiErrors.push({
                    social: res.data.social,
                    message: errorMsg,
                  });
                }
              });

              // Assegno il numero di tutti gli elementi delle api

              this.fbTotCampaigns = rispostaFB.data.tot_campaigns;
              this.googleTotCampaigns = rispostaYT.data.tot_campaigns;
              this.tiktokTotCampaigns = rispostaTT.data.tot_campaigns;

              const mergedArr = rispostaFB.data.campaigns.concat(
                rispostaYT.data.campaigns,
                rispostaTT.data.campaigns,
              );

              // Funzioni statistiche
              if (process.env.NODE_ENV !== 'production') {
                const endRendering = new Date();
                console.log(
                  `Durata renderizzazione totale: ${
                    endRendering - inizio}`,
                );
              }

              this.mergedArr = this.shuffle(mergedArr);
              this.errors = apiErrors;
            },
            (error) => {
              console.error(error);
            },
          ),
        )
        .catch((error) => {
          let body;
          console.log(error);
          if (error.response) {
            // Request made and server responded
            // console.log(error.response.data);
            //        console.log(error.response.status);
            //       console.log(error.response.headers);
            body = error.response.data.message;
          } else if (error.request) {
            // The request was made but no response was received
            // console.log(error.request);
            body = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log("Error", error.message);
            body = error.message;
          }

          if (error.response && error.response.status && error.response.status === 401) {
            // C.R., il cliente vuole eliminare la visualizzazione dell'errore
            // se ha perso la sessione
            // Purtroppo non sono eccellente con la logica, scrivo il codice come
            // mi è più semplice per non far guai..
          } else { // mostro notifica solo se il codice non esiste o non è 401
            this.$notification.error(body, {
              title: 'Errore di sistema',
              showCloseIcn: true,
              infiniteTimer: true,
              messageIsHTML: true,
            });
          }
        });
      return { campaigns: this.mergedArr, apiErrors: this.errors };
    },
    shuffle(a) {
      let j; let x; let
        i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    },
    getCampaignInsights(idCampaign, additionalParams) {
      return Axios.post(`${this.BASEURL}/${additionalParams.social}/insights/${idCampaign}`, {
        advertiser_id: additionalParams.account_id,
        filters: {since: additionalParams.since, until:  additionalParams.until},
      });
    },
    getAdSetsWithInsights(idCampaign, additionalParams) {
      return Axios.post(`${this.BASEURL}/${additionalParams.social}/insights/${idCampaign}/ad_groups`, {
        advertiser_id: additionalParams.account_id,
        filters: {since: additionalParams.since, until:  additionalParams.until},
      });
    },
    getAdsWithInsights(idCampaign, additionalParams) {
      return Axios.post(`${this.BASEURL}/${additionalParams.social}/insights/${idCampaign}/ad_groups/${additionalParams.id_adGroup}/ads`, {
        advertiser_id: additionalParams.account_id,
        filters: {since: additionalParams.since, until:  additionalParams.until},
      });
    },

  },
};
