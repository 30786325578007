import axios from 'axios';
import constants from '@/constants';

export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods:
    {
      getDefaultPage(role) {
        let route = '/';
        // eslint-disable-next-line default-case
        switch (role) {
          case constants.ROLES.MANAGER:
          case constants.ROLES.MANAGER_BASIC:
          case constants.ROLES.MANAGER_PRO:
            route = '/dashboard';
        }
        return route;
      },
      updateUser(payload) {
        return axios
          .post(
            `${process.env.VUE_APP_API_URL}/user/update`,
            payload,
          );
      },
      getRoleFromSubrole(subrole) {
        let role = constants.ROLES.ADMINISTRATOR;
        // eslint-disable-next-line default-case
        switch (subrole) {
          case constants.ROLES.MANAGER_PRO:
          case constants.ROLES.MANAGER_BASIC:
          case constants.ROLES.MAIL_ONLY_MANAGER:
            role = constants.ROLES.MANAGER;
        }
        return role;
      },
      isCurrentUserAdministrator() {
        return (
          this.$store.getters.currentUser.role.trim()
          === constants.ROLES.ADMINISTRATOR
        );
      },
      isCurrentUserManager() {
        return (
          this.$store.getters.currentUser.role.trim()
          === constants.ROLES.MANAGER
        );
      },
    },
};
