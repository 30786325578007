<template>
  <div>
    <v-row>
      <v-col cols="6" class="py-0 pb-2">
        <div class="font-weight-bold">
          Titolo slide
        </div>
      </v-col>
      <v-col
        class="py-0 pb-2"
        cols="6"
      >
        <div class="font-weight-bold">
          Accessi unici al canvas
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="py-0 pt-2">
        <v-text-field
          v-model="titolo_slide"
          label="Titolo slide"
          single-line
          outlined
          :disabled="confirmed"
        />
      </v-col>
      <v-col cols="6" class="py-0 pt-2">
        <v-text-field
          v-model="accesses"
          single-line
          outlined
          :class="{ 'no-select': confirmed }"
          :disabled="confirmed"
        />
      </v-col>
    </v-row>
    <div class="font-weight-bold mb-2">
      Click su store digitali
    </div>
    <Repeater
      v-for="item in items"
      :id="'eb_canvas'"
      :key="item.index"
      :index="item.index"
      :items="itemsSelectable"
      :item_dropdown_label="'Store digitali'"
      :item_value_label="'Numero di click'"
    />
    <RepeaterInserter
      v-if="items.length != 8"
      :id="'eb_canvas'"
      class="mt-7"
      :item_dropdown_label="'Store digitali'"
      :item_value_label="'Numero di click'"
      :items="itemsSelectable"
      :last="true"
    />
    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbar"
      :color="color_snackbar"
      :timeout="timeout"
    >
      {{ CustomMsg }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
    <!--- SNACKBAR --->
  </div>
</template>

<script>
import axios from 'axios';
import Repeater from '../../../../Repeater';
import RepeaterInserter from '../../../../Repeater_inserter';

export default {
  name: 'CardCanvas',
  components: { Repeater, RepeaterInserter },

  props: {
    id_campagna: {
      default: '',
    },
  },
  data() {
    return {
      itemsSelectable: [],
      snackbar: false,
      color_snackbar: 'primary',
      timeout: 5000,
      CustomMsg: '',
    };
  },
  computed: {
    titolo_slide: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .titolo_slide;
      },
      set(value) {
        this.$store.commit('updateSlide', {
          id_campagna: this.id_campagna,
          titolo_slide: value,
        });
        if (!this.$store.getters.report.draft) this.$store.commit('updateIsDraft', true);
      },
    },
    accesses: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .accesses;
      },
      set(value) {
        value = value.replace(/[^0-9]/g, '');
        if (value == '') value = -1;
        this.$store.commit('updateSlide', {
          id_campagna: this.id_campagna,
          accesses: parseInt(value),
        });
        if (!this.$store.getters.report.draft) this.$store.commit('updateIsDraft', true);
      },
    },
    confirmed: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .confirmed;
      },
    },
    items: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .repeaterItems;
      },
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (to.path == '/wizard') {
        this.loadDigitalStores();
      }
    },
  },
  mounted() {
    this.titolo_slide = 'DATI SUL CANVAS';
    this.loadDigitalStores();
  },
  methods: {
    loadDigitalStores() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/storeandriep/get`, {
          type: 'digital_store',
        })
        .then((res) => {
          if (String(res.status).match(/^2/)) {
            this.itemsSelectable = res.data.result.map(
              (obj) => obj.name.charAt(0).toUpperCase()
                                + obj.name.slice(1),
            );
          }
          this.itemsSelectable.sort();
        })
        .catch(() => {
          this.CustomMsg = `Errore nel caricamento dei dati di tipo ${
            this.typeTab
          }. Gentilmente, contattare l'amministratore di sistema.`;
          this.color_snackbar = 'red darken-1';
          this.snackbar = true;
        });
    },
  },
};
</script>

<style></style>
