<template>
  <div class="mx-3">
    <span class="d-flex align-center">
      <v-icon
        v-if="confirmed !== false"
        color="green accent-4"
      >mdi-checkbox-marked-circle-outline</v-icon>
      <v-checkbox
        v-if="isCheckedInternal !== undefined"
        v-model="isCheckedInternal"
        class="shrink ml-2"
        :disabled="confirmed"
        @change="checkboxUpdated($event, id_campagna)"
      />
      <v-btn :ripple="false" class="ma-2" outlined color="indigo">{{
        type_beautified
      }}</v-btn>
      <span class="d-inline-block text-no-wrap">{{ titleRow }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SidebarRow',
  props: {
    id_campagna: {
      default: -1,
    },
    type: {
      default: '',
    },
    titleRow: {
      default: '',
    },
    checked: {
      default: undefined,
    },
  },
  data() {
    return {
      isCheckedInternal: this.checked,
    };
  },
  computed: {
    type_beautified() {
      switch (this.type) {
        case 'tt':
          return 'TIKTOK';
        case 'fb_feed':
          return 'FB FEED';
        case 'ig_feed':
          return 'IG FEED';
        case 'fb+ig_feed':
          return 'FB + IG FEED';
        case 'ig_story':
          return 'IG STORY';
        case 'preroll':
          return 'PREROLL';
        case 'discovery':
          return 'DISCOVERY VIDEO';
        case 'yt_banner':
          return 'YT BANNER';
        case 'static_discovery':
          return 'DISCOVERY STATICA';
        case 'web_banner':
          return 'WEB BANNER';
        default:
          return (
            this.type.charAt(0).toUpperCase() + this.type.slice(1)
          );
      }
    },
    confirmed() {
      const slide = this.$store.getters.getSlideById(this.id_campagna);
      return slide.confirmed !== undefined ? slide.confirmed : false;
    },
  },
  methods: {
    checkboxUpdated(toggle, campaignId) {
      this.$store.commit('toggleSlide', { toggle, campaignId });
    },
  },
};
</script>

<style scoped>
.v-btn {
    text-transform: none !important;
}
.v-btn:hover:before,
.v-btn:hover:after,
.v-btn:focus:before,
.v-btn:focus::after {
    background-color: transparent;
}
</style>
