<template>
  <v-row class="d-flex align-center">
    <v-col class="py-1" cols="3">
      <v-select
        v-model="item"
        :items="items"
        :disabled="confirmed"
        :label="item_dropdown_label"
      />
    </v-col>
    <v-col
      class="py-1"
      cols="3"
    >
      <v-text-field
        v-model="value"
        hide-details
        :label="item_value_label"
        :value="value"
        :disabled="confirmed"
        outlined
      />
    </v-col>
    <v-col cols="1">
      <v-icon
        :disabled="confirmed"
        color="red"
        @click="removeRow"
      >
        mdi-close-box-outline
      </v-icon>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    id: {},
    index: { default: -1 },
    last: {
      type: Boolean,
      default: false,
    },
    items: {},
    item_dropdown_label: {
      default: '',
    },
    item_value_label: {
      default: '',
    },
  },
  data() {
    return {
      select: '',
    };
  },
  computed: {
    confirmed: {
      get() {
        return this.$store.getters.getSlideById(this.id).confirmed;
      },
    },
    item: {
      get() {
        return this.$store.getters.getRepeaterByIndex(
          this.id,
          this.index,
        ).item;
      },
      set(value) {
        this.$store.commit('commitRepeaterItem', {
          index: this.index,
          id: this.id,
          item: value,
          value: parseInt(this.value),
          last: false,
        });
        this.$store.commit('updateIsDraft', true);
      },
    },
    value: {
      get() {
        return this.$store.getters.getRepeaterByIndex(
          this.id,
          this.index,
        ).value;
      },
      set(value) {
        value = value.replace(/[^0-9]/g, '');
        if (value == '') value = -1;
        this.$store.commit('commitRepeaterItem', {
          index: this.index,
          id: this.id,
          item: this.item,
          value: parseInt(value),
          last: false,
        });
        this.$store.commit('updateIsDraft', true);
      },
    },
  },
  methods: {
    removeRow() {
      this.$store.commit('removeRepeaterItem', {
        index: this.index,
        id: this.id,
      });
      this.$store.commit('updateIsDraft', true);
    },
  },
};
</script>

<style></style>
