<template>
  <div>
    <TitleSlide :index="0" :id_campagna="id_campagna" />
    <div class="font-weight-bold mb-3">
      Dati di riepilogo
    </div>
    <Repeater
      v-for="item in items"
      :id="id_campagna"
      :key="item.index"
      :index="item.index"
      :items="selectItems"
      :item_dropdown_label="'Dati riepilogativi'"
      :item_value_label="'Valore'"
    />
    <RepeaterInserter
      v-if="items.length != 4"
      :id="id_campagna"
      class="mt-7"
      :item_dropdown_label="'Dati riepilogativi'"
      :item_value_label="'Valore'"
      :items="selectItems"
      :last="true"
    />
    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbar"
      :color="color_snackbar"
      :timeout="timeout"
    >
      {{ CustomMsg }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
    <!--- SNACKBAR --->
  </div>
</template>

<script>
import axios from 'axios';
import Repeater from '../../../Repeater';
import RepeaterInserter from '../../../Repeater_inserter';
import TitleSlide from '../TitleSlide';

export default {
  name: 'CardRiepilogo',
  components: {
    TitleSlide,
    Repeater,
    RepeaterInserter,
  },
  props: {
    id_campagna: {
      default: '',
    },
  },
  data() {
    return {
      selectItems: [],
      snackbar: false,
      color_snackbar: 'primary',
      timeout: 5000,
      CustomMsg: '',
    };
  },

  computed: {
    confirmed: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .confirmed;
      },
    },
    items: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .repeaterItems;
      },
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (to.path == '/wizard') {
        axios
          .post(`${process.env.VUE_APP_API_URL}/storeandriep/get`, {
            type: 'resume_data',
          })
          .then((res) => {
            if (String(res.status).match(/^2/)) {
              this.selectItems = res.data.result.map(
                (obj) => obj.name,
              );
            }
          })
          .catch(() => {
            this.CustomMsg = `Errore nel caricamento dei dati di tipo ${
              this.typeTab
            }. Gentilmente, contattare l'amministratore di sistema.`;
            this.color_snackbar = 'red darken-1';
            this.snackbar = true;
          });
      }
    },
  },
  created() {
    axios
      .post(`${process.env.VUE_APP_API_URL}/storeandriep/get`, {
        type: 'resume_data',
      })
      .then((res) => {
        if (String(res.status).match(/^2/)) {
          this.selectItems = res.data.result.map(
            (obj) => obj.name.charAt(0).toUpperCase() + obj.name.slice(1),
          );
        }
        this.selectItems.sort();
      })
      .catch(() => {
        this.CustomMsg = `Errore nel caricamento dei dati di tipo ${
          this.typeTab
        }. Gentilmente, contattare l'amministratore di sistema.`;
        this.color_snackbar = 'red darken-1';
        this.snackbar = true;
      });
  },
};
</script>

<style></style>
