<template>
  <div class="d-flex align-center flex-column pa-4 rounded-xl mt-10">
    <v-card flat width="40%">
      <UserModal
        :is-updating-existing-user="true"
        :data-information-prop="dataInformations"
      />
    </v-card>
  </div>
</template>

<script>
import UserModal from '@/components/UserModal.vue';
import user from '@/helpers/mixins/user';

export default {
  name: 'Profilo',
  components: { UserModal },
  mixins: [user],
  data() {
    return {
      dataInformations: {
        id: '',
        name: '',
        surname: '',
        email: '',
        username: '',
        role: '',
        sub_role: '',
        photo: '',
      },
    };
  },
  created() {
    this.dataInformations.id = this.$store.getters.currentUser.userId;
    this.dataInformations.name = this.$store.getters.currentUser.name;
    this.dataInformations.surname = this.$store.getters.currentUser.surname;
    this.dataInformations.email = this.$store.getters.currentUser.email;
    this.dataInformations.username = this.$store.getters.currentUser.username;
    this.dataInformations.role = this.$store.getters.currentUser.role;
    this.dataInformations.sub_role = this.$store.getters.currentUser.sub_role;
    this.dataInformations.company_id = this.$store.getters.currentUser.company_id;
    this.dataInformations.photo = this.$store.getters.photo;
  },
};
</script>

<style scoped></style>
