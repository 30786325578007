<template>
  <v-container id="body">
    <v-card id="cardParent">
      <v-card-title> Modifica la password </v-card-title>
      <v-form ref="formUpdate" v-model="valid" lazy-validation>
        <div id="container-text-field">
          <v-text-field
            v-model="password"
            label="Nuova Password"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rulesUsersModify, rulesLength]"
            :type="show3 ? 'text' : 'password'"
            name="input-10-2"
            class="password"
            @keyup="delayValidation()"
            @click:append="show3 = !show3"
          />
          <v-text-field
            v-model="confirmPassword"
            label="Conferma Password"
            :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rulesConfirmPasswordUserModify]"
            :type="show4 ? 'text' : 'password'"
            name="input-10-2"
            class="password"
            @click:append="show4 = !show4"
            @keyup="delayValidation()"
          />
        </div>
      </v-form>
      <div id="buttonConfirmChangePassword">
        <v-btn
          id="buttonChangeConfirm"
          rounded
          :disabled="buttonControl()"
          color="primary"
          @click="updatePassword()"
        >
          Invia
        </v-btn>
      </div>
      <div id="alertEmail">
        <v-alert
          v-model="successEmailReset"
          outlined
          type="success"
        >
          La password è stata modificata correttamente. Sarai
          ridirezionato alla homepage tra 3 secondi...
        </v-alert>
      </div>
      <!-- Schermata caricamento -->
      <v-dialog
        v-model="retrievingTemplates"
        content-class="custom_dialog"
        persistent
      >
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="white"
          />
        </div>
      </v-dialog>
      <!-- Fine schermata caricamento -->
    </v-card>
    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbar"
      :color="color_snackbar"
      :timeout="timeout"
    >
      {{ customMsg }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
    <!--- SNACKBAR --->
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      show3: false,
      show4: false,
      timer: 1000,
      valid: true,
      snackbar: false,
      color_snackbar: 'primary',
      timeout: 5000,
      customMsg: '',
      retrievingTemplates: false,
      rulesUsersModify: true,
      rulesLength: true,
      rulesConfirmPasswordUserModify: true,
      password: '',
      confirmPassword: '',
      successEmailReset: false,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.$nextTick(this.routeLoaded);
    },
  },
  mounted() {
    this.routeLoaded();
  },
  methods: {
    routeLoaded() {
      if (document.getElementsByTagName('main').length > 0) {
        document
          .getElementsByTagName('main')[0]
          .classList.remove('scrollable');
      }
    },
    validatePassword(password) {
      const regex_validation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!="£€^°§<>ç#_%,*?&.])[A-Za-z\d$@$!%*?&.]/;
      return regex_validation.test(password);
    },

    buttonControl() {
      if (this.password == '' || this.confirmPassword == '') {
        return true;
      } if (this.password != this.confirmPassword) {
        return true;
      } if (!this.validatePassword(this.confirmPassword)) {
        return true;
      } if (this.password.length < 8) {
        return true;
      }
      return false;
    },

    delayValidation() {
      // Delaying the function execute
      if (this.timer) {
        window.clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if (this.password != '' || this.confirmPassword != '') {
          this.$refs.formUpdate.validate();
          this.rulesUsersModify = this.validatePassword(this.password)
            ? true
            : 'La password deve contenere minimo 8 caratteri tra cui una lettera maiuscola, una minuscola, un carattere speciale ed un numero';
          this.rulesLength = this.password.length < 8 ? 'Minimo 8 caratteri' : true;
          this.rulesConfirmPasswordUserModify = this.password != this.confirmPassword
            ? 'Le password non corrispondono'
            : true;
        }
      }, 1000);
    },

    updatePassword() {
      const { token } = this.$route.query;
      const { password } = this;
      if (this.password == this.confirmPassword) {
        this.retrievingTemplates = true;
        axios
          .post(`${process.env.VUE_APP_API_URL}/recover/updatepwd`, {
            password,
            token,
          })
          .then((res) => {
            if (String(res.status).match(/^2/)) {
              this.successEmailReset = true;
              setTimeout(() => {
                this.$router.push('/login').catch(() => {});
              }, 3000);
            }
          })
          .catch((error) => {
            this.customMsg = error;
            this.color_snackbar = 'red darken-1';
            this.snackbar = true;
          })
          .finally(() => {
            this.retrievingTemplates = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.password {
    padding: 5px 0 5px 0;
    margin: 30px 0 30px 0;
}
#buttonChangeConfirm {
    margin: auto;
    width: 120px;
    text-transform: capitalize;
    margin-bottom: 15px;
}
#buttonChangeConfirm:disabled {
    background-color: lightgrey;
}
#alertEmail {
    width: 450px;
    margin: auto;
}
#body {
    background-image: url("./../assets/4Ad7ukUd.jpeg");
    max-width: 100%;
    height: 100%;
    background-size: cover;
}

#buttonConfirmChangePassword {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}
#container-text-field {
    width: 300px;
    margin: auto;
    padding-top: 50px;
}
#cardParent {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
