<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <div class="d-flex justify-center flex-column pa-5">
      <v-file-input
        v-model="inputFile"
        :label="label"
        prepend-icon="mdi-camera"
        filled
        accept="image/*"
        hint="Il limite massimo è di 500KB"
        persistent-hint
        :rules="imageValidation"
        @change="fileSelected"
      />
    </div>
    <div class="d-flex justify-center pa-6">
      <v-btn
        id="buttonUpload"
        color="primary"
        rounded
        :disabled="
          inputFile == null || uploadChange.size > 500000
        "
        @click="uploadFile()"
      >
        Upload
      </v-btn>
      <v-btn
        id="buttonUpload"
        color="red"
        rounded
        @click="toggleModal(false)"
      >
        Chiudi
      </v-btn>
    </div>
    <v-dialog
      v-model="uploadingFiles"
      content-class="custom_dialog"
      persistent
    >
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="white"
        />
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from 'axios';
import constants from '../constants';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    previousName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputFile: null,
      uploadChange: '',
      uploadingFiles: false,
      imageValidation: [
        (value) => !value
                    || value.size < 500000
                    || 'Il limite massimo è di 500KB',
      ],
    };
  },
  methods: {
    fileSelected(event) {
      this.uploadChange = event;
    },
    uploadFile() {
      this.uploadingFiles = true;
      const data = new FormData();

      const selectedPhoto = this.uploadChange;
      data.append('new_photo', selectedPhoto);

      axios
        .post(
          `${process.env.VUE_APP_API_URL
          }/pdf/preview/${this.previousName}/change`,
          data,
        )
        .then((res) => {
          this.$store.commit('toggleSnackbar', {
            message: 'Immagine sostituita con successo!',
            color: constants.SUCCESS_MESSAGE_SNACKBAR,
          });
          const dataToSend = {};
          dataToSend.new_name = res.data.new_name;
          const [, index] = res.data.new_name.split('_');
          dataToSend.index = index;
          dataToSend.isNewUpload = res.data.new_name.includes('newUpload');
          this.$emit('update-time', dataToSend);
        })
        .catch((error) => {
          this.$store.commit('toggleSnackbar', {
            message: `Errore nel caricamento dell'immagine.
             Gentilmente, contattare l'amministratore di sistema. Errore tecnico: ${error}`,
            color: constants.ERROR_SNACKBAR,
          });
          console.error(error);
        })
        .finally(() => {
          this.uploadingFiles = false;
          this.toggleModal(false);
        });
    },
    toggleModal(status) {
      this.$emit('toggle-modal', status);
    },
  },
};
</script>

<style></style>
