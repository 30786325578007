// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/4Ad7ukUd.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".password[data-v-07f994ad]{padding:5px 0 5px 0;margin:30px 0 30px 0}#buttonChangeConfirm[data-v-07f994ad]{margin:auto;width:120px;text-transform:capitalize;margin-bottom:15px}#buttonChangeConfirm[data-v-07f994ad]:disabled{background-color:#d3d3d3}#alertEmail[data-v-07f994ad]{width:450px;margin:auto}#body[data-v-07f994ad]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");max-width:100%;height:100%;background-size:cover}#buttonConfirmChangePassword[data-v-07f994ad]{display:flex;justify-content:center;padding-top:50px}#container-text-field[data-v-07f994ad]{width:300px;margin:auto;padding-top:50px}#cardParent[data-v-07f994ad]{position:absolute;display:flex;flex-direction:column;justify-items:center;top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
