import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#4934C8',
        viola_eb: '#5E49D7',
        blu_eb: '#4B51D7',
        verde_eb: '#3BA39A',
      },
    },
    options: { customProperties: true },
  },
});
