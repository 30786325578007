import axios from 'axios';
import store from '../store';
import router from '../router';

export default function setup() {
  axios.interceptors.request.use(
    (config) => {
      const { token } = store.getters.currentUser;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err) => Promise.reject(err),
  );
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log(error);

      if (error.response.status === 401) {
        store.commit('logout');
        router.push('/login').catch((e) => {
          console.error(e);
        });
      }
      return Promise.reject(error);
    },
  );
}
