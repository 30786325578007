<template>
  <div>
    <div class="font-weight-bold">
      Titolo
    </div>
    <v-text-field
      v-model="titolo_substr"
      label="Titolo"
      :value="titolo_substr"
      :disabled="confirmed"
      single-line
      outlined
      @keyup="$store.commit('updateIsDraft', true)"
    />
    <div class="font-weight-bold">
      Sottotitolo
    </div>
    <v-text-field
      v-model="sottotitolo_substr"
      label="Sottotitolo"
      :value="sottotitolo_substr"
      :disabled="confirmed"
      single-line
      outlined
      @keyup="$store.commit('updateIsDraft', true)"
    />
  </div>
</template>

<script>
export default {
  name: 'CardTitle',
  props: {
    id_campagna: {
      default: '',
    },
  },
  computed: {
    titolo_substr: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .titolo_slide;
      },
      set(value) {
        this.$store.commit('updateSlide', {
          id_campagna: this.id_campagna,
          titolo_slide: value,
        });
      },
    },
    sottotitolo_substr: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .sottotitolo_slide;
      },
      set(value) {
        this.$store.commit('updateSlide', {
          id_campagna: this.id_campagna,
          sottotitolo_slide: value,
        });
      },
    },
    confirmed: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .confirmed;
      },
    },
  },
  mounted() {
    this.initialSetTitolo();
    this.initialSetSottotitolo();
  },
  methods: {
    initialSetTitolo() {
      if (this.$store.getters.report.id == -1) {
        let computedTitolo = this.$store.getters.slides[1]
          .campaign_name;
        const regex = new RegExp('\\[(.*)\\]');
        if (regex.test(computedTitolo)) {
          computedTitolo = computedTitolo.substring(
            computedTitolo.lastIndexOf('[') + 1,
            computedTitolo.lastIndexOf(']'),
          );
        }
        this.titolo_substr = computedTitolo;
      }
    },
    initialSetSottotitolo() {
      if (this.$store.getters.report.id == -1) {
        let computedSottotitolo = this.$store.getters.slides[1]
          .campaign_name;
        const regex = new RegExp('"(.*)"');
        if (regex.test(computedSottotitolo)) {
          computedSottotitolo = computedSottotitolo.match(regex)[1];
        }

        this.sottotitolo_substr = computedSottotitolo;
      }
    },
  },
};
</script>

<style></style>
