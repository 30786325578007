import Axios from 'axios';
import constants from '@/constants';
import store from '@/store';

const namespaced = true;
const getDefaultState = () => ({
  companies: [],
});

// initial state
const state = getDefaultState();
const mutations = {
  resetState() {
    Object.assign(state, getDefaultState());
  },
  // Customers section
  setCompanies() {
    if (state.companies.length === 0) {
      Axios.post(`${process.env.VUE_APP_API_URL}/company/get`)
        .then((res) => {
          state.companies = res.data.company.map((x) => ({
            text: x.name,
            value: x.id,
            company: x.name,
          }));
          const maybeCompanyFound = res.data.company.filter(
            (company) => store.getters.currentUser.company_id === company.id,
          );
          if (maybeCompanyFound.length > 0) {
            store.commit('updateCurrentProfile', { company_name: maybeCompanyFound[0].name });
          }
        })
        .catch((error) => {
          store.commit('toggleSnackbar', {
            message: `Errore nell'ottenimento delle aziende: ${error}`,
            color: constants.ERROR_SNACKBAR,
          });
          console.error(`Errore nell'ottenimento delle aziende: ${error}`);
        });
    }
  },
};

const getters = {
  getCompanies: () => state.companies,
};

export default {
  namespaced,
  state,
  getters,
  mutations,
};
