var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-5"},[_c('v-card-text',[_c('v-icon',{staticClass:"float-right",attrs:{"color":_vm.confirmed ? 'green lighten-2' : 'grey'},on:{"click":function($event){return _vm.confirmSlide()}}},[_vm._v(" mdi-checkbox-marked-circle-outline ")]),(
        _vm.type != 'titolo' && _vm.type != 'canvas' && _vm.type != 'riepilogo'
      )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('span',{staticClass:"display-1 eb-card-title text--primary-eb"},[_vm._v(" "+_vm._s(_vm.titolo)+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[(_vm.videoPreviewLocal.length > 0)?_c('div',_vm._l((_vm.videoPreviewLocal),function(preview,i){return _c('v-row',{key:i,staticClass:"d-flex flex-column",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"alert_custom",attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.buildTooltip(i)))])]),(preview != '')?_c('v-img',{attrs:{"lazy-src":"http://flxtable.com/wp-content/plugins/pl-platform/engine/ui/images/image-preview.png","max-height":"50","min-height":"30","max-width":"50","src":preview + '?t=' + _vm.date},on:{"mouseover":function($event){_vm.previewToShow = preview;
                _vm.toggleModalPreview = true;}}}):_c('v-img',{attrs:{"lazy-src":"http://flxtable.com/wp-content/plugins/pl-platform/engine/ui/images/image-preview.png","max-height":"50","max-width":"50","min-height":"30","src":"/wizard/assets/no-image.png"}}),(preview != '')?_c('div',{staticClass:"background-camera custom_pos",class:{
                disabled: _vm.confirmed,
              },on:{"click":function($event){_vm.previewToEdit = preview;
                _vm.toggleModal = true;}}},[_c('v-icon',{staticClass:"camera",staticStyle:{"left":"12px","bottom":"1px"},attrs:{"color":"white"}},[_vm._v(" mdi-cached ")])],1):_c('div',{staticClass:"background-camera custom_pos",class:{
                disabled: _vm.confirmed,
              },on:{"click":function($event){_vm.previewToEdit = 'newUpload_'
                  + i
                  + '_'
                  + _vm.id_campagna
                  + '_'
                  + new Date().getTime()
                  + '.png';
                _vm.toggleModal = true;}}},[_c('v-icon',{staticClass:"camera",staticStyle:{"left":"12px"},attrs:{"color":"white"}},[_vm._v(" mdi-file-upload-outline ")])],1)],1)}),1):_c('div',_vm._l((_vm.times),function(i){return _c('v-row',{key:i,staticClass:"d-flex flex-column",attrs:{"no-gutters":"","align":"center"},on:{"mouseleave":function($event){_vm.toggleModalPreview = false}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"alert_custom",attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.buildTooltip(i - 1)))])]),_c('v-img',{attrs:{"lazy-src":"http://flxtable.com/wp-content/plugins/pl-platform/engine/ui/images/image-preview.png","max-height":"50","min-height":"30","max-width":"50","src":"/wizard/assets/no-image.png"}}),_c('div',{staticClass:"background-camera custom_pos",class:{
                disabled: _vm.confirmed,
              },on:{"click":function($event){_vm.previewToEdit = 'newUpload_'
                  + (i - 1)
                  + '_'
                  + _vm.id_campagna
                  + '_'
                  + new Date().getTime()
                  + '.png';
                _vm.toggleModal = true;}}},[_c('v-icon',{staticClass:"camera",staticStyle:{"left":"12px"},attrs:{"color":"white"}},[_vm._v(" mdi-file-upload-outline ")])],1)],1)}),1)])],1):_c('p',{staticClass:"display-1 eb-card-title text--primary-eb"},[_vm._v(" "+_vm._s(_vm.titolo)+" ")]),(_vm.type == 'titolo')?_c('span',[_c('CardTitle',{attrs:{"id_campagna":_vm.id_campagna}})],1):(_vm.type == 'canvas')?_c('span',[_c('CardCanvas',{attrs:{"id_campagna":_vm.id_campagna}})],1):(_vm.type == 'riepilogo')?_c('span',[_c('CardRiepilogo',{attrs:{"id_campagna":_vm.id_campagna}})],1):_vm._l((_vm.slides),function(slide,index){return _c('TitleSlide',{key:index,attrs:{"index":index,"id_campagna":_vm.id_campagna,"type":_vm.type}},[(
          (_vm.type == 'preroll'
            || _vm.type == 'yt_banner'
            || _vm.type == 'web_banner')
            && index == 1
        )?_c('Positions',{attrs:{"id_campagna":_vm.id_campagna,"additional-headers":_vm.additionalHeaders}}):_vm._e()],1)})],2),_c('v-dialog',{attrs:{"content-class":"position-relative","width":"600"},model:{value:(_vm.toggleModalPreview),callback:function ($$v) {_vm.toggleModalPreview=$$v},expression:"toggleModalPreview"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-img',{attrs:{"src":_vm.previewToShow + '?t=' + _vm.date}})],1)],1),_c('v-btn',{attrs:{"height":"64px","elevation":"4","absolute":"","top":"","right":"","rounded":""},on:{"click":function($event){_vm.toggleModalPreview = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.toggleModal),callback:function ($$v) {_vm.toggleModal=$$v},expression:"toggleModal"}},[_c('UploadFile',{attrs:{"label":'Caricamento nuova anteprima',"title":'Caricamento anteprima',"previous-name":_vm.getPreviousName(_vm.previewToEdit)},on:{"toggle-modal":_vm.toggleModalEvent,"update-time":_vm.updateTime}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }