<template>
  <v-container fluid class="pa-3">
    <div class="mostraAggiungi">
      <div class="mostraAccantoAggiungi">
        <span class="mr-5 scrittaMostra">Mostra</span>
        <v-select
          v-model="itemsPerPage"
          class="custom_ses_input"
          height="30"
          :items="show"
          hide-details="auto"
          outlined
          :disabled="!dataLoaded"
          :value="show[0]"
          return-object
        />
      </div>
      <v-btn rounded color="primary" @click="showDialogAddUser()">
        <v-icon class="mr-3">
          mdi-plus-circle
        </v-icon>
        Aggiungi
      </v-btn>
    </div>
    <v-dialog v-model="confirmDelete" width="600">
      <v-card>
        <v-card-title>
          Vuoi eliminare davvero questo utente?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-model="deleteYes"
            text
            color="primary"
            icon
            @click="deleteUserConfirm()"
          >
            Si
          </v-btn>
          <v-btn
            text
            color="primary"
            icon
            @click="confirmDelete = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showModalUser" width="600">
      <UserModal
        :is-updating-existing-user="isUpdatingExistingUser"
        :is-creating-new-user="isCreatingNewUser"

        :data-information-prop="dataInformation"
        :status="showModalUser"
        @addOk="addUser"
        @updateOk="updateUsers"
        @closeModal="showModalUser = false"
      />
    </v-dialog>

    <!-- Schermata caricamento -->
    <v-dialog
      v-model="retrievingTemplates"
      content-class="custom_dialog"
      persistent
    >
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="white"
        />
      </div>
    </v-dialog>
    <!-- Fine schermata caricamento -->

    <v-data-table
      :headers="headers"
      hide-default-header
      hide-default-footer
      :items="users"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      no-data-text="Non ci sono ancora utenti. Quando vuoi, puoi inserirne uno nuovo."
      no-results-text="Non è stato trovato nessun utente con i criteri di ricerca specificati..."
      :loading="!dataLoaded"
      loading-text="Sto analizzando tutti gli utenti..."
      class="mt-3 elevation-4"
      @pagination="getFiltered"
    >
      <template #[`item.created_at`]="{ item }">
        {{ item.created_at ? item.created_at.slice(" ", 10) : '' }}
      </template>
      <template #[`item.company_id`]="{ item }">
        {{ companyName(item.company_id) }}
      </template>
      <template #[`item.role`]="{ item }">
        {{ roleBeautified(item.role) }}
      </template>
      <!------- FILTRO RICERCA INZIIO -------------->
      <template #header="{ props: { headers } }">
        <thead>
          <tr>
            <th
              v-for="h in headers"
              :key="h.id"
              :width="calcWidth(h)"
            >
              <!-- First Row, it appears always -->
              {{ h.text }}
              <v-icon
                v-if="h.text !== ''"
                class="ml-1"
                small
                :color="h.isSearchActive ? 'blue' : ''"
                @click="toggleSearch(h.id)"
              >
                mdi-magnify
              </v-icon>
              <v-icon
                v-if="h.sortable"
                class="ml-1"
                small
                @click="toggleSort(h.id)"
              >
                mdi-sort{{ sortMode(h.value) }}
              </v-icon>
            </th>
          </tr>
          <!-- Second row, appears only if some search button is clicked -->
          <!-- headers last elem is col for actions -->
          <tr
            height="70px"
            :class="{
              'visible-true': searchFilterSelected <= 0,
              'change-opacity bg-color-row ': true,
            }"
          >
            <th v-for="n in headers.length - 1" :key="`filter-${n}`" color="blue">
              <v-text-field
                v-model="headersFiltered[n - 1].valueFilter"
                :class="{
                  'filter--hidden': !headersFiltered[n - 1].isSearchActive,
                  'change-opacity ': true,
                }"
                hide-details
                style="width: 130px"
                outlined
                :label="headersFiltered[n - 1].text"
              />
            </th>
            <th>
              <!-- calendario -->
              <v-menu
                ref="menu"
                v-model="menu"
                :class="{
                  'filter--hidden': !headersFiltered[indexColumnDateType]
                    .isSearchActive,
                }"
                hide-details
                :close-on-content-click="false"
                :return-value.sync="headers[indexColumnDateType].date"
                transition="scale-transition"
                offset-y
                min-width="90px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="headers[indexColumnDateType].date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    style="width:130px"
                    :class="{
                      'filter--hidden': !headersFiltered[indexColumnDateType]
                        .isSearchActive,
                    }"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="headers[indexColumnDateType].date"
                  :class="{
                    'filter--hidden': !headersFiltered[indexColumnDateType]
                      .isSearchActive,
                  }"
                  locale="it-it"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="headers[indexColumnDateType].date = ''"
                  >
                    Reset
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.menu.save(headers[indexColumnDateType].date)
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <!-- fine cal -->
            </th>
            <th />
          </tr>
        </thead>
      </template>
      <!----------------- FILTRO RICERCA FINE -------------->
      <template #[`item.actions`]="{ item }">
        <v-row class="flex-nowrap">
          <v-btn
            :disabled="!(item.role === constants.ROLES.MANAGER)"
            class=""
            :class="{
              'white-icon': item.sendmail === 1,
              'mr-3 pa-4 rounded blu_eb': true,
            }"
            elevation="1"
            icon
            small
            tile
            @click="toggleMailSend(item.id)"
          >
            <v-icon> mdi-email-outline </v-icon>
          </v-btn>
          <v-btn
            class="mr-3 pa-4 viola_eb rounded"
            dark
            icon
            elevation="1"
            small
            tile
            @click="showDialogEditUser(item)"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            class="mr-3 pa-4 buttonBucket rounded"
            color="error"
            :disabled="idUser === item.id"
            @click="deleteUser(item)"
          >
            <v-icon> mdi-trash-can-outline </v-icon>
          </v-btn>
        </v-row>
      </template>

      <template #footer>
        <div class="text-center">
          <v-pagination
            v-model="page"
            color="primary"
            :length="pagesNum"
            :total-visible="7"
          />
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios';
import constants from '../constants';
import UserModal from '../components/UserModal.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Utenti',
  components: {
    UserModal,
  },
  data() {
    return {
      retrievingTemplates: false,
      openChangePhotoUsersAdd: false,
      menu: false,

      retrievingData: false,
      sortBy: 'name',
      sortDesc: false,
      pagesNum: 0,
      idItemToWork: -1,
      indexColumnDateType: 5, // Starting from 0, referred to the headers array
      headers: [],
      page: 1,
      dataLoaded: false,
      show: [10, 25, 50, 100], // Elementi della dropdown "Mostra"
      itemsPerPage: 10, // Elementi visualizzati nel dt
      users: [],

      showModalUser: false,
      isUpdatingExistingUser: false,
      isCreatingNewUser: false,

      deleteYes: false,

      emails: '',
      confirmDelete: false,
      nameUsersAdd: '',
      surnameUsersAdd: '',
      usernameUsersAdd: '',
      emailUsersAdd: '',
      dataInformation: {
        id: '',
        name: '',
        surname: '',
        email: '',
        username: '',
        role: '',
        sub_role: '',
        password: '',
        photo: '',
        company_id: '',
      },
      userToUpdateBefore: {
        id: '',
        name: '',
        surname: '',
        email: '',
        username: '',
        role: '',
        password: '',
        photo: '',
      },
    };
  },
  computed: {
    constants() {
      return constants;
    },
    idUser() {
      return this.$store.getters.idUser;
    },
    /// /// METODI FILTRO RICERCA ////
    headersFiltered() {
      return this.headers.filter((h) => h.text !== '');
    },
    searchFilterSelected() {
      let filterSelected = 0;
      this.headers.forEach((header) => {
        if (header.isSearchActive) filterSelected += 1;
      });
      return filterSelected;
    },
    /// /// FINE METODI FILTRO RICERCA //////
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to) {
      if (to.path === '/utenti') {
        this.loadUsers();
      }
    },
  },
  created() {
    this.loadUsers();
    this.$store.commit('companies/setCompanies');
    this.headers = [
      {
        text: 'Nome',
        value: 'name',
        sortable: true,
        isSearchActive: false,
        valueFilter: '',
        filter(value) {
          return (
            this.valueFilter === ''
              || value
                .toLowerCase()
                .includes(this.valueFilter.toLowerCase())
          );
        },
      },
      {
        text: 'Cognome',
        value: 'surname',
        sortable: true,
        isSearchActive: false,
        valueFilter: '',
        filter(value) {
          return (
            this.valueFilter === ''
              || value
                .toLowerCase()
                .includes(this.valueFilter.toLowerCase())
          );
        },
      },
      {
        text: 'E-mail',
        value: 'email',
        sortable: true,
        isSearchActive: false,
        valueFilter: '',
        filter(value) {
          return (
            this.valueFilter === ''
              || value
                .toLowerCase()
                .includes(this.valueFilter.toLowerCase())
          );
        },
      },
      {
        text: 'Ruolo',
        value: 'role',
        sortable: true,
        isSearchActive: false,
        valueFilter: '',
        filter(value) {
          return (
            this.valueFilter === ''
              || value
                .toLowerCase()
                .includes(this.valueFilter.toLowerCase())
          );
        },
      },
      {
        text: 'Azienda Manager',
        value: 'company_id',
        sortable: true,
        isSearchActive: false,
        valueFilter: '',
        filter(value) {
          return (
            this.valueFilter === ''
              || value
                .toLowerCase()
                .includes(this.valueFilter.toLowerCase())
          );
        },
      },
      {
        text: 'Creato',
        value: 'created_at',
        sortable: true,
        isSearchActive: false,
        valueFilter: '',
        date: '',
        filter(value) {
          const valore = value ? value.slice(' ', 10) : '';
          return this.date === '' || valore === this.date;
          // return (this.valueFilter !== '') ? (value.slice(' ', 10) === this.date) : true;
        },
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      }];
    this.headers = this.headers.map((el, index) => ({ ...el, id: index }));
    this.indexColumnDateType = this.headers.filter((el) => el.value === 'created_at')[0].id;
  },

  methods: {
    toggleMailSend(id) {
      const index = this.users.findIndex((user) => user.id === id);
      if (index === -1) return;

      // Chiamata api per modifica sendmail
      const newStatus = +!this.users[index].sendmail;
      axios.post(
        `${process.env.VUE_APP_API_URL}/user/update?id=${id}`,
        {
          sendmail: newStatus,
        },
      ).then(() => {
        this.users[index].sendmail = newStatus;
      });
    },
    roleBeautified(role) {
      return role ? constants.LABEL_ROLES[role] : '';
    },
    companyName(companyId) {
      if (companyId) {
        const allCompanies = this.$store.getters['companies/getCompanies'];
        const companiesFound = allCompanies.filter(
          (localCompany) => companyId === localCompany.value,
        );
        return companiesFound.length > 0 ? companiesFound[0].text : 'Azienda non trovata';
      }
      return '';
    },
    updateUsers(response) {
      // Aggiorno il frontend
      const indexUser = this.users.findIndex(
        (x) => x.id === response.user.id,
      );
      Object.keys(response.user).forEach((key) => {
        if (
        // Se esiste la chiave per l'oggetto, la aggiorno
          Object.hasOwnProperty.call(
            this.users[indexUser],
            key,
          )
        ) {
          this.users[indexUser][key] = response.user[key];
        }
      });

      let toUploadProfile = false;
      const bodyToUpdate = {};
      if (this.dataInformation.id === this.idUser) {
        // Update current user information in realtime
        /* if (
          this.avatarUpload.toLowerCase() !== 'photo'
            || this.avatarUpload !== ''
        ) {
          toUploadProfile = true;
          bodyToUpdate.photo = this.avatarUpload;
        } */
        if (
          this.dataInformation.name
            !== response.user.name
            || this.dataInformation.surname
            !== response.user.surname
        ) {
          toUploadProfile = true;
          bodyToUpdate.nameSurname = `${response.user.name
          } ${
            response.user.surname}`;
        }

        if (
          this.dataInformation.role
            !== response.user.role
        ) {
          toUploadProfile = true;
          bodyToUpdate.role = response.user.role;
        }
      }

      if (toUploadProfile) {
        this.$store.commit(
          'updateCurrentProfile',
          bodyToUpdate,
        );
      }
    },
    addUser(response) {
      this.users.push({
        id: response.user.id,
        username: response.user.username,
        name: response.user.name,
        surname: response.user.surname,
        email: response.user.email,
        role: response.role_user,
        sub_role: response.sub_role,
        company_id: response.user.company_id,
        created_at: response.user.created_at,
        photo: response.user.photo,
      });
    },
    loadUsers() {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      window.scrollTo(0, 0);
      this.dataLoaded = false;
      axios
        .post(`${process.env.VUE_APP_API_URL}/users/all`)
        .then((res) => {
          this.users = res.data.user;
        })
        .finally(() => {
          this.dataLoaded = true; // Imposto il caricamento a completato
        });
    },
    showDialogAddUser() {
      this.dataInformation.photo = require('@/assets/avatar_utenti_default.png');
      this.dataInformation.name = '';
      this.dataInformation.surname = '';
      this.dataInformation.email = '';
      this.dataInformation.password = '';
      this.dataInformation.username = '';
      this.dataInformation.role = '';
      this.dataInformation.sub_role = '';
      // this.roleUsersAdd = this.itemsroleUserAdd;
      // this.$nextTick(() => {
      //     if (this.$refs["formAdd"]) {
      //         this.rulesEmail = true;
      //         this.rulesLength = true;
      //         this.rulesConfirmPasswordUsersAdd = true;
      //         this.rules = true;
      //         this.$refs.formAdd.resetValidation();
      //     }
      // });

      this.isUpdatingExistingUser = false;
      this.isCreatingNewUser = true;
      this.showModalUser = true;
    },
    showDialogEditUser(item) {
      this.idItemToWork = this.users.indexOf(item);
      this.dataInformation = { ...item };

      if (!this.dataInformation.photo.includes('data:image')) {
        this.dataInformation.photo = require('@/assets/avatar_utenti_default.png');
      }

      this.$nextTick(() => {
        if (this.$refs.form) {
          this.rulesUsersModify = true;
          this.rulesConfirmPasswordUserModify = true;
          this.rulesEmail = true;
          this.$refs.form.resetValidation();
        }
      });

      this.isUpdatingExistingUser = true;
      this.isCreatingNewUser = false;
      this.showModalUser = true;
    },
    deleteUser(item) {
      this.idItemToWork = this.users.indexOf(item);
      this.dataInformation = { ...item };
      this.confirmDelete = true;
    },
    deleteUserConfirm() {
      const { id } = this.dataInformation;
      this.retrievingTemplates = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}/users/${id}/delete`, {
          id,
        })
        .then((res) => {
          if (String(res.status).match(/^2/)) {
            this.confirmDelete = false;
            let customMsg = 'Utente eliminato correttamente';
            let snackbarColor = constants.SUCCESS_MESSAGE_SNACKBAR;
            const indexArray = this.users.findIndex((x) => x.id === id);
            if (indexArray !== -1) this.users.splice(indexArray, 1);
            else {
              customMsg = "L'utente da cancellare non è stato trovato.";
              snackbarColor = constants.ERROR_SNACKBAR;
            }

            this.$store.commit('toggleSnackbar', {
              message: customMsg,
              color: snackbarColor,
            });
          }
        })
        .catch((e) => {
          this.$store.commit('toggleSnackbar', {
            message: `L'utente non è stato eliminato correttamente: ${e}`,
            color: constants.ERROR_SNACKBAR,
          });
        })
        .finally(() => {
          this.retrievingTemplates = false;
          this.confirmDelete = false;
        });
    },
    /// ////////INIZIO METODI FILTRO RICERCA ///////////////////////
    sortMode(value) {
      if (this.sortBy === value) {
        return this.sortDesc ? '-descending' : '-ascending';
      } return '';
    },

    calcWidth(item) {
      return item.text === '' ? '10%' : `${((90 / (this.headers.length - 1))).toFixed(2)}%`;
    },

    toggleSearch(id) {
      this.headers[id].isSearchActive = !this.headers[id].isSearchActive;
      this.headers[id].valueFilter = '';
    },

    toggleSort(id) {
      this.sortBy = this.headersFiltered[id].value;
      this.sortDesc = !this.sortDesc;
    },
    /// //////// FINE METODI FILTRO RICERCA ///////////////////////

    getFiltered(pagination) {
      this.pagesNum = Math.ceil(
        pagination.itemsLength / pagination.itemsPerPage,
      );
    },
  },
};
</script>

<style>

.white-icon {
  color: white !important;
}
.buttonBucket {
    height: 28px !important;
    width: 28px !important;
    min-width: 28px !important;
}
.filter--hidden {
    opacity: 0;
    pointer-events: none;
}
.change-opacity {
    transition: all linear 0.2s;
}
.visible-true {
    visibility: hidden;
    position: absolute;
    top: -1000px;
}

.bg-color-row {
    background-color: #eaebf6;
}
.verde_eb.v-btn.v-btn--disabled {
    opacity: 0.4;
}
.verde_eb.v-btn.v-btn--disabled .mdi-printer {
    color: white !important;
}

#buttonUpload:disabled {
    background-color: lightgrey;
}

#buttonUpload {
    position: relative;
    top: 15px;
    width: 120px;
    text-transform: capitalize;
    margin: auto;
    color: #fff;
}

.mostraAggiungi {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.mostraAccantoAggiungi {
    display: flex;
    width: 120px;
    position: relative;
    right: 50px;
}

.buttonBucket:disabled {
    background-color: lightgrey !important;
}

.scrittaMostra {
    display: flex;
    align-items: center;
    left: 10px;
    position: relative;
}

.custom_dialog {
    box-shadow: unset !important;
    overflow: unset !important;
}
</style>
