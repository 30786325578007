// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/login-form-bg.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/login-logo-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#body[data-v-d2e1c516]{background-color:#4934c8;height:100vh;max-width:100vw;font-family:Rubik,sans-serif;font-size:1rem}.scrollable[data-v-d2e1c516]{max-height:none;overflow-y:hidden}#btnAccedi[data-v-d2e1c516]{width:100%;height:3rem;font-weight:600;border-radius:8px!important;background:#7c2cff;background:linear-gradient(180deg,#7c2cff,#2816ff)}#passwordReset[data-v-d2e1c516]{width:100%;padding:0 0 30px 0;text-align:center}#passwordclickReset[data-v-d2e1c516]{cursor:pointer;font-weight:500;color:#4934c8;text-transform:capitalize}#containerTextField[data-v-d2e1c516]{width:500px;margin:auto;margin-top:20px}#containerbuttonResetPassword[data-v-d2e1c516]{display:flex;justify-content:space-around;margin:30px 180px 0 180px}#buttonResetPassword[data-v-d2e1c516]{text-transform:capitalize;width:120px}#buttonResetPassword[data-v-d2e1c516]:disabled{background-color:#d3d3d3}#closeButtonResetPassword[data-v-d2e1c516]{color:#fff;text-transform:capitalize}#alertEmail[data-v-d2e1c516]{width:450px;padding:20px;margin:auto}#titleAccesso[data-v-d2e1c516]{text-align:center;font-size:1em!important}#container[data-v-d2e1c516]{flex-direction:row;width:100%}#container[data-v-d2e1c516],#container>.half-container[data-v-d2e1c516]{display:flex;height:100%;justify-content:center;align-items:center}#container>.half-container[data-v-d2e1c516]{flex-direction:column;width:50%}.login-form-bg[data-v-d2e1c516]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;background-color:#fbf9ff}.login-logo-bg[data-v-d2e1c516]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:cover;background-color:#4e5ac3}h1[data-v-d2e1c516]{font-size:2.125rem;font-weight:800}.custom-card-width[data-v-d2e1c516]{width:35em;background-color:transparent;box-shadow:none!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
