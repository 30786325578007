<template>
  <v-app>
    <!--    <v-dialog v-model="showModalUser" width="600">
      <UserModal
        :is-updating-existing-user="true"
        :data-information-prop="dataInformations"
        :status="showModalUser"
        @updateOk="updateUser"
        @closeModal="showModalUser = false"
      />
    </v-dialog>-->
    <v-app-bar
      v-if="notLogin"
      max-height="80"
      height="80"
      width="100%"
      class="sticky_toolbar background_gradient_eb"
      :src="require('@/assets/navbar_bg.png')"
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <!-- <v-toolbar-title>Exploding bands</v-toolbar-title> -->
      <v-row no-gutters align="center">
        <v-col class="pl-10" cols="3">
          <v-img
            class="v-chip--clickable"
            position="left center"
            contain
            height="100%"
            width="50px"
            src="@/assets/logo.svg"
            @mousedown="
              $router
                .push(
                  getDefaultPage(
                    $store.getters.currentUser.role,
                  ),
                )
                .catch((e) => {})
            "
          />
        </v-col>

        <v-col id="nav" cols="6">
          <div class="d-flex justify-center">
            <router-link
              v-for="route in routes"
              :key="route.path"
              :to="route.path"
              :disabled="route.disabled"
              :event="route.disabled ? '' : 'click'"
            >
              <v-btn
                :disabled="route.disabled"
                :event="route.disabled ? '' : 'click'"
                class="text-decoration-none font-weight-regular text-center customGreen--text"
                text
                height="3.5em"
                color="white"
              >
                <v-icon :class="route.class">
                  {{ route.icon }}
                </v-icon>
                {{ route.title }}
              </v-btn>
            </router-link>
          </div>
        </v-col>

        <v-col
          class="text-right white--text"
          cols="3"
        >
          <v-menu
            v-model="menuOpen"
            :close-on-content-click="false"
            offset-x
            offset-y
            right
            open-on-hover
            max-width="230"
          >
            <template #activator="{ on }">
              <div class="font-weight-medium d-flex justify-end pr-10">
                <div class="d-flex flex-column justify-center" v-on="on">
                  <span class="nameSurname">{{ nameSurname }}</span>
                  <span class="role">{{ role }}</span>
                </div>

                <div class="d-flex align-center" v-on="on">
                  <v-list-item-avatar
                    id="avatar"
                    right
                    color="grey darken-3"
                  >
                    <v-img
                      class="elevation-6"
                      :src="photoUser"
                    />
                  </v-list-item-avatar>
                </div>
              </div>
            </template>
            <v-list>
              <div class="d-flex flex-column justify-end profilePopup">
                <v-list-item @click="showModalUser = true">
                  <v-list-item-icon>
                    <v-icon class="custom-color">
                      mdi-account
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="custom-color">
                    <router-link to="/profilo">
                      Profilo
                    </router-link>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item v-if="isCurrentUserAdministrator()" class="px-5" @click="downloadUserManual">
                  <v-list-item-icon>
                    <v-icon class="custom-color">
                      mdi-help-circle-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="custom-color">
                    Help
                  </v-list-item-title>
                </v-list-item>

                <v-list-item class="px-5" @click="logout">
                  <v-list-item-icon>
                    <v-icon class="custom-color">
                      mdi-logout
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="custom-color">
                    Logout
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
          </div>
        </v-col>

        <!-- <v-col cols="2">

        </v-col> -->
      </v-row>
    </v-app-bar>
    <v-main class="scrollable scrollbar-grey">
      <keep-alive>
        <router-view />
      </keep-alive>
      <ToolVersion />
    </v-main>
    <v-snackbar
      v-model="snackbar"
      multi-line
      :color="snackbarColor"
      :timeout="snackbarTimeout"
    >
      {{ snackbarMsg }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
    <FullScreenLoading />
  </v-app>
</template>

<script>
import '@/assets/style.css';
// eslint-disable-next-line import/extensions
import constants from '@/constants';
import ToolVersion from '@/components/ToolVersion.vue';
import user from '@/helpers/mixins/user';
import FullScreenLoading from '@/components/FullScreenLoading.vue';

export default {
  name: 'App',
  components: { ToolVersion, FullScreenLoading },
  mixins: [user],
  data() {
    return {
      dataInformations: {
        id: '',
        name: '',
        surname: '',
        email: '',
        username: '',
        role: '',
        photo: '',
      },
      pdfUrl: './Manuale_Utente_Exploding_Reports_v1.1.0.pdf',
      routes: [],
      menuOpen: false,
    };
  },
  computed: {

    isLoggedIn: {
      get() {
        return this.$store.getters.isLoggedIn;
      },
    },
    snackbar: {
      get() {
        return this.$store.getters.snackbar.visible;
      },
      set(val) {
        this.$store.commit('toggleSnackbar', { status: val });
      },
    },
    snackbarMsg: {
      get() {
        return this.$store.getters.snackbar.message;
      },
    },
    snackbarTimeout: {
      get() {
        return this.$store.getters.snackbar.timeout;
      },
    },
    snackbarColor: {
      get() {
        return this.$store.getters.snackbar.color;
      },
    },
    photoUser: {
      get() {
        return this.$store.getters.photo !== ''
          ? this.$store.getters.photo
          : require('@/assets/avatar_utenti_default.png');
      },
    },
    nameSurname: {
      get() {
        return `${this.$store.getters.currentUser.name} ${this.$store.getters.currentUser.surname}`;
      },
    },
    role: {
      get() {
        return constants.LABEL_ROLES[this.$store.getters.ruolo];
      },
    },
    notLogin() {
      return (
        this.$route.path !== '/login'
                && this.$route.path !== '/reset_password'
      );
    },
  },
  watch: {
    isLoggedIn(val) {
      this.routes = [];

      if (val) {
        this.initializeRoutes();
      }
    },
    showModalUser(val) {
      if (val) {
        this.dataInformations.id = this.$store.getters.currentUser.userId;
        this.dataInformations.name = this.$store.getters.currentUser.name;
        this.dataInformations.surname = this.$store.getters.currentUser.surname;
        this.dataInformations.email = this.$store.getters.currentUser.email;
        this.dataInformations.username = this.$store.getters.currentUser.username;
        this.dataInformations.role = this.$store.getters.currentUser.role;
        this.dataInformations.photo = this.$store.getters.photo;
      }
    },
  },
  created() {
    // To call methods from this component (see PageNotFound.vue searching $refs.App)
    this.$root.$refs.App = this;
    this.initializeRoutes();
  },
  beforeCreate() {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    window.scrollTo(0, 0);
  },
  methods: {
    initializeRoutes() {
      this.routes = [];
      this.routes.push({
        path: '/dashboard',
        title: 'Dashboard Manager',
        icon: 'mdi-bullhorn',
        class: 'rotate-15 icon-margin',
      });
      switch (this.$store.getters.currentUser.role) {
        case constants.ROLES.MANAGER:
          break;
        case constants.ROLES.ADMINISTRATOR:
          this.routes.push({ path: '/', title: 'Report Campagne' });
          this.routes.push({ path: '/report', title: 'Report' });
          this.routes.push({ path: '/utenti', title: 'Utenti' });
          this.routes.push({ path: '/wizard', title: 'Wizard' });
          this.routes.push({
            path: '/impostazioni',
            title: 'Impostazioni',
          });
          break;
        default:
          break;
      }
      // this.routes.push({
      //   path: '/profilo',
      //   title: 'Il mio Profilo',
      //   icon: 'mdi-account',
      //   class: 'icon-margin',
      // });
    },
    updateUser(response) {
      const bodyToUpdate = {};
      const currentUser = {
        name: '',
        surname: '',
        username: '',
        email: '',
        role: '',
        photo: '',
      };
      const properties = Object.keys(currentUser);
      properties.forEach((prop) => {
        bodyToUpdate[prop] = response.user[prop];
        // if (user[prop]) state.currentUser[prop] = user[prop];
      });

      this.$store.commit('updateCurrentProfile', bodyToUpdate);
    },
    logout() {
      this.$store.commit('logout');
      this.$router.push('/login');
      this.menuOpen = false;
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    },
    downloadUserManual() {
      window.open(
        '/Manuale_Utente_Exploding_Reports_v1.1.0.pdf',
        '_blank',
      );
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
}

#nav {
    height: 100%;
}

#nav button {
    margin: 0;
}

#nav a {
    text-decoration: none;
}

.button {
    color: white;
}

.custom-color {
    color: #4e5ac3 !important;
}

.customGreen--text:active,
.customGreen--text:hover {
    color: #05ffb4 !important;
}

.router-link-exact-active button {
    color: #05ffb4 !important;
    border-bottom: 3px solid #05ffb4; /* This creates the border. Replace black with whatever color you want. */
    border-radius: 5px;
    margin: 0 auto; /* This will center the border. */
    margin-top: 10px; /* This creates some space between the element and the border. */
    background-color: #4e5ac3;
}

.v-btn {
    text-transform: none !important;
}

.background_gradient_eb {
    background-color: #4e35e5 !important;
}
.sticky_toolbar {
    position: sticky;
    top: 0;
    z-index: 1;
}

#avatar {
    left: 5px;
}

.nameSurname {
    font-weight: 700;
}

.role {
    font-weight: 400;
}

.profilePopup {
    //width: 196px !important;
    //height: 100px !important;
}

.rotate-15 {
    transform: rotate(-15deg);
}

.icon-margin {
    margin-right: 0.5em;
}

.v-main {
    background-image: url('./assets/bg.png');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

</style>
