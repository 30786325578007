<template>
  <v-card class="rounded-xl">
    <FullScreenLoading :retrieving-templates-prop="retrievingTemplates" />

    <v-img
      height="20vh"
      src="./../assets/4Ad7ukUd.jpeg"
      class="rounded-xl rounded-b-0"
    />
    <v-container>
      <div class="avatar">
        <v-avatar color="primary" size="120">
          <img :src="localProperties.photo" alt="profile_photo" />
        </v-avatar>
        <div
          class="background-camera"
          @mousedown="showModalPhotoChange = true"
        >
          <v-icon class="camera" color="secondary-color">
            mdi-pencil
          </v-icon>
        </div>
      </div>
      <v-dialog v-model="showModalPhotoChange" width="400">
        <v-card>
          <v-card-title> Modifica avatar</v-card-title>
          <div class="d-flex justify-center flex-column pa-5">
            <v-file-input
              v-model="inputFile"
              label="Seleziona l'avatar..."
              prepend-icon="mdi-camera"
              filled
              accept="image/*"
              hint="Il limite massimo è di 500KB"
              persistent-hint
              :rules="imageValidation"
              @change="fileSelected"
            />
          </div>
          <div class="d-flex justify-center pa-6">
            <v-btn
              id="buttonUpload"
              color="primary"
              rounded
              :disabled="
                inputFile == null || uploadChange.size > 500000
              "
              @click="uploadFile()"
            >
              Upload
            </v-btn>
            <v-btn
              id="buttonUpload"
              color="red"
              rounded
              @click="showModalPhotoChange = false"
            >
              Chiudi
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-form ref="form" v-model="valid">
        <v-row class="textboxHeight editMargin">
          <v-col>
            <span class="subTitle">Nome</span>
            <v-text-field
              id="name"
              v-model="localProperties.name"
              solo
              :rules="[(v) => isFieldFilled(v)]"
              dense
              @keyup="delayValidationUser()"
            />
          </v-col>
          <v-col>
            <span class="subTitle">Cognome</span>
            <v-text-field
              id="surname"
              v-model="localProperties.surname"
              solo
              dense
              :rules="[(v) => isFieldFilled(v)]"
              @keyup="delayValidationUser()"
            />
          </v-col>
        </v-row>
        <v-row class="textboxHeight">
          <v-col>
            <span class="subTitle">Username</span>
            <v-text-field
              id="username"
              v-model="localProperties.username"
              required
              :rules="[(v) => isUsernameValid(v)]"
              :class="
                (isSoloMailSelected ? 'v-chip--disabled' : '')
                  + ' fieldWidth'
              "
              dense
              :disabled="isSoloMailSelected"
              solo
              @keyup="delayValidationUser()"
            />
          </v-col>
          <v-col>
            <span class="subTitle">Email</span>
            <v-text-field
              id="email"
              v-model="localProperties.email"
              required
              class="fieldWidth"
              :disabled="!isCurrentUserAdministrator()"
              :rules="[ruleIsEmailValid, (v) => isFieldFilled(v)]"
              dense
              solo
              @keyup="delayValidationUser()"
            />
          </v-col>
        </v-row>
        <v-row class="textboxHeight">
          <v-col cols="12" md="12">
            <span class="subTitle">Ruolo</span>
            <v-select
              id="role"
              v-model="localProperties.role"
              solo
              dense
              :disabled="!isCurrentUserAdministrator()"
              :rules="[(v) => isFieldFilled(v)]"
              :label="localProperties.role"
              :items="allRolesAvailable"
              @keyup="delayValidationUser()"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="isUserManager(localProperties.role)"
          class="textboxHeight"
        >
          <v-col cols="12" md="12">
            <span class="subTitle">Tipologia manager</span>
            <v-select
              id="manager_type"
              v-model="localProperties.sub_role"
              solo
              dense
              :rules="[(v) => isFieldFilled(v)]"
              :disabled="!isCurrentUserAdministrator()"
              label="Selezionare..."
              :items="allManagerTypes"
              @change="delayValidationUser()"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="isUserManager(localProperties.role)"
          class="textboxHeight"
        >
          <v-col cols="12" md="12">
            <span class="subTitle">Azienda del manager</span>
            <v-select
              id="company"
              v-model="localProperties.company_id"
              solo
              return-object
              :disabled="!isCurrentUserAdministrator()"
              dense
              :rules="[(v) => isFieldFilled(v)]"
              label="Azienda del manager..."
              :items="allCompanies"
            />
          </v-col>
        </v-row>

        <v-row v-if="!isSoloMailSelected" class="textboxHeight">
          <v-col cols="12" md="6">
            <span class="subTitle">Nuova Password</span>
            <v-text-field
              id="password"
              v-model="password"
              placeholder="•••••"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              :rules="[
                rulesUsersModify,
                ruleIsPasswordLengthValid,
              ]"
              solo
              dense
              @keyup="delayValidationUser()"
              @click:append="show1 = !show1"
            />
          </v-col>
          <v-col cols="12" md="6">
            <span class="subTitle">Conferma password</span>

            <v-text-field
              id="confirmPwd"
              v-model="confirmUserPassword"
              placeholder="•••••"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              :rules="[rulesConfirmPasswordUserModify]"
              name="input-10-1"
              :hidden="password === ''"
              solo
              dense
              @keyup="delayValidationUser()"
              @click:append="show2 = !show2"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="
            !isCurrentUserAdministrator()
              && isUpdatingExistingUser
              && password !== ''
          "
          class="textboxHeight"
        >
          <v-col cols="12">
            <span class="subTitle">Password precedente</span>
            <v-text-field
              id="previousPassword"
              v-model="previousPassword"
              placeholder="•••••"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              name="input-10-3"
              :rules="[
                previousPassword === '' && password !== ''
                  ? 'Campo richiesto'
                  : true,
              ]"
              solo
              dense
              @keyup="delayValidationUser()"
              @click:append="show3 = !show3"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row class="textboxHeight mt-10 mb-6" justify="center">
        <v-btn
          class="saveButtonAdd"
          color="primary"
          :disabled="!valid"
          height="48"
          width="96.7%"
          @click="
            isCreatingNewUser
              ? addUser()
              : isUpdatingExistingUser
                ? saveUser()
                : ''
          "
        >
          Salva
        </v-btn>
        <!-- <v-btn
          class="closeButton"
          color="red"
          rounded
          @click="$emit('closeModal')"
        >
          Chiudi
        </v-btn> -->
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios';
// eslint-disable-next-line import/extensions,import/no-unresolved
import constants from '@/constants';
import FullScreenLoading from '@/components/FullScreenLoading.vue';
// eslint-disable-next-line import/extensions
import checkNestedObjects from '@/helpers/mixins/checkNestedObjects';

export default {
  name: 'UserModal',
  components: { FullScreenLoading },
  mixins: [checkNestedObjects],
  // beforeUpdate() {
  //  if(this.isUpdatingExistentUser) {
  //    this.localProperties = Object.assign({}, this.dataInformationProp);
  //  }
  // },
  props: {
    status: {
      // if modal is closed or open
      type: Boolean,
      default: false,
    },
    dataInformationProp: {
      type: Object,
      default: () => {},
    },
    isCreatingNewUser: Boolean,
    isUpdatingExistingUser: Boolean,
  },
  data() {
    return {
      timerAmount: 500,
      allManagerTypes: [],
      allRolesAvailable: [
        { text: 'Amministratore', value: constants.ROLES.ADMINISTRATOR },
        { text: 'Manager', value: constants.ROLES.MANAGER },
      ],
      retrievingTemplates: false,
      showModalPhotoChange: false,
      show1: false,
      show2: false,
      show3: false,
      companySelected: { text: '', value: '' },
      uploadChange: '',

      // inizializzate a true perchè semafori, se false o stringa, restituisce errore
      ruleIsUsernameValid: true,
      rulesLength: true,
      rulesConfirmPasswordUsersAdd: true,
      rulesUsersModify: true,
      rulesConfirmPasswordUserModify: true,
      ruleIsPasswordLengthValid: true,
      ruleIsEmailValid: true,

      // eslint-disable-next-line import/no-unresolved,global-require
      avatarUpload: require('@/assets/avatar_utenti_default.png'),

      imageValidation: [
        (value) => !value
                    || value.size < 500000
                    || 'Il limite massimo è di 500KB',
      ],
      valid: true,

      inputFile: null,

      passwordTouched: false,
      password: '',
      confirmUserPassword: '',
      previousPassword: '',
      managerType: constants.MANAGER_TYPES.MAIL_ONLY.value,
      localProperties: {
        id: '',
        name: '',
        surname: '',
        email: '',
        username: '',
        role: '',
        sub_role: '',
        company_id: null,
        photo: '',
      },
    };
  },
  computed: {
    isSoloMailSelected() {
      const val = this.isUserManager(this.localProperties.role)
                && this.localProperties.sub_role
                    === constants.MANAGER_TYPES.MAIL_ONLY.value;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // if (val) this.localProperties.username = '';
      return val;
    },
    allCompanies: {
      get() {
        return this.$store.getters['companies/getCompanies'];
      },
    },
  },
  watch: {
    isCreatingNewUser(val) {
      if (val) {
        this.localProperties = {
          id: '',
          name: '',
          surname: '',
          email: '',
          username: '',
          role: '',
          sub_role: '',
          photo: require('@/assets/avatar_utenti_default.png'),
        };
        this.$refs.form.resetValidation();
      }
    },
    status(val) {
      if (val) {
        this.$refs.form.resetValidation();
        this.localProperties = { ...this.dataInformationProp };
        this.companySelected = {
          // Transform from numeric id to obj
          // mandatory for correct visualization in select
          text: this.getCompanyById(this.localProperties.company_id),
          value: this.localProperties.company_id,
        };

        this.password = '';
        this.confirmUserPassword = '';
        this.passwordTouched = false;
      }
    },
    password() {
      this.passwordTouched = true;
    },
  },
  created() {
    this.$root.$refs.UserModal = this;

    this.allManagerTypes = Object.entries(
      constants.MANAGER_TYPES,
    ).map(([key, value]) => ({ text: value.text, value: value.value }));
    this.localProperties = { ...this.dataInformationProp };
    if (this.localProperties.sub_role === '') this.localProperties.sub_role = this.managerType;
    this.companySelected = {
      // Transform from numeric id to obj
      // mandatory for correct visualization in select
      text: this.getCompanyById(this.localProperties.company_id),
      value: this.localProperties.company_id,
    };

    this.$nextTick(() => {
      if (this.$refs.form) {
        // this.rulesUsersModify = true;
        // this.rulesConfirmPasswordUserModify = true;
        // this.rulesLengthUsersToModify = true;
        // this.rulesEmail = true;
        this.$refs.form.resetValidation();
      }
    });
    this.$store.commit('companies/setCompanies');
  },
  methods: {
    getCompanyById(id) {
      const companiesFound = this.allCompanies.filter(
        (company) => company.value === id,
      );

      return companiesFound.length > 0 ? companiesFound[0].text : '';
    },
    isUserManager(role) {
      if (role) return role.trim() === constants.ROLES.MANAGER;
      return false;
    },
    isUserAdministrator(role) {
      if (role) return role.trim() === 'amministratore';
      return false;
    },
    isCurrentUserAdministrator() {
      return (
        this.$store.getters.currentUser.role.trim()
                === constants.ROLES.ADMINISTRATOR
      );
    },
    emailIsValid(email) {
      const validEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return validEmailRegex.test(email);
    },
    isUsernameValid(val) {
      // L'username è valido se il ruolo selezionato è manager
      // e il sottoruolo è solo mail, oppure se il campo è fillato
      return this.isSoloMailSelected || this.isFieldFilled(val);
    },
    isFieldFilled(val) {
      return !!val || 'Campo richiesto';
    },
    customResetValidation() {
      this.rulesUsersModify = true;
      this.rulesConfirmPasswordUserModify = true;
      this.ruleIsPasswordLengthValid = true;
      this.ruleIsUsernameValid = true;
      this.ruleIsEmailValid = true;
    },
    delayValidationUser() {
      // Delaying the function execution
      if (this.timerFn) {
        window.clearTimeout(this.timerFn);
      }
      this.timerFn = setTimeout(() => {
        // La password deve essere validata solo se è stata toccata, e se rispetta le condizioni
        // eslint-disable-next-line no-nested-ternary
        this.rulesUsersModify = !this.passwordTouched ? false
          // eslint-disable-next-line no-nested-ternary
          : this.password.trim() === '' ? true
            : this.validatePassword(this.password)
              ? true : 'La password deve contenere 8 caratteri tra cui una lettera maiuscola, una minuscola, un carattere speciale ed un numero';

        this.rulesConfirmPasswordUserModify = this.password !== this.confirmUserPassword
          ? 'Le password non corrispondono'
          : true;

        this.ruleIsPasswordLengthValid = this.password.trim() !== '' && this.password.length < 8
          ? 'Lunghezza min. 8 caratteri'
          : true;

        // Regole per controllo username
        // Ritorna campo obbligatorio se il campo è vuoto
        this.ruleIsUsernameValid = this.localProperties.role === constants.ROLES.MANAGER
                    && this.localProperties.sub_role
                        !== constants.ROLES.MAIL_ONLY_MANAGER
                    && this.localProperties.username === ''
          ? 'Campo obbligatorio'
          : true;

        // this.ruleIsUsernameValid = this.checkUsernameExistent(
        //  this.localProperties.username,
        // )
        //  ? 'Questo username è già esistente'
        //  : true;

        // Regole per le Mail
        this.ruleIsEmailValid = this.emailIsValid(
          this.localProperties.email,
        )
          ? true
          : 'Formato Email non valido';
        if (this.ruleIsEmailValid === true) {
          this.ruleIsEmailValid = this.isEmailAlreadyExistent(
            this.localProperties.email,
          )
            ? "Email già presente all'interno del sistema."
            : true;
        }

        this.$refs.form.validate();
      }, this.timerAmount);
    },
    addUser() {
      /* var msgErrorArr = "";
      //Validare uno ad uno i campi, e mettere il messaggio di errori per ogni
       singolo campo, non generici.
      if (name == false) msgErrorArr += "Il nome inserito è vuoto.\n";
      if (surname == false)
          msgErrorArr += "Il cognome inserito è vuoto.\n";
      if (password == false || confirmPassword == false)
          msgErrorArr += "La password inserita è vuota.\n";
      if (username == false)
          msgErrorArr += "L'username inserito è vuoto.\n";
      if (role == false) msgErrorArr += "Il ruolo inserito è vuoto\n";

      if (!this.emailIsValid(email) || email == false)
          msgErrorArr += "La mail non è valida o non è stata inserita.\n";
      else if (this.isEmailAlreadyExistent(email))
          msgErrorArr += "La mail inserita è già esistente! \n";

      if (msgErrorArr != "") {
          this.error_create_msg = msgErrorArr;
          this.errorCreateToggle = true;
      } else { */
      const newUser = {
        username: this.isSoloMailSelected ? '' : this.localProperties.username,
        name: this.localProperties.name,
        surname: this.localProperties.surname,
        email: this.localProperties.email,
        companyId: this.localProperties.company_id.value,
        password: this.password,
        password_confirmation: this.password,
        role: this.localProperties.role,
        photo: this.avatarUpload,
      };
      if (this.localProperties.role !== constants.ROLES.ADMINISTRATOR) newUser.sub_role = this.localProperties.sub_role;

      this.retrievingTemplates = true;
      axios
        .post(`${process.env.VUE_APP_API_URL}/user/register`, newUser)
        .then((res) => {
          if (String(res.status).match(/^2/)) {
            if (res.data.user && res.data.user.roles) {
              res.data.user.role = this.localProperties.role;
              res.data.user.sub_role = this.managerType.value || '';
            }
            this.$emit('addOk', res.data);
            this.openChangePhotoUsersAdd = false;
            this.$emit('closeModal');

            this.$store.commit('toggleSnackbar', {
              message: 'Utente inserito correttamente',
              color: constants.SUCCESS_MESSAGE_SNACKBAR,
            });

            this.avatarUpload = require('@/assets/avatar_utenti_default.png');
          }
        })
        .catch((err) => {
          // err = { ...err }; // Cast to object
          // if (String(err.response.status).match(/^4/)) {
          /* if (
                    err.response.data.errors.hasOwnProperty("username")
                ) { */
          /* vm.error_create_msg =
                            "L'username inserito non è valido o è stato già utilizzato.
                             Per favore, riprova con un'altro.";
                        vm.errorCreateToggle = true; */
          /* this.customMsg =
                        "L'username inserito non è valido o è stato già utilizzato.
                         Per favore, riprova con un'altro.";
                    this.color_snackbar = "red darken-1";
                    this.snackbar = true; */
          // }
          // } else {
          /* vm.error_create_msg =
                        "Errore di sistema nella creazione utente.
                         Contattare gentilmente il supporto per la risoluzione del problema.";
                    vm.errorCreateToggle = true; */
          console.error(err);
          let message = `Errore di sistema nella creazione utente.\n
                   Contattare gentilmente il supporto per la risoluzione del problema.\n
                   Errore generico: ${err}\n`;
          if (this.checkNested(err, 'response', 'data', 'message')) message += `\nErrore specifico: ${err.response.data.message}`;
          this.$store.commit('toggleSnackbar', {
            message,
            color: constants.ERROR_SNACKBAR,
          });
          // }
        })
        .finally(() => {
          this.retrievingTemplates = false;
        });
      // }
    },
    fileSelected(event) {
      this.uploadChange = event;
    },
    uploadFile() {
      this.avatarUpload = this.uploadChange;
      const reader = new FileReader();
      reader.readAsDataURL(this.avatarUpload);
      reader.onload = () => {
        this.avatarUpload = reader.result;
        this.localProperties.photo = this.avatarUpload;
      };
      reader.onerror = (error) => {
        this.$store.commit('toggleSnackbar', {
          message: `Errore nel caricamento dell'immagine. Gentilmente, contattare l'amministratore di sistema. Errore tecnico: ${error}`,
          color: constants.ERROR_SNACKBAR,
        });
      };
      this.showModalPhotoChange = false;
    },
    saveUser() {
      if (this.avatarUpload === '') {
        this.avatarUpload = this.dataInformationProp.photo;
      }
      const fieldToUpdate = {
        id: this.dataInformationProp.id,
        photo: this.avatarUpload,
        username: this.isSoloMailSelected ? '' : this.localProperties.username,
        name: this.localProperties.name,
        surname: this.localProperties.surname,
        email: this.localProperties.email,
        role: this.localProperties.role,
        company_id: this.localProperties.company_id.value,
      };

      if (
        this.password // TODO: Add control that check if the user wants to change his pwd
      ) {
        fieldToUpdate.password = this.password;
        fieldToUpdate.previousPassword = this.previousPassword;
      }
      this.retrievingTemplates = true;

      if (this.localProperties.role !== constants.ROLES.ADMINISTRATOR) fieldToUpdate.sub_role = this.localProperties.sub_role;

      axios
        .post(
          `${process.env.VUE_APP_API_URL}/user/update`,
          fieldToUpdate,
        )
        .then((res) => {
          if (String(res.status).match(/^2/)) {
            if (res.data.user && res.data.user.roles) {
              res.data.user.role = this.localProperties.role;
              res.data.user.sub_role = this.managerType.value || '';
            }
            this.$emit('updateOk', res.data);
            this.avatarUpload = require('@/assets/avatar_utenti_default.png');
            this.$emit('closeModal');
            this.$store.commit('toggleSnackbar', {
              message: 'Utente modificato correttamente',
              color: constants.SUCCESS_MESSAGE_SNACKBAR,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          this.$store.commit('toggleSnackbar', {
            message: `L'utente NON è stato modificato correttamente: ${e}`,
            color: constants.ERROR_SNACKBAR,
          });
        })
        .finally(() => {
          this.retrievingTemplates = false;
        });
    },

    // disableButtonUserAdd() {
    //  if (this.checkUsernameExistent(this.usernameUsersAdd)) {
    //    return true;
    //  }
    //
    //  if (
    //    !this.emailIsValid(this.emailUsersAdd)
    //      || this.isEmailAlreadyExistent(this.emailUsersAdd)
    //  ) {
    //    return true;
    //  }
    //  if (
    //    this.nameUsersAdd === ''
    //      || this.surnameUsersAdd === ''
    //      || this.usernameUsersAdd == ''
    //      || this.emailUsersAdd == ''
    //      || this.passwordUsersAdd == ''
    //      || this.confirmPasswordUsersAdd == ''
    //      || this.ruoloUsersAdd != 'Amministratore'
    //  ) {
    //    return true;
    //  } if (this.passwordUsersAdd.length < 8) {
    //    return true;
    //  }
    //  return (
    //    !this.validatePassword(this.passwordUsersAdd)
    //        || this.passwordUsersAdd !== this.confirmPasswordUsersAdd
    //  );
    // },

    checkUsernameExistent(/* username */) {
      // for (var i = 0; i < this.users.length; i++) {
      //     var usernameExistent = this.users[i].username;
      //     if (
      //         usernameExistent.toLowerCase().trim() ==
      //         username.toLowerCase().trim()
      //     ) {
      //         return true;
      //     }
      // }
      // return false;

      // TODO: implementare chiamata post quando inserisco utente per verificare se esiste già

      return false;
    },

    validatePassword(password) {
      // Password must be of 8 chars, it must contain 1 special character, 1 upper-case char
      // and 1 lower
      const pwdRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!=€"£^°§<>ç#_%,*?&.])[A-Za-z\d$@$!%*?&.]/;
      return pwdRegex.test(password);
    },

    isEmailAlreadyExistent(/* strEmailForm */) {
      // for (var i = 0; i < this.users.length; i++) {
      //    var emailLette = this.users[i].email;
      //    if (
      //        emailLette.toLowerCase().trim() ==
      //        strEmailForm.toLowerCase().trim()
      //    ) {
      //        return true;
      //    }
      // }
      // return false;
      // TODO: implementare chiamata che fa la chiamata post quando inserisco utente per verificare
      //  se esiste già
      return false;
    },
  },
};
</script>

<style>
.textboxHeight {
    height: 65px;
    margin: 0 20px 0 20px;
}

.saveButtonAdd {
    text-transform: capitalize;
    margin-top: 30px;
}

.saveButtonAdd:disabled {
    background-color: aqua !important;
}

.avatar {
    display: flex;
    align-items: center;
    position: relative;
    bottom: 60px;
    flex-direction: column;
    font-weight: bold;
    /*margin-bottom: 20px;*/
}

.closeButton {
    width: 120px;
    text-transform: capitalize;
    margin-top: 10px;
    color: #fff !important;
    margin-left: 10px;
}

.editMargin {
    margin-top: -50px !important;
}

.subTitle {
    font-size: 12px;
    font-weight: bold;
}

.secondary-color {
    color: #05ffb4;
}
</style>
