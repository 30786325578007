<template>
  <!-- Schermata caricamento -->
  <v-dialog
    v-model="isSomethingLoading"
    content-class="custom_dialog"
    persistent
  >
    <div class="text-center">
      <v-progress-circular
        indeterminate
        color="white"
      />
    </div>
  </v-dialog>
  <!-- Fine schermata caricamento -->
</template>

<script>
export default {
  name: 'FullScreenLoading',
  props: {
    retrievingTemplatesProp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    isSomethingLoading: {
      get() {
        return this.$store.getters.isSomethingLoading;
      },
      set(status) {
        this.$store.commit('changeStatusSomethingLoading', status);
      },
    },
  },
  watch: {
    retrievingTemplatesProp(status) {
      this.$store.commit('changeStatusSomethingLoading', status);
      // this.isSomethingLoading = this.retrievingTemplatesProp;
    },
  },
};
</script>

<style scoped>

.custom_dialog {
  box-shadow: unset !important;
  overflow: unset !important;
}

</style>
