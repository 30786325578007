// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a{text-decoration:none}#nav{height:100%}#nav button{margin:0}#nav a{text-decoration:none}.button{color:#fff}.custom-color{color:#4e5ac3!important}.customGreen--text:active,.customGreen--text:hover{color:#05ffb4!important}.router-link-exact-active button{color:#05ffb4!important;border-bottom:3px solid #05ffb4;border-radius:5px;margin:0 auto;margin-top:10px;background-color:#4e5ac3}.v-btn{text-transform:none!important}.background_gradient_eb{background-color:#4e35e5!important}.sticky_toolbar{position:sticky;top:0;z-index:1}#avatar{left:5px}.nameSurname{font-weight:700}.role{font-weight:400}.profilePopup{//width:196px!important;//height:100px!important}.rotate-15{transform:rotate(-15deg)}.icon-margin{margin-right:.5em}.v-main{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-size:cover;background-attachment:fixed}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
