<template>
  <div class="tool-version">
    Version {{ pjson.version }}
  </div>
</template>
<script>

const pjson = require('@/../package.json');

export default {
  name: 'ToolVersion',
  computed: {
    pjson() {
      return pjson;
    },
  },
};
</script>

<style scoped>
  .tool-version {
    position: relative;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    text-align: center;
    padding: 10px;
  }
</style>
