<template>
  <div>
    <div v-if="slides.length != 0">
      <v-dialog
        v-model="buildingPdf"
        content-class="custom_dialog"
        persistent
      >
        <div class="text-center">
          <v-card dark class="custom_dialog">
            <v-card-text>
              Slide in lavorazione: {{ currentSlideProcessing }}
              <div class="mt-3">
                <v-progress-circular
                  :rotate="270"
                  :size="100"
                  :width="15"
                  :value="value"
                  color="teal"
                >
                  {{ value }}
                </v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
      <WizardHeader @update-saving="updateSaving" />
      <WizardContent />
      <v-snackbar
        v-model="snackbarStatus"
        :timeout="timeout"
        :color="color_snackbar"
      >
        {{ snackbarMsg }}
        <template #action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbarStatus = false"
          >
            Chiudi
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <div v-else>
      <v-container
        fluid
        class="d-flex align-center justify-center custom-height"
      >
        Nessun report selezionato! Crea o seleziona un
        report.
      </v-container>
    </div>
    <v-dialog v-model="dialogExit" persistent width="400">
      <v-card>
        <v-card-title>
          Avviso
        </v-card-title>
        <v-card-text>
          Uscendo perderai tutti i dati non salvati! Sei davvero
          sicuro di uscire?
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn icon text color="primary" @click="confirmDialog()">
            Si
          </v-btn>
          <v-btn
            icon
            text
            color="primary"
            @click="
              nextObj(false);
              dialogExit = false;
            "
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="saving" content-class="custom_dialog" persistent>
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="white"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import WizardHeader from '../components/wizard/Header.vue';
import WizardContent from '../components/wizard/Content.vue';

export default {
  name: 'Wizard',
  components: { WizardHeader, WizardContent },
  async beforeRouteLeave(to, from, next) {
    if (this.slides.length > 0 && this.$store.getters.report.draft) {
      this.nextObj = next;
      this.dialogExit = true;
    } else {
      if (document.getElementsByTagName('main').length > 0) {
        document
          .getElementsByTagName('main')[0]
          .classList.add('scrollable', 'scrollbar-grey');
      }
      next();
    }
  },
  data() {
    return {
      dialogExit: false,
      response: false,
      nextObj: null,
      color_snackbar: 'primary',
      timeout: 5000,
      saving: false,
    };
  },
  computed: {
    snackbarStatus: {
      get() {
        return this.$store.getters.snackbar.status;
      },
      set() {
        this.$store.commit('toggleSnackbar', {
          status: false,
        });
      },
    },
    snackbarMsg: {
      get() {
        return this.$store.getters.snackbar.message;
      },
    },
    currentSlideProcessing: {
      get() {
        const { processedTemplates } = this.$store.getters.buildingPdfState;

        return processedTemplates !== undefined
          ? processedTemplates.slice(-1)[0]
          : '';
      },
    },
    buildingPdf: {
      get() {
        return this.$store.getters.buildingPdf;
      },
    },
    value: {
      get() {
        return this.$store.getters.buildingPdfState.completed;
      },
    },
    slides() {
      return this.$store.getters.slides.filter(
        (slide) => slide.type != 'titolo'
                    && slide.type != 'canvas'
                    && slide.type != 'riepilogo',
      );
    },
  },
  watch: {
    response() {},
    $route(to) {
      if (to.path == '/wizard') {
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        window.scrollTo(0, 0);
        this.routeLoaded();
        // eslint-disable-next-line no-unused-vars
        // this.$nextTick(this.routeLoaded);
      } else document.getElementsByTagName('html')[0].style.overflowY = 'visible';
    },
  },
  mounted() {
    this.routeLoaded();
  },
  /*  beforeRouteEnter(to, from, next) {
        next(() => {

        });
    }, */
  beforeCreate() {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    window.scrollTo(0, 0);
  },
  methods: {
    routeLoaded() {
      if (document.getElementsByTagName('main').length > 0) {
        document
          .getElementsByTagName('main')[0]
          .classList.remove('scrollable');
      }
    },
    confirmDialog() {
      if (document.getElementsByTagName('main').length > 0) {
        document
          .getElementsByTagName('main')[0]
          .classList.add('scrollable');
      }
      this.$store.commit('resetState');
      this.response = false;
      this.nextObj();
      this.dialogExit = false;
    },
    updateSaving(status) {
      this.saving = status;
    },
  },
};
</script>

<style scoped>
.custom_footer_sticky {
    position: fixed;
    bottom: 0;
    left: 0;
}

.custom-height {
    height: calc(100vh - 48px);
}
.custom_dialog {
    box-shadow: unset !important;
    overflow: unset !important;
}
</style>
