/* eslint-disable */
import { Model, createServer, Response } from 'miragejs';

import get_company from './responses_mocking/get_company';
import get_facebook_campaigns from './responses_mocking/get_facebook_campaigns';
import get_facebook_campaigns2 from './responses_mocking/get_facebook_campaigns2';
import get_yt_campaigns from './responses_mocking/get_google_campaigns.json';
import get_yt_campaigns2 from './responses_mocking/get_google_campaigns2.json';
import get_tiktok_campaigns from './responses_mocking/get_tiktok_campaigns.json';
import get_tiktok_campaigns2 from './responses_mocking/get_tiktok_campaigns2.json';

import get_customers from './responses_mocking/get_customer.json';
import get_reports from './responses_mocking/get_report.json';
import get_specific_report from './responses_mocking/post_specific_report.json';
import get_all_users from './responses_mocking/get_users.json';

import get_campaign_insights from './responses_mocking/get_insights_campaign.json'
import get_campaign_adsets_insights from './responses_mocking/get_campaign_adsets_insights.json'
import get_campaign_ads_adset_insights from './responses_mocking/get_campaign_ads_adset.json'
import constants from "@/constants";

export function makeServer({ environment = 'development' } = {}) {
  const moment = require('moment');

  const nomi_campagne = ['Campagna FB', 'Campagna YT', 'Campagna TT'];

  const social = ['yt', 'gg', 'ig', 'fb', 'tt'];
  const arrayTemplatesYT = [];
  const arrayTemplatesIG = [];
  const arrayTemplatesTT = [];

  const accounts = [
    'r.marrone@gmail.com',
    'p.marrone@gmail.com',
    'l.giallo@gmail.com',
    'g.verde@gmail.com',
    'l.viola@gmail.com',
    'r.azzurro@gmail.com',
  ];

  const nomi = ['Giovanni', 'Roberto', 'Michele', 'Saverio', 'Marco'];
  const cognomi = ['Surico', 'Fratalli', 'Pivollo', 'Rubini', 'Selerio'];
  const aziende = [
    'Island',
    'Warner Bros',
    'Sony Entertainment',
    'Sony Int',
    'Sony Digital',
    'Sony Legacy',
    'Sony Legacy',
    'Sony Legacy',
    'Ala bianca',
    'Virgin',
  ];

  const arrTypesYt = [
    'preroll',
    'discovery',
    'yt_banner',
    'web_banner',
    'static_discovery',
  ];
  const arrTypesFb = ['fb_feed', 'ig_feed', 'fb+ig_feed', 'ig_story'];

  const posArr = [
    {
      id: 0,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 1,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 2,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 3,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 4,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 5,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 6,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 7,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 8,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 9,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 10,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 11,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 12,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 13,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 14,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 15,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 16,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 17,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 18,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
    {
      id: 19,
      canale: '...',
      video: '',
      impressions: Math.round(Math.random() * 1000),
      views: Math.round(Math.random() * 10009), // 0
      // ...
      // [...] // 19
    },
  ];

  function generateClienti() {
    // var iter = Math.floor(Math.random() * 15) + 1;
    const iter = aziende.length;
    const users = [];
    for (let i = 0; i < iter; i++) {
      users.push({
        id: Math.round(Math.random() * 100000),
        name: nomi[Math.floor(Math.random() * nomi.length)],
        surname: cognomi[Math.floor(Math.random() * cognomi.length)],
        email: '',
        company: aziende[i],
        created_at: '',
        password: '',
        photo_url: '',
        disabled: true,
      });
    }

    // Test duplicati
    users.push({
      id: Math.round(Math.random() * 100000),
      name: nomi[Math.floor(Math.random() * nomi.length)],
      surname: cognomi[Math.floor(Math.random() * cognomi.length)],
      email: '',
      company: aziende[0],
      created_at: '',
      password: '',
      photo_url: '',
      disabled: true,
    });
    return users;
  }

  function randomDate() {
    const start = new Date(2020, 0, 1);
    const end = new Date();
    return moment(
      new Date(
        start.getTime()
                + Math.random() * (end.getTime() - start.getTime()),
      ),
    ).format('YYYY-MM-DD');
  }

  function generateFakeData(title, id_campagna, type) {
    const staticObj = {
      id_campagna,
      type: determineType(title),
      id_account: Math.round(Math.random() * 1000000),
      campaign_name: title,
      // titolo_slide: "",
      confirmed: false,
      // start_date: randomDate()
    };

    let dynamicObj = {};
    switch (type) {
      case 'preroll':
        // YT preroll
        dynamicObj = {
          social: 'yt',
          data: {
            durata: 0, // secondi
            titolo_annuncio: '',
            impression: 1000,
            views: 10000,
            clic: 200,
            ripr: {
              video_25: 0.0, // Float
              video_50: 0,
              video_75: 0,
              video_100: 0,
            },
            pos: posArr,
          },
        };
        break;
      case 'discovery':
        dynamicObj = {
          social: 'yt',
          data: {
            durata: 0, // secondi
            titolo_annuncio: '',
            canale: '',
            visualizzazioni: 0,
            impression: 1000,
            views: 10000,
            clic_views: 200,
            ripr: {
              video_25: 0.0, // Float
              video_50: 0,
              video_75: 0,
              video_100: 0,
            },
          },
        };
        break;
      case 'yt_banner':
        dynamicObj = {
          social: 'yt',
          data: {
            impression: 1000,
            clic: 200,
            pos: posArr,
          },
        };
        break;
      case 'web_banner':
        dynamicObj = {
          social: 'yt',
          data: {
            impression: 1000,
            clic: 200,
            pos: posArr,
          },
        };
        break;
      case 'static_discovery':
        dynamicObj = {
          social: 'yt',
          data: {
            impression: 1000,
            clic: 200,
            titolo_annuncio: '',
          },
        };
        break;
      case 'fb_feed':
        dynamicObj = {
          campaign_subject: '',
          campaign_msg: '',
          social: 'ig',
          data: {
            fb: {
              unique_user_achieved: 1000,
              link_clicks: 200,
              comments: 0,
              impressions: 0,
              reactions: 0,
              shares: 0,
            },
          },
        };
        break;
      case 'ig_feed':
        dynamicObj = {
          campaign_subject: '',
          campaign_msg: '',
          social: 'ig',
          data: {
            ig: {
              unique_user_achieved: 1000,
              link_clicks: 200,
              comments: 0,
              impressions: 0,
              reactions: 0,
              shares: 0,
            },
          },
        };
        break;
      case 'fb+ig_feed':
        dynamicObj = {
          campaign_subject: '',
          campaign_msg: '',
          social: 'ig',
          data: {
            fb: {
              unique_user_achieved: 1000,
              link_clicks: 200,
              comments: 0,
              impressions: 0,
              reactions: 0,
              shares: 0,
            },
            ig: {
              unique_user_achieved: 1000,
              link_clicks: 200,
              comments: 0,
              impressions: 0,
              reactions: 0,
              shares: 0,
            },
          },
        };
        break;
      case 'ig_story':
        dynamicObj = {
          social: 'ig',
          data: {
            unique_user_achieved: 1000,
            link_clicks: 200,
            impressions: 0,
            shares: 0,
          },
        };
        break;
      case 'tt':
        dynamicObj = {
          campaign_subject: '',
          campaign_msg: '',
          social: 'tt',
          data: {
            impression: 0,
            click_per_link: 0,
            like: 0,
            comments: '',
            shares: '',
            unique_user_achieved: 0,
          },
        };
        break;
      default:
        break;
    }
    return {
      ...staticObj,
      ...dynamicObj,
    };
  }

  function determineType(name) {
    name = name.toLowerCase();
    if (name.includes('fb feed')) return 'fb_feed';
    if (name.includes('fb + ig feed')) return 'fb+ig_feed';
    if (name.includes('ig feed')) return 'ig_feed';
    if (name.includes('ig story')) return 'ig_story';
    if (name.includes('preroll')) return 'preroll';
    if (name.includes('discovery video')) return 'discovery';
    if (name.includes('discovery statica')) return 'static_discovery';
    if (name.includes('yt banner')) return 'yt_banner';
    if (name.includes('web banner')) return 'web_banner';
    return 'tt';
  }

  return createServer({
    environment,
    models: {
      campaign: Model,
      campaignFb: Model,
      campaignYt: Model,
      campaignTt: Model,
    },

    seeds(server) {
      for (let i = 0; i < 50; i++) {
        server.create('campaign', {
          campaign_name:
                        nomi_campagne[
                          Math.floor(nomi_campagne.length * Math.random())
                        ],
          social: social[Math.floor(social.length * Math.random())],
          account:
                        accounts[Math.floor(accounts.length * Math.random())],
          created: randomDate(),
        });
      }

      // ------------------ Campagne FB
      const tot_campaigns_fb = 90;
      const arrayDataFb = [];
      for (let j = 0; j < tot_campaigns_fb; j++) {
        arrayDataFb.push({
          id: Math.floor(Math.random() * 10000000000) + 1,
          campaign_name: `${nomi_campagne[0]} ${j + 1}`, // Math.floor(nomi_campagne.length * Math.random())
          social: 'ig',
          account:
                        accounts[Math.floor(accounts.length * Math.random())],
          start_date: randomDate(new Date(2020, 0, 1), new Date()),
        });
      }

      server.create('campaignFb', {
        tot_campaigns: tot_campaigns_fb,
        data: arrayDataFb,
      });

      // ------------------ Campagne YT
      const tot_campaigns_yt = 100;
      const arrayDataYt = [];
      for (let z = 0; z < tot_campaigns_yt; z++) {
        arrayDataYt.push({
          id: Math.floor(Math.random() * 10000000000) + 1,
          campaign_name: `${nomi_campagne[1]} ${z + 1}`,
          // Math.floor(nomi_campagne.length * Math.random())
          social: 'yt',
          account:
                        accounts[Math.floor(accounts.length * Math.random())],
          start_date: randomDate(new Date(2020, 0, 1), new Date()),
        });
      }
      server.create('campaignYt', {
        tot_campaigns: tot_campaigns_yt,
        data: arrayDataYt,
      });

      // ------------------------------------ Campagne Tik Tok ------------------------------------
      const tot_campaigns_tt = 110;
      const arrayDataTT = [];
      for (let k = 0; k < tot_campaigns_tt; k++) {
        arrayDataTT.push({
          id: Math.floor(Math.random() * 10000000000) + 1,
          campaign_name: `${nomi_campagne[2]} ${k + 1}`, // Math.floor(nomi_campagne.length * Math.random())
          social: 'tt',
          account:
                        accounts[Math.floor(accounts.length * Math.random())],
          start_date: randomDate(new Date(2020, 0, 1), new Date()),
        });
      }
      server.create('campaignTt', {
        tot_campaigns: tot_campaigns_tt,
        data: arrayDataTT,
      });
    },

    routes() {
      // this.namespace = "/api";
      // this.urlPrefix = 'http://localhost:8000';

      this.urlPrefix = process.env.VUE_APP_API_URL;

      this.post(
        '/user/login',
        (schema, req) => {
          return new Response(200, {}, {
            code: 200,
            message: 'success',
            user: {
              id: 1,
              username: 'Admingrifo',
              name: 'Administrator',
              surname: 'Beautiful',
              email: 'serse06@benedetti.net',
              email_verified_at: null,
              photo: '',
              role: JSON.parse(req.requestBody).username === "m" ? constants.ROLES.MANAGER : constants.ROLES.ADMINISTRATOR,
              subrole: 'manager_pro',
              user_meta: [],
              customer_id: null,
              created_at: '2021-11-19T08:51:40.000000Z',
              updated_at: '2021-11-29T08:57:14.000000Z',
              locked: 0,
            },
            access_token: 'boo',
          })
        }
        // ,{ timing: 5192 }
      );
      
      this.post(
        '/company/get',
        (schema, req) => get_company,
        // ,{ timing: 5192 }
      );

      if(process.env.VUE_APP_MAKE_THIRD_API_CALLS === 'false' || typeof process.env.VUE_APP_MAKE_THIRD_API_CALLS === 'undefined') {
        this.get(
          '/facebook/campaigns',
          () => get_facebook_campaigns,
          // return new Response(401, {}, {}) // access token invalid
          /* return new Response(200, {}, {
                          message: "finalmente",
                          social: "facebook",
                          campaigns_errors: [
                              {
                                  account_name: "act_1",
                                  error_msg: "errore 1"
                              },
                              {
                                  account_name: "act_2",
                                  error_msg: "errore 2"
                              }
                          ]
                      }) */

          //                { timing: 4000 }
        );

        this.post(
          '/nd/insights/:campaign_id',
          () => get_campaign_insights,
          //                { timing: 4000 }
        );
        this.post(
          '/nd/insights/:campaign_id/ad_groups',
          () => get_campaign_adsets_insights,
          //                { timing: 4000 }
        );
        this.post(
          '/nd/insights/:campaign_id/ad_groups/:ad_group_id/ads',
          () => get_campaign_ads_adset_insights,
          //                { timing: 4000 }
        );
        this.get(
          '/google/campaigns',
          () => get_yt_campaigns2,
          //                { timing: 4000 }
        );

        //this.post(
        //  '/google/insights/:campaign_id',
        //  () => get_google_insights_campaign,
        //  //                { timing: 4000 }
        //);

        this.get(
          '/tiktok/campaigns',
          () => get_tiktok_campaigns,
          // { timing: 5000 }
        );

        //this.post(
        //  '/tiktok/insights/:campaign_id',
        //  () => get_tiktok_insights_campaign,
        //  // { timing: 5000 }
        //);

        this.post(
          '/google/reportsData',
          (schema, req) => {
            /*    var request = JSON.parse(req.requestBody).ytArr;
                         // Prendo le campagne selezionate, e per ogni campagna
                         var selectedRowsLenght = request.length;
                         var arrTemplatesYt = [];
                         for (let id = 0; id < selectedRowsLenght; id++) {
                             arrTemplatesYt.push(
                                 generateFakeData(
                                     request[id].title,
                                     request[id].id,
                                     arrTypesYt[
                                     //  Math.floor(
                                     //    arrTypesYt.length * Math.random()
                                     //)
                                     id % arrTypesYt.length
                                     ]
                                 )
                             );
                         }
                         return {
                             code: 200,
                             message: "success",
                             templates: arrTemplatesYt
                         }; */
          },
          { timing: 1000 },
        );

        this.post(
          '/facebook/getTemplatesData',
          (schema, req) => {
            const request = JSON.parse(req.requestBody).igArr;
            const selectedRowsLenght = request.length;
            const arrTemplatesIg = [];
            for (let id = 0; id < selectedRowsLenght; id++) {
              arrTemplatesIg.push(
                generateFakeData(
                  request[id].title,
                  request[id].id,
                  arrTypesFb[
                    Math.floor(
                      arrTypesFb.length * Math.random(),
                    )
                    ],
                ),
              );
            }
            return {
              code: 200,
              message: 'success',
              templates: arrTemplatesIg,
            };
          },
          // { timing: 5000 }
        );

        this.post(
          '/tiktok/getTemplatesData',
          (schema, req) => {
            const request = JSON.parse(req.requestBody).ttArr;
            const selectedRowsLenght = request.length;
            const arrTemplatesTt = [];
            for (let id = 0; id < selectedRowsLenght; id++) {
              arrTemplatesTt.push(
                generateFakeData(
                  request[id].title,
                  request[id].id,
                  'tt',
                ),
              );
            }
            return {
              code: 200,
              message: 'success',
              templates: arrTemplatesTt,
            };
          },
          // { timing: 5000 }
        );
      }

      //this.post(
      //  '/customer/get',
      //  (schema, req) =>
      //    /* return {
      //                  code: 200,
      //                  message: "success",
      //                  companies: generateClienti()
      //              }; */
      //    get_customers
      //  ,
      //  // { timing: 5000 }
      //);

      this.post(
        '/report/get',
        (schema, request) => ((request.requestBody && request.requestBody.length > 0) ? get_specific_report : get_reports),
      );

      this.post(
        '/users/all',
        () => get_all_users,
      );

      this.post(
        '/pdf/downloadPreview',
        () => ({
          path: [],
          notDownloaded: '{Bomba Dischi} [CLAVDIO] "Tour" FB+IG FEED',
        }),
      );

      // eslint-disable-next-line prettier/prettier
      this.passthrough('http://ec2-54-73-208-43.eu-west-1.compute.amazonaws.com/**');
      this.passthrough('http://explodingbands.grifomultimedia.it/**');
      this.passthrough('http://api.explodingreports.it/**');
      this.passthrough('https://api.instantwebtools.net/**');
      this.passthrough('http://127.0.0.1:8000/**');

      this.passthrough();
    },
  });
}
