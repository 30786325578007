<template>
  <v-container fluid class="pa-3">
    <v-dialog
      v-model="retrievingData"
      content-class="custom_dialog"
      persistent
    >
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="white"
        />
      </div>
    </v-dialog>
    <div class="d-flex float-right align-center width_min_con">
      <span class="mr-5 ">Mostra</span>
      <v-select
        v-model="itemsPerPage"
        class="custom_ses_input"
        height="30"
        :items="show"
        hide-details="auto"
        outlined
        :disabled="!dataLoaded"
        :value="show[0]"
        return-object
      />

      <!-- @change="updatePagination" -->
    </div>
    <v-snackbar
      v-model="snackbar"
      :color="color_snackbar"
      :timeout="timeout"
    >
      {{ customMsg }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          outlined
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
    <div style="clear:both" />
    <v-data-table
      :headers="headers"
      hide-default-header
      hide-default-footer
      :items="reports"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      no-data-text="Non è ancora presente nessun report..."
      no-results-text="La ricerca fra i report non ha portato ad alcun risultato..."
      :loading="!dataLoaded"
      loading-text="Sto analizzando tutti i report..."
      class="mt-3 elevation-4"
      @pagination="getFiltered"
    >
      <template #[`item.actions`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              class="mr-3 pa-4 viola_eb rounded"
              dark
              icon
              elevation="1"
              small
              tile
              v-bind="attrs"
              v-on="on"
            >
              <v-icon @click="editReport(item)">
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Modifica report</span>
        </v-tooltip>

        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              class="mr-3 pa-4 rounded blu_eb"
              color="white"
              elevation="1"
              icon
              small
              tile
              v-bind="attrs"
              v-on="on"
            >
              <v-icon @click="duplicateReport(item)">
                mdi-content-copy
              </v-icon>
            </v-btn>
          </template>
          <span>Duplica report</span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn
              class="pa-4 rounded verde_eb"
              color="white"
              elevation="1"
              icon
              :disabled="item.complete == 0"
              small
              tile
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="pt-1" @click="exportReport(item)">
                mdi-download
              </v-icon>
            </v-btn>
          </template>
          <span>Scarica report</span>
        </v-tooltip>
      </template>

      <template #[`item.complete`]="{ item }">
        {{ item.complete == 1 ? "Completo" : "Incompleto" }}
      </template>

      <template #header="{ props: { headers } }">
        <thead>
          <tr>
            <th
              v-for="h in headers"
              :key="h.id"
              :width="calcWidth(h)"
            >
              {{ h.text }}
              <v-icon
                v-if="h.text !== ''"
                class="ml-1"
                small
                :color="h.isSearchActive ? 'blue' : ''"
                @click="toggleSearch(h.id)"
              >
                mdi-magnify
              </v-icon>
              <v-icon
                v-if="h.sortable"
                class="ml-1"
                small
                @click="toggleSort(h.id)"
              >
                mdi-sort{{ sortMode(h.value) }}
              </v-icon>
            </th>
          </tr>
          <tr
            height="70px"
            :class="{
              'visible-true': searchFilterSelected <= 0,
              'change-opacity bg-color-row ': true,
            }"
          >
            <th v-for="n in 3" :key="`filter-${n}`" color="blue">
              <v-text-field
                v-model="headersFiltered[n - 1].valueFilter"
                :class="{
                  'filter--hidden': !headersFiltered[n - 1]
                    .isSearchActive,
                  'change-opacity ': true,
                }"
                hide-details
                style="width:130px"
                outlined
                :label="headersFiltered[n - 1].text"
              />
            </th>
            <th>
              <v-select
                v-model="headersFiltered[3].valueFilter"
                :items="headersFiltered[3].possibleValues"
                :class="{
                  'filter--hidden': !headersFiltered[3]
                    .isSearchActive,
                  '': true,
                }"
                hide-details
                style="width:130px"
                solo
              />
            </th>
            <th>
              <!-- calendario -->

              <v-menu
                ref="menu"
                v-model="menu"
                :class="{
                  'filter--hidden': !headersFiltered[4]
                    .isSearchActive,
                }"
                hide-details
                :close-on-content-click="false"
                :return-value.sync="headers[4].date"
                transition="scale-transition"
                offset-y
                min-width="90px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="headers[4].date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    style="width:130px"
                    :class="{
                      'filter--hidden': !headersFiltered[4]
                        .isSearchActive,
                    }"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="headers[4].date"
                  :class="{
                    'filter--hidden': !headersFiltered[4]
                      .isSearchActive,
                  }"
                  locale="it-it"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="
                      menu = false;
                      headers[4].date = '';
                    "
                  >
                    Reset
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.menu.save(headers[4].date)
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <!-- fine cal -->
            </th>
            <th />
          </tr>
        </thead>
      </template>
      <template #footer>
        <div class="text-center">
          <v-pagination
            v-model="page"
            color="primary"
            :length="pagesNum"
            :total-visible="7"
          />
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
/* Helper function */
// import printJS from "print-js";
import Axios from 'axios';

function download_file(fileURL, fileName) {
  // for non-IE
  if (!window.ActiveXObject) {
    const save = document.createElement('a');
    save.href = fileURL;
    save.target = '_blank';
    const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/)
            && navigator.userAgent.search('Chrome') < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    const _window = window.open(fileURL, '_blank');
    _window.document.close();
    _window.document.execCommand('SaveAs', true, fileName || fileURL);
    _window.close();
  }
}
export default {
  name: 'Report',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // access to component instance via `vm`
      Axios.post(`${process.env.VUE_APP_API_URL}/report/get`).then(
        (res) => {
          res.data.report.forEach(
            (el) => (el.created_at = el.created_at.split(' ')[0]),
          );
          vm.reports = res.data.report;
          // this.calcPagesNum(this.reports.length);
          vm.dataLoaded = true; // Imposto il caricamento a completato
        },
      );
    });
  },
  beforeRouteLeave(to, from, next) {
    switch (to.path) {
      case '/wizard':
        {
          if (this.idItemToWork != -1) {
            this.retrievingData = true;

            Axios.post(
              `${process.env.VUE_APP_API_URL}/report/get`,
              {
                report_id: this.idItemToWork,
              },
            )
              .then((res) => {
                if (this.toDuplicate) {
                  res.data.report.name = res.data.report.name.concat(
                    ' (COPIA)',
                  );
                  res.data.report.draft = true;
                  this.toDuplicate = false;
                }
                this.$store.commit(
                  'loadExistentReport',
                  res.data.report,
                );
                next();
              })
              .finally(() => {
                this.idItemToWork = -1;

                this.retrievingData = false;
              })
              .catch((err) => {
                console.error(error);

                this.customMsg = err;
                this.color_snackbar = 'red darken-1';
                this.snackbar = true;
                next(false);
              });
          } else next();
        }
        break;
      default:
        next();
    }
  },
  data() {
    return {
      nameFilter: '',
      toDuplicate: false,
      menu: false,
      retrievingData: false,
      snackbar: false,
      color_snackbar: 'primary',
      timeout: 5000,
      customMsg: '',
      sortBy: 'report_name',
      sortDesc: false,
      pagesNum: 0,
      idItemToWork: -1,
      headers: [
        {
          id: 0,
          width: '78',
          text: 'Nome',
          value: 'report_name',
          sortable: true,
          isSearchActive: false,
          valueFilter: '',
          filter(value, search, item) {
            return (
              this.valueFilter == ''
                            || value
                              .toLowerCase()
                              .includes(this.valueFilter.toLowerCase())
            );
          },
        },
        {
          id: 1,
          text: 'Autore',
          value: 'author_name',
          sortable: true,
          isSearchActive: false,
          valueFilter: '',
          filter(value, search) {
            return (
              this.valueFilter == ''
                            || value
                              .toLowerCase()
                              .includes(this.valueFilter.toLowerCase())
            );
          },
        },
        {
          id: 2,
          text: 'Azienda',
          value: 'customer_name',
          sortable: true,
          isSearchActive: false,
          valueFilter: '',
          filter(value, search) {
            return (
              this.valueFilter == ''
                            || value
                              .toLowerCase()
                              .includes(this.valueFilter.toLowerCase())
            );
          },
        },
        {
          id: 3,
          text: 'Stato',
          value: 'complete',
          sortable: true,
          isSearchActive: false,
          valueFilter: '',
          filter(value, search) {
            return (
              this.valueFilter === ''
                            || value === this.valueFilter
            );
          },
          possibleValues: [
            { text: 'Tutti', value: '' },
            { text: 'Completo', value: 1 },
            { text: 'Incompleto', value: 0 },
          ],
        },
        {
          id: 4,
          text: 'Creato il',
          value: 'created_at',
          sortable: true,
          valueFilter: '',
          date: '',
          isSearchActive: false,
          filter(value, search) {
            return this.date === '' || value === this.date;
          },
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      page: 1,
      dataLoaded: false,
      show: [10, 25, 50, 100], // Elementi della dropdown "Mostra"
      itemsPerPage: 10, // Elementi visualizzati nel dt
      reports: [],
      filteredResultSet: [],
    };
  },
  computed: {
    headersFiltered() {
      return this.headers.filter((h) => h.text != '');
    },
    searchFilterSelected() {
      let filterSelected = 0;
      this.headers.forEach((header) => {
        if (header.isSearchActive) filterSelected++;
      });
      return filterSelected;
    },
  },
  created() {
    /*  fetch("allReport.json")
            .then(r => r.json())
            .then(json => {
                this.campaigns = json;
            }); */
  },
  methods: {
    editReport(item) {
      this.idItemToWork = item.id;
      this.$router.push('wizard').catch(() => {});
    },
    duplicateReport(item) {
      this.retrievingData = true;

      Axios.post(
        `${process.env.VUE_APP_API_URL}/report/duplicate`,
        { report_id: item.id },
        {},
      )
        .then((res) => {
          this.idItemToWork = res.data.result.id;
          this.toDuplicate = true;
          this.$router.push('wizard').catch(() => {});
        })
        .catch((err) => {
          this.customMsg = err;
          this.color_snackbar = 'red darken-1';
          this.snackbar = true;
          this.retrievingData = false;
        });
    },
    exportReport(item) {
      this.retrievingData = true;
      Axios.post(`${process.env.VUE_APP_API_URL}/report/get`, {
        report_id: item.id,
      })
        .then((res) => {
          if (
            res.data.report.lastPdfGenerated != undefined
                        && res.data.report.lastPdfGenerated != ''
          ) {
            const urlPdf = res.data.report.lastPdfGenerated;
            if (urlPdf) {
              download_file(urlPdf, 'report.pdf');
            }
          } else this.customMsg = "Non è possibile scaricare il report poichè non è stato mai esportato. Prova a cliccare sull'icona della matita, salvare il report e cliccare 'Esporta'";
          this.color_snackbar = 'red darken-1';
          this.snackbar = true;
          this.retrievingData = false;
        })
        .catch((err) => {
          this.customMsg = err;
          this.color_snackbar = 'red darken-1';
          this.snackbar = true;
        })
        .finally(() => {
          this.retrievingData = false;
        });
      /*    printJS({
                maxWidth: 1490,
                printable:
                    "http://127.0.0.1:8000/temp_pdf/0c2tZmkpb1bJ1BBI_.pdf"
            }); */
    },

    sortMode(value) {
      if (this.sortBy == value) {
        return this.sortDesc ? '-descending' : '-ascending';
      } return '';
    },
    toggleSort(id) {
      this.sortBy = this.headersFiltered[id].value;
      this.sortDesc = !this.sortDesc;
    },
    toggleSearch(id) {
      this.headers[id].isSearchActive = !this.headers[id].isSearchActive;
      this.headers[id].valueFilter = '';
    },
    toggleOrder() {
      this.sortDesc = !this.sortDesc;
    },
    calcPagesNum(items) {
      this.pagesNum = Math.ceil(items / this.itemsPerPage);
    },
    calcWidth(item) {
      return item.text != '' ? '17%' : '15%';
    },
    getFiltered(pagination) {
      // console.log(pagination); //output the filtered items
      this.pagesNum = Math.ceil(
        pagination.itemsLength / pagination.itemsPerPage,
      );
    },
  },
};
</script>

<style>
.filter--hidden {
    opacity: 0;
    pointer-events: none;
}
.change-opacity {
    transition: all linear 0.2s;
}
.visible-true {
    visibility: hidden;
    position: absolute;
    top: -1000px;
}
.bg-color-row {
    background-color: #eaebf6;
}
.verde_eb.v-btn.v-btn--disabled {
    opacity: 0.4;
}
.verde_eb.v-btn.v-btn--disabled .mdi-printer {
    color: white !important;
}
.mdi-download,
.mdi-content-copy {
    color: white !important;
}
</style>
