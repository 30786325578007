<template>
  <v-container class="pa-0 br" fluid>
    <div class="eb_sidebar pt-3 px-3 scrollbar scrollbar-grey">
      <Row
        v-for="slide in slides"
        :key="slide.id_campagna"
        :confirmed="slide.confirmed"
        :checked="slide.checked"
        :id_campagna="slide.id_campagna"
        :type="slide.type"
        :title-row="slide.campaign_name"
      />
      <div class="force-overflow" />
    </div>
    <v-row class="text-center mx-0 py-3 eb_sidebar_footer">
      <v-divider />
      <transition name="fade" mode="out-in">
        <v-col v-if="draft" key="1" class="px-0" cols="10">
          <v-btn
            class="mx-3"
            rounded
            color="primary"
            dark
            @click="showPreviewPDF"
          >
            <v-icon class="mr-2">
              mdi-eye
            </v-icon>Anteprima
          </v-btn>
        </v-col>
        <v-col v-else key="2" class="px-0" cols="10">
          <v-btn
            class="mx-3"
            rounded
            color="primary"
            dark
            @click="showPreviewPDF"
          >
            <v-icon class="mr-2">
              mdi-eye
            </v-icon>Anteprima
          </v-btn>
          <v-btn
            rounded
            class="color-anteprima"
            dark
            @click="exportPDF"
          >
            <v-icon class="mr-2">
              mdi-file-move
            </v-icon>Esporta
          </v-btn>
        </v-col>
      </transition>
      <v-col cols="2" class="px-0">
        <v-btn rounded @click="confirmAll">
          <v-icon>mdi-check-all</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- SNACKBAR -->
    <v-snackbar
      v-model="snackbar"
      :color="color_snackbar"
      :timeout="timeout"
    >
      {{ customMsg }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
    <!--- SNACKBAR --->
  </v-container>
</template>

<script>
import Echo from 'laravel-echo';
import Axios from 'axios';
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js';
import Row from './Row';

export default {
  name: 'WizardSidebar',
  components: { Row },
  data() {
    return {
      stateConfirm: '',
      snackbar: false,
      color_snackbar: 'primary',
      timeout: 5000,
      customMsg: '',
    };
  },
  computed: {
    allSlidesChecked() {
      let slidesNotChecked = 0;
      this.slides.forEach((slide) => {
        if (slide.checked !== undefined) {
          if (slide.checked && !slide.confirmed) {
            slidesNotChecked++;
          }
        } else if (!slide.confirmed) {
          slidesNotChecked++;
        }
      });
      return slidesNotChecked == 0;
    },
    slides() {
      return this.$store.getters.slides;
    },
    draft() {
      return this.$store.getters.report.draft;
    },
  },
  methods: {
    showPreviewPDF() {
      this.$store.commit('toggleBuildingPdf', true);
      const idChannel = Date.now();
      let lastTemplateProcessed;
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_KEY,
        cluster: 'eu',
        forceTLS: process.env.VUE_APP_PUSHER_FORCETLS,
      });
      window.Echo.channel(`updatesPDF.${idChannel}`).listen(
        'UpdateStatus',
        (e) => {
          this.$store.commit('updateBuildingPdfState', e.message);
          lastTemplateProcessed = 'Non ancora iniziato';
          if (
            typeof e.message.processedTemplates !== 'undefined'
                        && e.message.processedTemplates.length > 0
          ) {
            lastTemplateProcessed = e.message.processedTemplates.slice(
              -1,
            )[0];
          }
        },
      );
      const { report } = this.$store.getters;
      report.idChannel = idChannel;
      // eslint-disable-next-line no-unreachable
      Axios.post(
        `${process.env.VUE_APP_API_URL}/pdf/preview`,
        // "/api/v1/campaigns/tt",
        { report },
      )
        .then((res) => {
          this.$store.commit('toggleBuildingPdf', false);
          this.$store.commit('updateBuildingPdfState', {
            completed: 0,
            processedTemplates: [],
          });
          window.open(res.data.path);
        })
        .catch((err) => {
          this.$store.commit('toggleBuildingPdf', false);
          this.customMsg = `Ultima slide processata: ${
            lastTemplateProcessed
          } Errore generato: ${
            err}`;
        })
        .finally(() => {
          window.Echo.disconnect();
        });
    },
    buildDate(d) {
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join('-');
    },
    exportPDF() {
      // eslint-disable-next-line no-unused-vars
      const reportName = this.$sanitize(this.$store.getters.report.name);
      this.$store.commit('toggleBuildingPdf', true);
      const idChannel = Date.now();
      let lastTemplateProcessed;
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_KEY,
        cluster: 'eu',
        forceTLS: process.env.VUE_APP_PUSHER_FORCETLS,
      });
      window.Echo.channel(`updatesPDF.${idChannel}`).listen(
        'UpdateStatus',
        (e) => {
          this.$store.commit('updateBuildingPdfState', e.message);
          lastTemplateProcessed = 'Non ancora iniziato';
          if (
            typeof e.message.processedTemplates !== 'undefined'
                        && e.message.processedTemplates.length > 0
          ) {
            lastTemplateProcessed = e.message.processedTemplates.slice(
              -1,
            )[0];
          }
        },
      );
      const { report } = this.$store.getters;
      report.author_id == '-1'
        ? (report.author_id = this.$store.getters.idUser)
        : report.author_id;
      report.idChannel = idChannel;
      Axios.post(`${process.env.VUE_APP_API_URL}/pdf/getRandomStr`, {
        name: this.$store.getters.report.name,
      })
        .then((res) => {
          Axios.post(
            `${process.env.VUE_APP_API_URL}/pdf/export`,
            // "/api/v1/campaigns/tt",
            {
              report,
              url: res.data.path,
            },
            {
              responseType: 'arraybuffer',
            },
          )
            .then((res) => {
              const url = window.URL.createObjectURL(
                new Blob([res.data], {
                  type: 'application/pdf',
                }),
              );
              const save = document.createElement('a');
              save.href = url;
              save.setAttribute('download', `${reportName}.pdf`);
              save.download = `${reportName}.pdf`;
              document.body.appendChild(save);
              save.click();
              document.body.removeChild(save);
              const tempObj = JSON.parse(res.config.data);

              this.$store.commit(
                'updateLastPdfGenerated',
                tempObj.url,
              );
              const d = new Date();
              const dateFormatted = `${this.buildDate(d)
              } ${
                d.getHours()
              }:${
                d.getMinutes()
              }:${
                d.getSeconds()}`;
              const body = {
                report_id: this.$store.getters.report.id,
                customer_id: this.$store.getters.report
                  .customer_id,
                author_id: this.$store.getters.author_id,
                draft: false,
                complete: this.$store.getters.reportCompleted,
                report_name: this.$store.getters.report.name,
                updated_at: dateFormatted, // 2020-02-02 16:30
                json_structure: this.$store.getters.report,
              };
              Axios.post(
                `${process.env.VUE_APP_API_URL}/report/save`,
                body,
              ).catch((err) => {
                this.customMsg = `Errore nel salvataggio del report: ${err}`;
                this.color_snackbar = 'red darken-1';
                this.snackbar = true;
              });
            })
            .catch((err) => {
              this.customMsg = `Errore nella generazione della preview del report: ${
                err
              }<br />Ultima slide processata: ${
                lastTemplateProcessed}`;
              this.color_snackbar = 'red darken-1';
              this.snackbar = true;
            })
            .finally(() => {
              window.Echo.disconnect();
              this.$store.commit('toggleBuildingPdf', false);
            });
        })
        .catch((err) => {
          this.customMsg = `Errore nell'export del pdf: ${err}`;
          this.color_snackbar = 'red darken-1';
          this.snackbar = true;
          this.$store.commit('toggleBuildingPdf', false);
        });
    },
    confirmAll() {
      this.$store.commit('toggleConfirmAllSlides', this.stateConfirm); // Stato iniziale: '', quando clicco diventa vero, altrimenti falso.
      if (this.stateConfirm === '') this.stateConfirm = false;
      else this.stateConfirm = !this.stateConfirm; // Altrimenti diventa toggle
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.br {
    border-right: 1px solid #1a1a1a33;
}
.eb_sidebar {
    height: calc(100vh - 222px);
    /*position: fixed;*/
    top: 118px;
    bottom: 80px;
    overflow-y: scroll;
    float: none;
    /* width: inherit; */
}
.eb_sidebar_footer {
    /*     position: fixed; */
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    bottom: 0;
    margin: 0;
    border-top: 1px solid #7f807f;
}
.color-anteprima {
    background-color: #1e9c93 !important;
}

.custom_footer_sticky {
    position: fixed;
    bottom: 0;
    left: 0;
}
</style>
