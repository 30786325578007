import VueNotification from '@kugatsu/vuenotification';
import Vue from 'vue';
import Vuebar from 'vuebar';
import VueSanitize from 'vue-sanitize';
import App from './App.vue';
import interceptorsSetup from './helpers/interceptors';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { makeServer } from './dev_server';

Vue.config.productionTip = false;

if ('VUE_APP_USE_MOCK_FRONTEND_SERVER' in process.env
  && process.env.VUE_APP_USE_MOCK_FRONTEND_SERVER === 'true') { // Set in the npm run serve command
  makeServer();
}

// Referenze a tutti i plugin-addon utilizzati nell'app
Vue.use(require('vue-moment'));

Vue.use(Vuebar);
Vue.use(VueSanitize);

interceptorsSetup();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(VueNotification, {
  timer: 20,
});
