<template>
  <div class="scrollable scrollbar-grey">
    <v-container class="pa-0" fluid>
      <div
        v-for="(slide, index) in slides"
        :key="slide.id_campagna"
        class="pa-4"
        :class="{
          'row-even': index % 2 === 0,
          white: index % 2 !== 0,
        }"
      >
        <Card
          v-if="slide.checked == undefined || slide.checked"
          :id_campagna="slide.id_campagna"
          :confirmed="slide.confirmed"
          :type="slide.type"
          :titolo="slide.campaign_name"
          :data="slide.data"
          :video-preview="
            slide.videoPreview != undefined
              ? slide.videoPreview
              : false
          "
        />
      </div>
    </v-container>
    <div class="force-overflow" />
  </div>
</template>

<script>

import Card from './Card.vue';

export default {
  name: 'WizardContentMio',
  components: {
    Card,
  },
  data() {
    return {};
  },
  computed: {
    slides() {
      return this.$store.getters.slides.filter(
        (slide) => slide.checked == undefined || slide.checked,
      );
    },
    /* slideTitolo: function() {
            return this.$store.getters.getSlideTitolo;
        },
        slideCanvas: function() {
            return this.$store.getters.getSlideCanvas;
        },
        slideRiepilogo: function() {
            return this.$store.getters.getSlideRiepilogo;
        } */
  },
};
</script>

<style scoped>
.eb-card-title {
    display: inline-block;
    font-size: 30;
    font-family: oswald;
    text-decoration: none;
    background-image: linear-gradient(to right, #5e4ddc, #5e4ddc);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 50px 4px;
    /* border-radius: 5px; */
    transition: background-size 0.5s ease;
}
.scrollbar {
    background: #fff;
    overflow-y: scroll;
}
.scrollable {
    background: #fff;
    overflow-y: scroll;
    max-height: calc(100vh - 160px);
}

.row-even {
    background-color: #f3f2fa;
}
</style>
