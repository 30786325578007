<template>
  <div>
    <v-data-table
      class="custom_eb_dt"
      :sort-by="['impressions']"
      sort-desc
      :footer-props="{
        disableItemsPerPage: true,
      }"
      :headers="headers"
      :items="arrayWithIndex"
      :item-class="row_class"
    >
      <template #[`item.actions`]="{ item }">
        <v-icon
          v-if="!confirmed"
          color="red"
          small
          class=""
          @click="removeRow(item.index)"
        >
          mdi-close-box-outline
        </v-icon>
      </template>
      <template #[`item.numRow`]="{ item }">
        {{ item.indexOrder + 1 }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Positions',
  props: {
    id_campagna: {
      default: '',
    },
    additionalHeaders: {},
    type: {},
  },

  data() {
    return {
      dialog: false,
      headersFixed: [
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
        {
          text: 'Indice',
          value: 'numRow',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    arrayWithIndex() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.pos
        .sort((a, b) => b.impressions - a.impressions)
        .map((item, indexOrder) => ({ ...item, indexOrder }));
    },
    confirmed: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .confirmed;
      },
    },
    pos: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna).data
          .pos;
      },
    },
    headers() {
      return this.headersFixed.concat(this.additionalHeaders);
    },
  },
  methods: {
    row_class(item) {
      if (item.indexOrder + 1 > 20) {
        return 'disabled';
      }
    },
    removeRow(index) {
      const arrIndex = this.pos.findIndex((x) => x.index == index);
      this.$store.commit('removeRow', {
        id_campagna: this.id_campagna,
        arrIndex,
      });
    },
  },
};
</script>

<style></style>
