<template>
  <v-card class="ma-5">
    <v-card-text>
      <v-icon
        class="float-right"
        :color="confirmed ? 'green lighten-2' : 'grey'"
        @click="confirmSlide()"
      >
        mdi-checkbox-marked-circle-outline
      </v-icon>
      <v-row
        v-if="
          type != 'titolo' && type != 'canvas' && type != 'riepilogo'
        "
        no-gutters
      >
        <v-col cols="10">
          <span class="display-1 eb-card-title text--primary-eb">
            {{ titolo }}
          </span>
        </v-col>
        <v-col cols="2">
          <div v-if="videoPreviewLocal.length > 0">
            <v-row
              v-for="(preview, i) in videoPreviewLocal"
              :key="i"
              no-gutters
              class="d-flex flex-column"
              justify="center"
              align="center"
            >
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    class="alert_custom"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-alert
                  </v-icon>
                </template>
                <span>{{ buildTooltip(i) }}</span>
              </v-tooltip>
              <v-img
                v-if="preview != ''"
                lazy-src="http://flxtable.com/wp-content/plugins/pl-platform/engine/ui/images/image-preview.png"
                max-height="50"
                min-height="30"
                max-width="50"
                :src="preview + '?t=' + date"
                @mouseover="
                  previewToShow = preview;
                  toggleModalPreview = true;
                "
              />
              <v-img
                v-else
                lazy-src="http://flxtable.com/wp-content/plugins/pl-platform/engine/ui/images/image-preview.png"
                max-height="50"
                max-width="50"
                min-height="30"
                src="/wizard/assets/no-image.png"
              />
              <!-- preview già presente, da cambiare -->
              <div
                v-if="preview != ''"
                class="background-camera custom_pos"
                :class="{
                  disabled: confirmed,
                }"
                @click="
                  previewToEdit = preview;
                  toggleModal = true;
                "
              >
                <v-icon
                  style="left:12px; bottom: 1px"
                  class="camera"
                  color="white"
                >
                  mdi-cached
                </v-icon>
              </div>

              <!-- preview non presente, icona dopo primo caricamento -->
              <div
                v-else
                class="background-camera custom_pos "
                :class="{
                  disabled: confirmed,
                }"
                @click="
                  previewToEdit = 'newUpload_'
                    + i
                    + '_'
                    + id_campagna
                    + '_'
                    + new Date().getTime()
                    + '.png';
                  toggleModal = true;
                "
              >
                <v-icon
                  style="left:12px"
                  class="camera"
                  color="white"
                >
                  mdi-file-upload-outline
                </v-icon>
              </div>
            </v-row>
          </div>
          <div v-else>
            <!-- neanche una preview presente -->
            <v-row
              v-for="i in times"
              :key="i"
              no-gutters
              class="d-flex flex-column"
              align="center"
              @mouseleave="toggleModalPreview = false"
            >
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    style=""
                    class="alert_custom"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-alert
                  </v-icon>
                </template>
                <span>{{ buildTooltip(i - 1) }}</span>
              </v-tooltip>
              <v-img
                lazy-src="http://flxtable.com/wp-content/plugins/pl-platform/engine/ui/images/image-preview.png"
                max-height="50"
                min-height="30"
                max-width="50"
                src="/wizard/assets/no-image.png"
              />
              <div
                class="background-camera custom_pos"
                :class="{
                  disabled: confirmed,
                }"
                @click="
                  previewToEdit = 'newUpload_'
                    + (i - 1)
                    + '_'
                    + id_campagna
                    + '_'
                    + new Date().getTime()
                    + '.png';
                  toggleModal = true;
                "
              >
                <v-icon
                  style="left:12px"
                  class="camera"
                  color="white"
                >
                  mdi-file-upload-outline
                </v-icon>
              </div>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <p v-else class="display-1 eb-card-title text--primary-eb">
        {{ titolo }}
      </p>
      <span v-if="type == 'titolo'">
        <CardTitle :id_campagna="id_campagna" />
      </span>
      <span v-else-if="type == 'canvas'">
        <CardCanvas :id_campagna="id_campagna" />
      </span>
      <span v-else-if="type == 'riepilogo'">
        <CardRiepilogo :id_campagna="id_campagna" />
      </span>
      <TitleSlide
        v-for="(slide, index) in slides"
        v-else
        :key="index"
        :index="index"
        :id_campagna="id_campagna"
        :type="type"
      >
        <Positions
          v-if="
            (type == 'preroll'
              || type == 'yt_banner'
              || type == 'web_banner')
              && index == 1
          "
          :id_campagna="id_campagna"
          :additional-headers="additionalHeaders"
        />
      </TitleSlide>
    </v-card-text>
    <v-dialog
      v-model="toggleModalPreview"
      content-class="position-relative"
      width="600"
    >
      <v-row no-gutters justify="center">
        <v-col>
          <v-card>
            <v-card-text>
              <v-img :src="previewToShow + '?t=' + date" />
            </v-card-text>
          </v-card>
          <v-btn
            height="64px"
            elevation="4"
            absolute
            top
            right
            rounded
            @click="toggleModalPreview = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog v-model="toggleModal" width="400">
      <UploadFile
        :label="'Caricamento nuova anteprima'"
        :title="'Caricamento anteprima'"
        :previous-name="getPreviousName(previewToEdit)"
        @toggle-modal="toggleModalEvent"
        @update-time="updateTime"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import CardTitle from './Cards/Title';
import TitleSlide from './TitleSlide';
import Positions from './Cards/Youtube/Positions';
/* import CardDiscovery from "./Cards/Youtube/Discovery";
import CardPrerollBanner from "./Cards/Youtube/PrerollBanner";
import CardGeneric from "./Cards/CardGeneric"; */
import CardCanvas from './Cards/Canvas/Canvas';
import CardRiepilogo from './Cards/Riepilogo';
import UploadFile from '../../UploadFile.vue';
import constants from '../../../constants';

export default {
  name: 'Card',
  components: {
    CardTitle,
    TitleSlide,
    Positions,
    CardCanvas,
    CardRiepilogo,
    UploadFile,
  },
  props: {
    id_campagna: {
      default: '',
    },
    data: { type: Object },
    checked: {
      default: false,
    },
    type: {
      default: '',
    },
    titolo: {
      default: '',
    },
    toCheck: {
      default: false,
    },
    videoPreview: {
      default: false,
    },
  },
  data() {
    return {
      previewToShow: '',
      previewToEdit: '',
      videoPreviewLocal: this.videoPreview,
      toggleModal: false,
      toggleModalPreview: false,
      date: new Date().getTime(),
    };
  },
  computed: {
    times() {
      return this.type.toLowerCase().includes('banner') ? 3 : 1;
    },
    slides: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .slides;
      },
    },
    confirmed: {
      get() {
        return this.$store.getters.getSlideById(this.id_campagna)
          .confirmed;
      },
    },
    additionalHeaders() {
      const headers = [];
      headers.push({
        text: 'Canale',
        value: 'canale',
      });
      if (this.type != 'web_banner') {
        headers.push({
          text: 'Video',
          value: 'video',
        });
      }
      headers.push({
        text: 'Impressioni',
        value: 'impressions',
      });
      if (this.type == 'preroll') {
        headers.push({
          text: 'Visualizzazioni',
          value: 'views',
        });
      } else {
        headers.push({
          text: 'Clic',
          value: 'clicks',
        });
      }
      return headers;
    },
  },
  methods: {
    buildTooltip(i) {
      let tooltip = "Ricorda che le dimensioni dell'immagine non devono eccedere le seguenti: ";
      const size = constants.PREVIEWS_SIZE[this.type][i];

      tooltip += `${size.width} x ${size.height}\n`;

      return tooltip;
    },
    confirmSlide() {
      this.$store.commit('updateConfirmed', {
        id_campagna: this.id_campagna,
        toggleValue: !this.confirmed,
      });
    },
    toggleModalEvent(e) {
      this.toggleModal = e;
    },
    getPreviousName(name) {
      return name.substring(name.lastIndexOf('/') + 1);
    },
    updateTime(data) {
      this.date = new Date().getTime();

      if (this.videoPreviewLocal.length == 0) this.videoPreviewLocal = new Array(this.times).fill('');

      if (data.new_name && data.isNewUpload) {
        this.videoPreviewLocal[data.index] = data.new_name;

        this.$store.commit('updateSlide', {
          id_campagna: this.id_campagna,
          new_name: this.videoPreviewLocal,
        });
      }
    },
  },
};
</script>

<style>
.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
    top: 0px;
}
.position-relative {
    position: relative;
}
.alert_custom {
    left: 25px;
    top: 12px;
    z-index: 1;
}
.disabled {
    pointer-events: none;
    opacity: 0.6;
}
.custom_pos {
    top: -10px;

    left: 25px;
    position: relative;
}
.eb_custom_divider {
    position: relative;
    bottom: 11px;
    border-color: #6e6e6e78 !important;
}
.custom_eb_dt .v-data-footer__select,
.custom_eb_dt .v-data-footer__pagination {
    display: none;
}
.long_text.v-divider--inset:not(.v-divider--vertical) {
    /*     max-width: calc(100% - 352px);
 */
    margin-left: 210px !important;
}
</style>
