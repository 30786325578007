<template>
  <v-row class="mt-0">
    <v-col cols="5" xl="4" class="pt-0 px-0">
      <WizardSidebar />
    </v-col>
    <v-col cols="7" xl="8" class="pt-0 pl-0">
      <WizardContentMio />
    </v-col>
  </v-row>
</template>

<script>
import WizardSidebar from './sidebar/Sidebar';
import WizardContentMio from './content/Content';

export default {
  name: 'WizardContent',
  components: {
    WizardSidebar,
    WizardContentMio,
  },
};
</script>

<style scoped></style>
