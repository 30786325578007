<template>
  <v-container
    fluid
    fill-height
    class="d-flex justify-center align-center text-center"
  >
    <v-card color="transparent" flat>
      <div class="display-3 mt-5 text-align-center">
        Pagina non trovata...
      </div>
      <div class="grey--text lighten-5 mt-3">
        La pagina che stai cercando non è mai esistita nella realtà...
      </div>
      <div class="grey--text lighten-5">
        ..o probabilmente è stata creata in un universo parallelo...
      </div>
      <div class="paragraph-text mt-2">
        <br />Prova a ritornare alla <a :href="$root.$refs.App.getDefaultPage()">home</a>, o magari
        contatta lo staff per supporto.
      </div>
    </v-card>
  </v-container>
</template>

<script>

export default {};
</script>

<style>
.paragraph-text {
    font-size: 18px;
}
</style>
