<template>
  <div class="pa-2">
    <v-dialog
      v-model="retrievingTemplates"
      content-class="custom_dialog"
      persistent
    >
      <div class="text-center">
        <v-progress-circular indeterminate color="white" />
      </div>
    </v-dialog>
    <!-- SNACKBAR TODO: cambiare con commit toggleSnackbar-->
    <v-snackbar
      v-model="snackbar"
      :color="color_snackbar"
      :timeout="timeout"
    >
      {{ customMsg }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
    <!--- SNACKBAR --->
    <v-text-field
      v-model="search"
      class="mx-16"
      prepend-inner-icon="mdi-magnify"
      label="Post Malone..."
      single-line
      outlined
      @input="resetCounters"
    />
    <div v-if="search === ''" class="text-center">
      <img src="@/assets/campagne/Indication.svg" alt="" srcset />
    </div>

    <v-data-table
      v-else
      v-model="selectedRows"
      :headers="headers"
      :items="campaigns"
      :search="search"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      :custom-filter="customFilter"
      item-key="id"
      :sort-by="['start_date']"
      sort-desc
      no-data-text="Non è ancora presente nessuna campagna..."
      no-results-text="La ricerca della campagna non ha portato ad alcun risultato..."
      show-select
      hide-default-footer
      :loading="!dataLoaded"
      loading-text="Sto recuperando tutte le campagne..."
      @page-count="pageCount = $event"
    >
      <template #top>
        <div
          class="d-flex justify-space-between align-center mx-3 eb_custom_filtering"
        >
          <div class="">
            <v-btn-toggle v-model="toggle_exclusive" multiple>
              <v-btn
                v-for="(social, idx) in socials"
                :key="idx"
                class="mx-5"
                :disabled="!dataLoaded"
                outlined
                :ripple="false"
                @click="toggleElement(social.value)"
              >
                {{ social.name }}
                <v-chip
                  small
                  class="ml-5"
                  disabled
                  color="rgba(0, 0, 0, 0.2)"
                >
                  {{
                    search === ''
                      ? social.items
                      : social.filteredItems
                  }}
                </v-chip>
              </v-btn>
            </v-btn-toggle>
          </div>
          <div class="d-inline-flex align-center width_min_con">
            <span class="mr-5">Mostra </span>
            <v-select
              v-model="itemsPerPage"
              class="custom_ses_input"
              height="30"
              :items="show"
              hide-details="auto"
              outlined
              :disabled="!dataLoaded"
              :value="show[0]"
              return-object
              @change="updatePagination"
            />

            <v-dialog
              v-model="showDialog"
              scrollable
              max-width="60%"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  light
                  class="mx-5 text-transformation-none ses_custom_btn"
                  :class="{ disabled: selectedRows.length === 0 }"
                  depressed
                  rounded
                  v-bind="attrs"
                  v-on="on"
                >
                  <img
                    alt=""
                    class="mx-2"
                    src="@/assets/campagne/bullhorn.svg"
                  />
                  Crea report
                </v-btn>
              </template>
              <v-card>
                <v-card-title
                  class="headline ses_custom_btn elevation-2 d-flex justify-space-between eb_custom_header"
                  fixed
                >
                  Riepilogo campagne selezionate
                  <v-btn
                    icon
                    color="white"
                    @click="showDialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text v-bar class="pa-0">
                  <v-data-table
                    class=""
                    :headers="summaryHeaders"
                    :items="selectedRows"
                    :items-per-page="99999"
                    hide-default-header
                    hide-default-footer
                    no-data-text="Non è ancora presente nessuna campagna..."
                  />
                </v-card-text>
                <v-divider />
                <v-card-actions class="eb_custom_footer">
                  <v-spacer />

                  <router-link
                    :class="{
                      disabled: selectedRows.length == 0,
                    }"
                    :to="{
                      name: 'Wizard',
                      params: {
                        selectedRows: templatesData,
                      },
                    }"
                    class="text-decoration-none"
                  >
                    <v-btn
                      light
                      class="px-5 text-transformation-none ses_custom_btn"
                      depressed
                      rounded
                      @click="createNewReport"
                    >
                      Crea
                    </v-btn>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!--  </router-link> -->
          </div>
        </div>
      </template>

      <template #[`item.social`]="{ item }">
        {{ socialName(item.social) }}
      </template>

      <template #footer>
        <div class="text-center">
          <v-pagination
            v-model="page"
            color="primary"
            :length="
              pagesNum === 0 && search === ''
                ? Math.round(campaigns.length / itemsPerPage)
                : pagesNum
            "
            :total-visible="7"
          />
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogNextStep" persistent width="400">
      <v-card>
        <v-card-title> Avviso </v-card-title>
        <v-card-text v-html="customMsg">
          {{ customMsg }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            icon
            text
            color="primary"
            class="previewsAlert"
            @click="dialogNextStep = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Axios from 'axios';
// eslint-disable-next-line import/extensions
import scrollbarUtils from '@/helpers/mixins/scrollbarUtils';

export default {
  mixins: [scrollbarUtils],
  beforeRouteLeave(to, from, next) {
    switch (to.path) {
      case '/wizard': {
        const ytArr = [];
        const igArr = [];
        const ttArr = [];
        if (this.selectedRows.length > 0) {
          this.selectedRows.forEach((campaign) => {
            switch (campaign.social) {
              case 'yt':
                ytArr.push({
                  id: campaign.id,
                  account_id: campaign.account_id,
                  title: campaign.campaign_name,
                });
                break;
              case 'ig':
              case 'fb':
                igArr.push({
                  id: campaign.id,
                  account_id: campaign.account_id,
                  title: campaign.campaign_name,
                });
                break;
              case 'tk':
                ttArr.push({
                  id: campaign.id,
                  account_id: campaign.account_id,
                  title: campaign.campaign_name,
                  start_date: campaign.start_date,
                });
                break;
              default:
                break;
            }
          });
          const REALURL = process.env.VUE_APP_API_URL;
          const googleReportDataCb = Axios.post(`${REALURL}/google/reportsData`, ytArr);
          const metaReportDataCb = Axios.post(`${REALURL}/facebook/reportsData`, igArr);
          const ttReportDataCb = Axios.post(`${REALURL}/tiktok/reportsData`, ttArr);
          this.retrievingTemplates = true;
          Axios.all([metaReportDataCb, googleReportDataCb, ttReportDataCb])
            .then(
              Axios.spread(
                (...responses) => {
                  // Funzioni statistiche

                  console.log(responses[1]);

                  const rispostaYT = responses[1]; // YT
                  const rispostaFB = responses[0]; // FB
                  const rispostaTT = responses[2]; // TT
                  responses.forEach((response) => {
                    if (
                      response.data.campaigns_errors && response.data.campaigns_errors.length > 0
                    ) {
                      let bodyMsg = `Nella chiamata per ottenere i dati delle campagne selezionate ci sono stati dei problemi.<br/>Ecco la risposta da parte di <b>${
                        response.data.social
                      }</b>: <ul>`;

                      response.data.campaigns_errors.forEach(
                        (error) => {
                          bodyMsg += `<li>${error.account_name}: ${error.error_msg}</li><br/><br/>`;
                        },
                      );

                      bodyMsg += '</ul>';
                      this.$notification.error(bodyMsg, {
                        title: `Errori da ${response.data.social} nell'ottenimento delle campagne...`,
                        showCloseIcn: true,
                        infiniteTimer: true,
                        messageIsHTML: true,
                      });
                    }
                  });

                  // Aggiunta di dati eventualmente non esistenti
                  this.addNotExistentData([
                    rispostaYT,
                    rispostaFB,
                    rispostaTT,
                  ]);

                  let allTemplates = rispostaFB.data.templates.concat(
                    rispostaYT.data.templates,
                    rispostaTT.data.templates,
                  );
                  allTemplates = this.sanitizeUnknownTemplates(
                    allTemplates,
                  );
                  // "3ELFRDW6LN3D7KEKG6UGNUJEVXHNXLCQ";
                  const token = process.env.VUE_APP_SCREENSHOT_API_TOKEN;

                  const previewsNotDownloaded = [];
                  let toAlert = false;
                  const arrAxios = []; // Array contenente tutte le chiamate da fare
                  allTemplates.forEach((template) => {
                    let baseUrl = `${process.env.VUE_APP_THIRD_PART_SCREENSHOT_EP}?token=${token}&fresh=true&output=image&delay=3000&url=`;
                    const baseUrlTT = `${process.env.VUE_APP_THIRD_PART_SCREENSHOT_EP}?token=${token}&width=426&height=757&fresh=true&output=image&delay=3000&url=`;
                    const url = [];
                    // Costruisco l'url dinamicamente
                    switch (template.type) {
                      case 'web_banner':
                      case 'yt_banner': {
                        url[0] = ''; // 300x250
                        url[1] = ''; // 468x60
                        url[2] = ''; // 728x90
                        template.data.webBanner.forEach(
                          (banner) => {
                            switch (
                              banner.pixel_width
                            ) {
                              case 300:
                                if (url[0] === '' && banner.pixel_height === 250) url[0] = banner.banner_url;
                                break;
                              case 468:
                                if (url[1] === '' && banner.pixel_height === 60) url[1] = banner.banner_url;
                                break;
                              case 728:
                                if (url[2] === '' && banner.pixel_height === 90) url[2] = banner.banner_url;
                                break;
                              default:
                                break;
                            }
                          },
                        );
                        break;
                      }
                      case 'static_discovery':
                      case 'discovery':
                      case 'preroll':
                        url.push(
                          template.data.videoPreview
                            .url,
                        );
                        break;
                      case 'tt':
                        url.push(baseUrlTT + template.data.preview);
                        break;
                      case 'ig_story':
                      case 'fb+ig_feed':
                        baseUrl += `${encodeURIComponent(
                          template.data.previews[0].body
                            .match(/src\s*=\s*"(.+?)"/)[1]
                            .replace(
                              /&amp;/g,
                              '&',
                            ),
                        )}&width=320&height=594`;
                        url.push(baseUrl);
                        break;
                      case 'fb_feed':
                        baseUrl += `${encodeURIComponent(
                          template.data.previews[0].body
                            .match(
                              /src\s*=\s*"(.+?)"/,
                            )[1]
                            .replace(
                              /&amp;/g,
                              '&',
                            ),
                        )}&width=320&height=575`;
                        url.push(baseUrl);
                        break;
                      case 'ig_feed':
                        baseUrl += `${encodeURIComponent(
                          template.data.previews[0].body
                            .match(
                              /src\s*=\s*"(.+?)"/,
                            )[1]
                            .replace(
                              /&amp;/g,
                              '&',
                            ),
                        )}&width=320&height=488`;
                        url.push(baseUrl);
                        break;
                      default:
                        break;
                    } // Fine switch
                    console.log(url);
                    arrAxios.push(
                      Axios.post(
                        `${process.env.VUE_APP_API_URL}/pdf/downloadPreview`,
                        {
                          url,
                          campaign_name:
                                                        template.campaign_name,
                          campaign_id:
                                                        template.id_campagna,
                          social: template.social,
                        },
                      ),
                    );
                  }); // Fine for each

                  // Ottenimento preview campagne selezionate
                  Axios.all(arrAxios)
                    .then(
                      Axios.spread((...responses) => {
                        for (
                          let index = 0;
                          index < allTemplates.length;
                          index += 1
                        ) {
                          if (
                            responses[index]
                              .status === 206
                          ) {
                            toAlert = true;
                            previewsNotDownloaded.push(
                              responses[index]
                                .data
                                .notDownloaded,
                            );
                          }
                          allTemplates[
                            index
                          ].videoPreview = responses[
                            index
                          ].data.path;
                        }
                        if (toAlert) {
                          this.customMsg = `Le previews delle seguenti campagne non sono state scaricate:<br />${previewsNotDownloaded.join(
                            '<br />',
                          )}<br /><br />L'immagine potrebbe non essere presente, oppure controlla il pannello per la gestione degli screeshot, potresti aver terminato il numero massimo di screenshot al mese.`;
                          this.dialogNextStep = true;
                        }
                        // Ordino le campagne dalla più vecchia alla più recente

                        allTemplates = allTemplates.sort(
                          // eslint-disable-next-line no-nested-ternary
                          (a, b) => (a.start_date
                                                        > b.start_date
                            ? 1
                            : b.start_date
                                                              > a.start_date
                              ? -1
                              : 0),
                        );
                        this.$store.commit(
                          'updateTemplates',
                          allTemplates,
                        );
                        let firstCompany = '';
                        const regex = /{(.*)}/;
                        if (
                          this.selectedRows.length > 0
                        ) {
                          const firstCampaign = this
                            .$store.getters
                            .slides[1]
                            .campaign_name;
                          firstCompany = firstCampaign.toLowerCase();
                          if (
                            regex.test(
                              firstCampaign,
                            )
                          ) {
                            firstCompany = firstCampaign
                              .substring(
                                firstCampaign.lastIndexOf(
                                  '{',
                                ) + 1,
                                firstCampaign.lastIndexOf(
                                  '}',
                                ),
                              )
                              .trim();
                          }
                        }
                        let found = -1;

                        this.$store.getters[
                          'companies/getCompanies'
                        ].forEach((item) => {
                          if (
                            firstCompany
                              .toLowerCase()
                              .includes(
                                item.company.toLowerCase(),
                              )
                                                        && found === -1
                          ) found = item.value;
                        });
                        this.$store.commit(
                          'updateReport',
                          {
                            customer_id: found,
                          },
                        );
                        this.selectedRows = [];
                        this.socialFilter = [];
                        this.toggle_exclusive = [];
                        this.search = '';
                        next();
                      }),
                    )
                    .finally(() => {
                      this.retrievingTemplates = false;
                    })
                    .catch((err) => {
                      console.error(err);
                      this.customMsg = `Si è verificato un problema nell'ottenimento delle preview dalle campagne selezionate: ${err}`;
                      this.color_snackbar = 'red darken-1';
                      this.snackbar = true;
                    });
                },
                (error) => {
                  console.error(error);

                  this.customMsg = `Errore nell'ottenimento dei dati delle campagne selezionate: ${error}`;
                  this.color_snackbar = 'red darken-1';
                  this.snackbar = true;
                },
              ),
            )
            .catch((error) => {
              console.error(error);

              this.retrievingTemplates = false;
              next(false);
              this.customMsg = `Errore nell'effettuare le chiamate per ottenere TUTTI i dati: ${error}`;
              this.color_snackbar = 'red darken-1';
              this.snackbar = true;
            });
        } else {
          next();
        }
        break;
      }
      default:
        next();
        break;
    }
  },
  data() {
    return {
      snackbar: false,
      timeout: '10000',
      color_snackbar: 'primary',
      customMsg: '',
      firstSearchDone: false,
      showDialog: false,
      dialogNextStep: false,
      pagesNum: 0,
      search: '',
      socials: [
        {
          name: 'FB - IG',
          value: 'ig',
          items: 0,
          filteredItems: 0,
        },
        {
          name: 'Youtube',
          value: 'yt',
          items: 0,
          filteredItems: 0,
        },
        {
          name: 'Tik Tok',
          value: 'tk',
          items: 0,
          filteredItems: 0,
        },
      ],
      dataLoaded: false,
      socialFilter: [],
      summaryHeaders: [
        {
          text: 'Nome campagna',
          align: 'start',
          sortable: true,
          value: 'campaign_name',
        },
        { text: 'Account', value: 'account', sortable: true },
      ],
      selectedRows: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 50, // Elementi visualizzati nel dt
      show: [10, 25, 50, 100], // Elementi della dropdown "Mostra"
      headers: [
        {
          text: '',
          value: 'id',
          align: ' d-none',
        },
        {
          text: 'Nome campagna',
          align: 'start',
          sortable: true,
          value: 'campaign_name',
        },
        {
          text: 'Social',
          value: 'social',
          sortable: true,
          filter: (f) => ( // Filtra gli elementi in base alla funzione data su questa colonna
            this.socialFilter.length === 0 // Prendo tutti i valori oppure
            || this.socialFilter.includes(f) // Prendo quelli inclusi nel filtro
          ),
        },
        { text: 'Account', value: 'account', sortable: true },
        { text: 'Creata', value: 'start_date', sortable: true },
      ],
      campaigns: [],
      toggle_exclusive: [],
      templatesData: [],
      retrievingTemplates: false,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (to.path === '/') this.$nextTick(this.routeLoaded);
    },
    dataLoaded(newer, older) {
      if (newer === true && older === false && this.search !== '') {
        this.updateFilteredWithSearch();
        const sumFiltered = this.updateFiltered();

        if (sumFiltered !== 0 && this.search !== '') this.calcPagesNum(sumFiltered);
      }
    },
    search() {
      if (!this.firstSearchDone) {
        this.firstSearchDone = true;
        const inizio = new Date();
        const BASEURL = process.env.VUE_APP_API_URL;
        // if ((process.env.VUE_APP_MAKE_THIRD_API_CALLS &&
        // process.env.VUE_APP_MAKE_THIRD_API_CALLS === 'false')) return;
        const getGoogleCampaignsCb = Axios.get(`${BASEURL}/google/campaigns`, {});
        const getMetaCampaignsCb = Axios.get(`${BASEURL}/facebook/campaigns`, {});
        const getTiktokCampaignsCb = Axios.get(`${BASEURL}/tiktok/campaigns`, {});

        // Ottimizzazione failing chiamate

        Axios.all([getMetaCampaignsCb, getGoogleCampaignsCb, getTiktokCampaignsCb])
          .then(
            Axios.spread(
              (...responses) => {
                // Funzioni statistiche

                if (process.env.NODE_ENV !== 'production') {
                  const fine = new Date();
                  console.log(
                    `Durata chiamate totali: ${fine - inizio}`,
                  );
                }
                const rispostaFB = responses[0]; // FB
                const rispostaYT = responses[1]; // YT
                const rispostaTT = responses[2]; // TT

                const responsesArr = [
                  rispostaFB,
                  rispostaYT,
                  rispostaTT,
                ];
                responsesArr.forEach((res) => {
                  if (res.data.campaigns_errors.length > 0) {
                    let bodyMsg = `Nella chiamata per ottenere le campagne ci sono stati dei problemi.<br/>Ecco la risposta da parte di <b>${
                      res.data.social
                    }</b>: <ul>`;

                    res.data.campaigns_errors.forEach((error) => {
                      bodyMsg += `<li>${error.account_name}: ${error.error_msg}</li><br/>`;
                    });

                    bodyMsg += '</ul>';
                    this.$notification.error(bodyMsg, {
                      title:
                        `Errore dal social ${res.data.social} nell'ottenimento delle campagne.`,
                      showCloseIcn: true,
                      infiniteTimer: true,
                      messageIsHTML: true,
                    });
                  }
                });

                // Assegno il numero di tutti gli elementi delle api
                this.socials[0].items = rispostaFB.data.tot_campaigns;
                this.socials[1].items = rispostaYT.data.tot_campaigns;
                this.socials[2].items = rispostaTT.data.tot_campaigns;

                const mergedArr = rispostaFB.data.campaigns.concat(
                  rispostaYT.data.campaigns,
                  rispostaTT.data.campaigns,
                );
                // Miscelo l'array
                this.campaigns = mergedArr.sort(
                  () => Math.random() - 0.5,
                );

                this.dataLoaded = true; // Imposto il caricamento a completato
                this.pagesNum = Math.round(
                  // Imposto le pagine presenti
                  // al numero delle righe presenti diviso gli elementi per pag
                  this.campaigns.length / this.itemsPerPage,
                );

                // Funzioni statistiche
                if (process.env.NODE_ENV !== 'production') {
                  const endRendering = new Date();
                  console.log(`Durata renderizzazione totale: ${endRendering - inizio}`);
                }
              },
              (error) => { console.error(error); },
            ),
          )
          .catch((error) => {
            let body = '';
            console.log(error);
            if (error.response) {
              // Request made and server responded
              /* console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers); */
              body = error.response.data.message;
            } else if (error.request) {
              // The request was made but no response was received
              // console.log(error.request);
              body = error.request;
            } else {
              // Something happened in setting up the request that triggered an Error
              // console.log("Error", error.message);
              body = error.message;
            }

            if (error.response && error.response.status && error.response.status === 401) {
              // C.R., il cliente vuole eliminare la visualizzazione dell'errore se ha perso la sessione
              // Purtroppo non sono eccellente con la logica, scrivo il codice
              // come mi è più semplice per non far guai..
            } else { // mostro notifica solo se il codice non esiste o non è 401
              this.$notification.error(body, {
                title: 'Errore di sistema',
                showCloseIcn: true,
                infiniteTimer: true,
                messageIsHTML: true,
              });
            }
          });
      }
    },
  },
  mounted() {
    this.routeLoaded();
  },
  created() {
    this.$store.commit('companies/setCompanies');
  },
  methods: {
    sanitizeUnknownTemplates(templates) {
      const templateToPurge = [];
      const campaignsToPurge = [];
      templates.forEach((template) => {
        if (template.type === 'undefined') {
          templateToPurge.push(template.campaign_name);
          campaignsToPurge.push(template.id_campagna);
        }
      });
      campaignsToPurge.forEach((campaignId) => {
        const indexToRemove = templates.findIndex(
          (templateToFind) => templateToFind.id_campagna === campaignId,
        );
        templates.splice(indexToRemove, 1);
      });
      if (templateToPurge.length > 0) {
        this.customMsg = `Non è stato possibile riconoscere la tipologia delle seguenti campagne: \n${templateToPurge.join(
          '\n',
        )}\n\nQueste campagne non sono state considerate ai fini della generazione del report. Controllare che nel nome della campagna sia presente la sua tipologia.`;
        this.dialogNextStep = true;
      }
      return templates;
    },
    updateFiltered() {
      let sumFiltered = 0;

      // Il num degli elementi è uguale agli elementi dei 3 social se non ci
      // sono filtri, altrimenti devo selezionare gli elementi solo del/dei
      // social filtrati
      if (this.socialFilter.length > 0) {
        // prendo i filtrati dei social selezionati
        this.socials.forEach((social) => {
          // Per ogni social
          if (this.socialFilter.includes(social.value)) sumFiltered += social.filteredItems;
        });
      } else {
        // Sommo tutti gli elementi filtrati dalla ricerca
        this.socials.forEach((social) => {
          // Altrimenti il numero di righe sarà uguale a quello di tutte le
          // righe filtrate dei social
          sumFiltered += social.filteredItems;
        });
      }
      return sumFiltered;
    },
    resetCounters() {
      // Funzione scatenata all'inserimento di un testo (NON ANCORA o al click di un filtro)
      // Funzione che aggiorna il numero degli elementi filtrati (solo se è stata fatta una ricerca)

      // Ogni volta che cerco un testo, resetto gli elementi filtrati

      // Ha senso filtrare solo se il testo è riempito oppure se è stato inserito almeno un filtro
      if (this.search === '' && this.socialFilter.length === 0) this.calcPagesNum(this.calcTotalItems);

      if (!this.dataLoaded) {
        this.socials.forEach((social) => {
          // eslint-disable-next-line no-param-reassign
          social.filteredItems = '-';
        });
        return;
      }

      this.updateFilteredWithSearch();

      const sumFiltered = this.updateFiltered();

      if (sumFiltered !== 0 && this.search !== '') this.calcPagesNum(sumFiltered);
      else if (this.search === '') this.calcPagesNum(this.campaigns.length);
      else if (sumFiltered === 0 && this.search !== '') this.calcPagesNum(0);
    },
    customFilter(value, search, item) {
      // Filtro i risultati
      if (this.socialFilter.length > 0) {
      // Se ci sono dei filtri abilitati aggiorno la paginazione,
      // il numero degli elementi sarà uguale a
        return (
          item.campaign_name
            .toLowerCase()
            .includes(search.toLowerCase())
                    && this.socialFilter.includes(item.social.toLowerCase())
        );
      }
      return item.campaign_name
        .toLowerCase()
        .includes(search.toLowerCase());
    },
    toggleElement(el) {
      // Callback che viene richiamato al click di un filtro,
      // inserisce se non presente (altrimenti rimuove) un filtro
      const vm = this;
      const index = vm.socialFilter.indexOf(el);
      if (index > -1) {
        vm.socialFilter.splice(index, 1);
      } else vm.socialFilter.push(el);

      if (vm.socialFilter.length > 0) {
        // Per ogni social abilitato sommo gli elementi filtrati
        let sumFilteredItems = 0;
        this.socials.forEach((social) => {
          // Per ogni social
          if (this.socialFilter.includes(social.value)) {
            if (this.search === '') sumFilteredItems += social.items;
            else sumFilteredItems += social.filteredItems;
          }
        });
        this.calcPagesNum(sumFilteredItems);
      } else {
        // Se nessun filtro è abilitato, gli elementi sono quelli
        let sumFilteredItemsWithoutFilters = 0;
        this.socials.forEach((social) => {
          // Per ogni social
          if (this.search === '') sumFilteredItemsWithoutFilters += social.items;
          else sumFilteredItemsWithoutFilters += social.filteredItems;
        });
        this.calcPagesNum(sumFilteredItemsWithoutFilters);
      }
    },
    socialName(name) {
      // Associa il nome che arriva dall'api al social codificato
      switch (name) {
        case 'yt':
          return 'Youtube';
        case 'tk':
          return 'Tik Tok';
        case 'fb':
        case 'ig':
          return 'Facebook / Instagram';
        case 'gg':
          return 'Google';
        default:
          return name;
      }
    },
    updatePagination() {
      let sumFiltered = 0;
      if (this.search !== '' && this.socialFilter.length > 0) {
        // Gli elementi sono uguali alle righe filtrate tra quelle dei social
        this.socials.forEach((social) => {
          if (this.socialFilter.includes(social.value)) sumFiltered += social.filteredItems;
        });
      } else if (this.socialFilter.length > 0) {
        sumFiltered = this.calcFilteredButtonsItems();
      } else if (this.search !== '') {
        sumFiltered = this.calcFilteredItems();
      }
      this.calcPagesNum(sumFiltered);
    },
    calcPagesNum(items) {
      this.pagesNum = Math.ceil(items / this.itemsPerPage);
    },
    calcTotalItems() {
      let totalItems = 0;
      this.socials.forEach((social) => {
        totalItems += social.items;
      });
      return totalItems;
    },
    calcFilteredItems() {
      let totalFilteredItems = 0;
      this.socials.forEach((social) => {
        totalFilteredItems += social.filteredItems;
      });
      return totalFilteredItems;
    },
    calcFilteredButtonsItems() {
      let totalFilteredNotSearchedItems = 0;
      this.socials.forEach((social) => {
        // Per ogni social
        // eslint-disable-next-line vue/max-len
        if (this.socialFilter.includes(social.value)) totalFilteredNotSearchedItems += social.filteredItems;
      });
      return totalFilteredNotSearchedItems;
    },
    updateFilteredWithSearch() {
      this.socials.forEach((social) => {
        // eslint-disable-next-line no-param-reassign
        social.filteredItems = 0;
      });
      this.campaigns.forEach((campaign) => {
        // Se il nome della campagna include la ricerca

        /*  if (this.socialFilter.length > 0) {
                    // Se ci sono dei filtri abilitati
                     i social dei risultati devono essere anche nei filtri
                } else */
        if (
          campaign.campaign_name
            .toLowerCase()
            .trim()
            .includes(this.search.toLowerCase().trim())
        ) {
          switch (
            campaign.social // Aggiungo l'elemento al numero totale dei filtrati
          ) {
            case 'fb':
            case 'ig':
              this.socials[0].filteredItems += 1;
              break;
            case 'yt':
            case 'gg':
              this.socials[1].filteredItems += 1;
              break;
            case 'tk':
              this.socials[2].filteredItems += 1;
              break;
            default:
              break;
          }
        }
      });
    },
    addNotExistentData(arrResponses) {
      arrResponses.forEach((res) => {
        if (res.data.templates.length > 0) {
          res.data.templates.forEach((template) => {
            // eslint-disable-next-line no-param-reassign
            if (template.confirmed === undefined) template.confirmed = false;
            if (template.slides === undefined) {
              switch (template.type) {
                case 'preroll':
                  // eslint-disable-next-line no-param-reassign
                  template.slides = [
                    { titolo_slide: 'LANCIO CON CAMPAGNA YOUTUBE PREROLL' },
                    { titolo_slide: 'POSIZIONAMENTI' },
                  ];
                  break;
                case 'discovery':
                  // eslint-disable-next-line no-param-reassign
                  template.slides = [
                    { titolo_slide: 'LANCIO CON CAMPAGNA YOUTUBE DISCOVERY' },
                  ];
                  break;
                case 'static_discovery':
                  // eslint-disable-next-line no-param-reassign
                  template.slides = [
                    { titolo_slide: 'DISCOVERY STATICA SU YOUTUBE' },
                  ];
                  break;
                case 'yt_banner':
                  // eslint-disable-next-line no-param-reassign
                  template.slides = [
                    { titolo_slide: 'BANNER SU YOUTUBE' },
                    { titolo_slide: 'POSIZIONAMENTI' },
                  ];
                  break;

                case 'web_banner':
                  // eslint-disable-next-line no-param-reassign
                  template.slides = [
                    { titolo_slide: 'BANNER SU WEB' },
                    { titolo_slide: 'POSIZIONAMENTI' },
                  ];
                  break;

                  // -------------------- FB/IG ----------------

                case 'fb_feed':
                  // eslint-disable-next-line no-param-reassign
                  template.slides = [
                    { titolo_slide: 'LANCIO CON FACEBOOK POST' },
                    { titolo_slide: 'GENERE ED ETÀ' },
                  ];

                  break;
                case 'ig_feed':
                  // eslint-disable-next-line no-param-reassign
                  template.slides = [
                    { titolo_slide: 'LANCIO CON INSTAGRAM POST' },
                    { titolo_slide: 'GENERE ED ETÀ' },
                  ];
                  break;

                case 'fb+ig_feed':
                  // eslint-disable-next-line no-param-reassign
                  template.slides = [
                    { titolo_slide: 'LANCIO CON INSTAGRAM E FACEBOOK POST' },
                    { titolo_slide: 'GENERE ED ETÀ' },
                  ];
                  break;

                case 'ig_story':
                  // eslint-disable-next-line no-param-reassign
                  template.slides = [
                    { titolo_slide: 'LANCIO CON INSTAGRAM STORY' },
                    { titolo_slide: 'GENERE ED ETÀ' },
                  ];
                  break;

                case 'tt':
                  // eslint-disable-next-line no-param-reassign
                  template.slides = [
                    { titolo_slide: 'LANCIO TIKTOK' },
                    { titolo_slide: 'GENERE ED ETÀ' },
                  ];
                  break;
                default:
                  break;
              }
            }
          });
        }
      });
    },
    createNewReport() {
      this.showDialog = false;
      this.$store.commit('resetState');
    },
  },
};
</script>

<style>
.v-pagination__item {
    border: 1px solid rgba(39, 35, 35, 0.2);
    border-radius: unset !important;
    box-shadow: unset !important;
}
.v-pagination__navigation i {
    color: #4934c8 !important;
}

.width_min_con {
    width: min-content;
}

.v-application--is-ltr .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
    border-left-width: 1px !important;
}

.custom_ses_input .v-input__append-inner {
    margin-top: 5px !important;
}

.custom_ses_input .v-input__slot {
    height: 35px !important;
    min-height: unset !important;
}

.text-transformation-none {
    text-transform: none;
}

.eb_custom_filtering .v-btn--active,
.eb_custom_filtering .v-btn--active .v-chip__content,
.ses_custom_btn {
    background-color: var(--v-primary-base) !important;
    color: #fff !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.disabled .v-btn {
    background-color: rgb(0, 0, 0, 0.2) !important;
}

.vb > .vb-dragger {
    z-index: 5;
    width: 12px;
    right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
        height 100ms ease-out;
    transition: background-color 100ms ease-out, margin 100ms ease-out,
        height 100ms ease-out;
    background-color: rgb(47 47 47 / 10%);
    /* margin: 5px 5px 5px 0; */
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgb(47 47 47 / 10%);
}

.previewsAlert {
    position: relative;
    margin-left: 320px;
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
    background-color: rgb(47 47 47 / 10%);
    margin: 0px;
    height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(99, 101, 105, 0.5);
    margin: 0px;
    height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(99, 101, 105, 0.5);
}

.custom_dialog {
    box-shadow: unset !important;
    overflow: unset !important;
}
</style>
