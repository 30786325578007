<template>
  <div>
    <v-expansion-panel-header>
      <b> {{ title }} </b>
    </v-expansion-panel-header>
    <v-dialog v-model="deleteCompany" width="500">
      <v-card>
        <v-card-title> Rimuovi {{ title_beautified }} </v-card-title>
        <v-card-text v-if="title == 'Dati Riepilogo'">
          Sei sicuro di voler eliminare questo dato di riepilogo?
        </v-card-text>
        <v-card-text v-else-if="title == 'Store Digitali'">
          Sei sicuro di voler eliminare questo store digitale?
        </v-card-text>
        <v-card-text
          v-else-if="title == 'Aziende (relative ai clienti)'"
        >
          Sei sicuro di voler eliminare questa azienda? Con essa
          verrano rimossi anche i relativi clienti e report.
        </v-card-text>
        <v-card-text v-else>
          Sei sicuro di voler eliminare questo elemento?
        </v-card-text>
        <div class="d-flex justify-end mr-5">
          <v-btn
            color="primary"
            icon
            @click="removeCurrentField(temporanyInput)"
          >
            Si
          </v-btn>
          <v-btn
            icon
            color="primary"
            @click="deleteCompany = false"
          >
            No
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :color="color_snackbar"
      :timeout="timeout"
    >
      {{ customMsg }}
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          outlined
          v-bind="attrs"
          @click="snackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
    <v-expansion-panel-content>
      <v-text-field
        v-for="input in data"
        :key="input.id"
        v-model="input.name"
        clearable
        :append-icon="'mdi-minus-circle'"
        :append-outer-icon="'mdi-update'"
        @click:append="
          deleteCompany = true;
          temporanyInput = input.id;
        "
        @click:append-outer="updateCurrentField(input.id, input.name)"
      />

      <!-- Inserter -->
      <v-text-field
        v-model="valueToInsert"
        class="mt-5"
        :label="'Inserisci ' + elementName"
        clearable
        :append-icon="'mdi-plus-circle'"
        @click:append="addField()"
      />
    </v-expansion-panel-content>
  </div>
</template>

<script>
import axios from 'axios';
import constants from '../../constants';

function compare(a, b) {
  const cond = a.name.charAt(0).toUpperCase() < b.name.charAt(0).toUpperCase();
  const cond_eq = a.name.charAt(0).toUpperCase() == b.name.charAt(0).toUpperCase();
  return cond ? -1 : cond_eq ? 0 : 1;
}
export default {
  props: {
    title: {},
    elementName: {},
    typeTab: {},
  },
  data() {
    return {
      timeout: 3500,
      snackbar: false,
      color_snackbar: 'primary',
      customMsg: '',
      data: [],
      valueToInsert: '',
      prefix: '',
      deleteCompany: false,
      confirmDeleteCompany: false,
      temporanyInput: '',
    };
  },
  computed: {
    title_beautified() {
      switch (this.title) {
        case 'Dati Riepilogo':
          return 'dato di riepilogo';
        case 'Store Digitali':
          return 'store digitale';
        case 'Aziende (relative ai clienti)':
          return 'azienda';
        default:
          return 'elemento';
      }
    },
  },
  created() {
    this.prefix = this.typeTab == 'digital_store' || this.typeTab == 'resume_data'
      ? 'storeandriep'
      : 'company';
    const body = { type: this.typeTab };
    axios
      .post(
        `${process.env.VUE_APP_API_URL}/${this.prefix}/get`,
        body,
      )
      .then((res) => {
        if (String(res.status).match(/^2/)) {
          this.data = this.prefix == 'storeandriep'
            ? res.data.result
            : res.data.company;
        }

        this.data.sort(compare);
      })
      .catch((e) => {
        console.error(e);

        this.customMsg = `Errore nel caricamento dei dati di tipo ${
          this.typeTab
        }. Gentilmente, contattare l'amministratore di sistema.`;
        this.color_snackbar = 'red darken-1';
        this.snackbar = true;
      });
  },
  methods: {
    addField() {
      const body = { type: this.typeTab, name: this.valueToInsert };

      if (this.valueToInsert != '') {
        axios
          .post(
            `${process.env.VUE_APP_API_URL
            }/${
              this.prefix
            }/insert`,
            body,
          )
          .then((res) => {
            if (String(res.status).match(/^2/)) {
              this.data.push({
                id: res.data.result,
                name: this.valueToInsert,
              });
              this.valueToInsert = '';

              this.color_snackbar = constants.SUCCESS_MESSAGE_SNACKBAR;
              this.customMsg = `L'elemento '${
                this.title_beautified
              }' è stato aggiunto correttamente nel sistema`;
              this.$store.commit('companies/setCompanies');
            }
          })
          .catch((e) => {
            console.error(e);

            this.color_snackbar = constants.ERROR_SNACKBAR;
            this.customMsg = "Non è stato possibile aggiungere correttamente l'elemento nel sistema. Controlla che l'elemento non sia già presente, oppure contattare l'amministratore di sistema.";
          })
          .finally(() => {
            this.snackbar = true;
          });
      }
    },
    removeCurrentField(id) {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/${this.prefix}/delete`,
          { id },
        )
        .then((res) => {
          if (String(res.data.code).match(/^2/)) {
            const index = this.data.findIndex((x) => x.id == id);
            this.data.splice(index, 1);
            this.deleteCompany = false;
            this.color_snackbar = 'primary';
            this.customMsg = `L'elemento '${
              this.title_beautified
            }' è stato rimosso correttamente dal sistema`;
            this.$store.commit('companies/setCompanies');
          } else {
            this.color_snackbar = 'red darken-1';
            this.customMsg = "Non è stato possibile eliminare correttamente l'elemento dal sistema. Gentilmente, contattare l'amministratore di sistema.";
          }
        })
        .catch(() => {
          this.color_snackbar = 'red darken-1';
          this.customMsg = "Non è stato possibile eliminare correttamente l'elemento dal sistema. Gentilmente, contattare l'amministratore di sistema.";
        })
        .finally(() => {
          this.snackbar = true;
        });
    },
    updateCurrentField(id, name) {
      if (name !== '') {
        axios
          .post(
            `${process.env.VUE_APP_API_URL
            }/${
              this.prefix
            }/update`,
            { id, name },
          )
          .then((res) => {
            if (String(res.status).match(/^2/)) {
              this.color_snackbar = 'primary';
              this.customMsg = `L'elemento '${
                this.title_beautified
              }' è stato aggiornato correttamente dal sistema`;
              this.$store.commit('companies/setCompanies');
            }
          })
          .catch(() => {
            this.color_snackbar = 'red darken-1';
            this.customMsg = "Non è stato possibile aggiornare correttamente l'elemento nel sistema. Gentilmente, contattare l'amministratore di sistema.";
          })
          .finally(() => {
            this.snackbar = true;
          });
      }
    },
  },
};
</script>

<style></style>
