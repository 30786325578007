export default {
  data() {
    return {
      mergedArr: [],
      errors: [],
    };
  },
  methods: {
    routeLoaded() {
      // Elimina scrollbar nativa e aggiunge quella custom
      if (document.getElementsByTagName('main').length > 0) {
        document
          .getElementsByTagName('main')[0]
          .classList.add('scrollable', 'scrollbar-grey');
      }
    },
  },
};
