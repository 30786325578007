<template>
  <div class="pa-2">
    <v-dialog v-model="columnsSelectionModalVisibility" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Seleziona le colonne
        </v-card-title>
        <v-card-text>
          <div
            v-for="(header, i) in userSelection"
            :key="i"
            class="d-flex align-center"
            style="max-height: 50px"
          >
            <v-checkbox
              v-model="header.checked"
              height="50px"
              :label="header.text"
            />
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color=""
            text
            @click="columnsSelectionModalVisibility = false"
          >
            Annulla
          </v-btn><v-btn
            color="primary"
            text
            @click="updateFilteredColumns()"
          >
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      class="d-flex align-center justify-center content-center align-content-center"
    >
      <v-text-field
        v-model="search"
        class="mx-16 my-8 white"
        prepend-inner-icon="mdi-magnify"
        label="Post Malone..."
        single-line
        outlined
        height="48px"
        hide-details
        elevation
      />

    </div>
    <div v-if="debouncedSearch === ''" class="text-center">
      <img src="@/assets/campagne/Indication.svg" alt="search" srcset />
    </div>

    <div
      v-if="debouncedSearch !== '' && !dataLoaded"
      style="height: 75vh;"
      class="d-flex align-center justify-center"
    >
      <v-progress-circular
        :size="75"
        :width="3"
        color="primary"
        indeterminate
      />
    </div>
    <div v-if="dataLoaded && debouncedSearch !== ''" class="mx-6">
      <div class="d-flex my-6">
        <div id="socials_filter" class="pa-1">
          <v-btn
            v-for="(social, index) in socials"
            :key="index"
            :class="[
              'mx-2',
              social.class,
              socialFilter.includes(social.value)
                ? social.value + '-active'
                : '',
            ]"
            height="48"
            @click="toggleElement(social.value)"
          >
            <v-icon left :class="social.iconClass">
              {{ social.icon }}
            </v-icon>
            <v-btn
              :class="[
                'ml-3',
                'counter-btn-color',
                social.btnCounter,
              ]"
              height="1.5rem"
              min-width="0.5rem"
              color="#F2F7FD!important"
              depressed
            >
              {{ social.filteredItems }}
            </v-btn>
          </v-btn>
        </div>
        <div id="columns_filter" class="ml-auto pa-1">
          <v-menu :close-on-content-click="false" ref="datesMenu"
                  v-model="datesMenu">
            <template v-slot:activator="{ on }">
              <v-btn
                  class="ml-3"
                  color="primary"
                  height="48"
                  v-on="on"
              >
                {{ dateRangeText }}
                <v-icon class="pl-2 customgreen-color">
                  mdi-calendar-range
                </v-icon>
              </v-btn>
            </template>
            <v-date-picker
                v-model="dates"
                range
                :min="minDate"
                :max="maxDate"
            >
              <v-btn
                  text
                  color="primary"
                  @click="dates = []"
              >
                Reset
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.datesMenu.save(dates)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn
              class="ml-3"
              color="primary"
              height="48"
              @click="exportToExcel"
              :disabled="campaigns.filter(c => c.selected).length == 0 || campaigns.filter(c => c.selected && c.isCampaignDataLoading).length > 0"
          >
            Download
            <v-icon class="pl-2 customgreen-color">
              mdi-file-excel
            </v-icon>
          </v-btn>
          <v-btn
              class="ml-3"
              color="primary"
              height="48"
              @click="columnsSelectionModalVisibility = true"
          >
            Colonne
            <v-icon class="pl-2 customgreen-color">
              mdi-view-column-outline
            </v-icon>
          </v-btn>
        </div>
      </div>
      <v-data-table
        v-model="selectedRows"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :items-per-page="itemsPerPage"
        :search="debouncedSearch"
        :headers="tableHeaders"
        :sort-by="['start_date']"
        sort-desc
        :items="campaigns"
        height="60vh"
        show-select
        show-expand
        hide-default-header
        hide-default-footer
        item-key="id"
        class="elevation-24 table-layout-fixed rounded dashboard-table"
      >
        <template
          v-for="column in headersWithoutFixed"
          #[`item.${column.value}`]="{ item, value }"
        >
          <v-progress-circular
            v-if="item.selected && item.isCampaignDataLoading"
            :size="25"
            color="primary"
            :width="2"
            indeterminate
          />
          <span v-else-if="column.visible">
            {{ item.selected ? value : '' }}
          </span>
        </template>

        <template
          #item.data-table-expand="{
            item, expand, isExpanded, isSelected,
          }"
        >
          <td>
            <button
              :disabled="!isCampaignExpandable(isSelected)"
              type="button"
              :class="
                'v-icon notranslate v-data-table__expand-icon v-icon--link mdi mdi-chevron-'
                  + (isExpanded ? 'down' : 'right')
                  + ' theme--light '
                  + (!isCampaignExpandable(isSelected)
                    ? 'v-btn--disabled v-chip--disabled'
                    : '')
              "
              @click="
                setCampaignExpanded(isExpanded, expand, item)
              "
            />
          </td>
        </template>

        <template #expanded-item="{ headers, item }">
          <!-- First Col -->
          <!--
        <v-progress-circular
            v-if="item.selected && item.isAdGroupsLoading"
            :size="25"
            color="primary"
            :width="2"
            indeterminate
          />
          -->

          <td v-if="!item.isAdGroupsLoading" id="first-col">
            <!--          <p />
            <p />-->
            <!--
                <button
                  type="button"
                  class="v-icon notranslate v-data-table__expand-icon
                   v-icon--link mdi mdi-chevron-down theme--light"
                  @click="expandAdGroupLevel = !expandAdGroupLevel"
                />-->
          </td>

          <!-- Second col, under chbx -->
          <td
            v-if="!item.isAdGroupsLoading"
            id="second-col"
            class="ma-0"
            style="height: 48px;"
          >
            <!-- Col 2, row 2 -->
            <!-- <div v-if="item.adGroups" class="pa-0 ma-0 py-3 d-flex align-center" /> -->
          </td>

          <!-- Third col, under Nome campagna -->

          <td id="third-col" style="height: 48px;" class="py-4">
            <tr
              v-for="adGroup in item.adGroups"
              v-if="!item.isAdGroupsLoading"
              :key="adGroup.id"
              class="custom-size"
            >
              <button
                type="button"
                :class="
                  'v-icon notranslate v-data-table__expand-icon v-icon--link mdi mdi-chevron-'
                    + (isAdGroupExpanded(item, adGroup.id)
                      ? 'down'
                      : 'right')
                    + ' theme--light'
                "
                @click="
                  toggleExpandGroupLevel(item, adGroup.id)
                "
              />
              {{
                adGroup.name
              }}

              <ul
                v-if="
                  adGroup.ads
                    && isAdGroupExpanded(item, adGroup.id)
                "
              >
                <v-progress-circular
                  v-if="adGroup.isAdsLoading"
                  :size="25"
                  color="primary"
                  :width="2"
                  indeterminate
                />
                <li
                  v-for="(ad, id) in adGroup.ads"
                  v-else
                  :key="id"
                  class="li-custom-size"
                >
                  {{ ad.name }}
                </li>
              </ul>
            </tr>
          </td>

          <td
            v-for="(col, key) in tableHeaders.filter(
              (i) => i.text !== 'Nome Campagna',
            )"
            v-if="col.visible"
            :key="key"
            class="my-1"
          >
            <v-progress-circular
              v-if="item.isAdGroupsLoading"
              :size="25"
              color="primary"
              :width="2"
              indeterminate
            />
            <tr
              v-for="(adGroup, keyAdGrp) in item.adGroups"
              v-else
              :key="keyAdGrp"
            >
              <div
                class="d-flex flex-column justify-center custom-size"
              >
                {{ adGroup[col.value] }}
              </div>
              <div class="d-flex justify-center align-center">
                <v-progress-circular
                  v-if="
                    isAdGroupExpanded(item, adGroup.id)
                      && adGroup.isAdsLoading
                  "
                  :size="25"
                  color="primary"
                  :width="2"
                  indeterminate
                />
              </div>
              <ul
                v-for="(ad, key) in item.adGroups[keyAdGrp].ads"
                v-if="isAdGroupExpanded(item, adGroup.id)"
                :key="key"
              >
                <li v-if="!adGroup.isAdsLoading" id="ad_item">
                  {{ ad[col.value] }}
                </li>
              </ul>
            </tr>
          </td>
        </template>

        <template
          #item.data-table-select="{ item, isSelected, select }"
        >
          <td>
            <v-simple-checkbox
              :value="isSelected"
              @input="
                toggleCheckboxGetCampaignsInsights(
                  $event,
                  isSelected,
                  item,
                  select,
                )
              "
            />
          </td>
        </template>

        <template #header="{}">
          <thead class="fixed">
            <tr>
              <th
                class="fixed"
                style="min-width: 50px; width: 50px;"
              >
                <span />
              </th>

              <th
                class="fixed"
                style="min-width: 50px; width: 50px; "
              >
                <span />
              </th>

              <th
                v-for="h in tableHeaders"
                v-if="h.visible"
                :key="h.id"
                :width="calcWidth(h)"
                :style="'min-width:' + calcWidth(h)"
                class="fixed"
              >
                {{ h.text }}
              </th>
            </tr>
          </thead>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import apiCalls from '@/helpers/mixins/apiCalls';
import scrollbarUtils from '@/helpers/mixins/scrollbarUtils';
import constants from '@/constants';
import user from '@/helpers/mixins/user';
import moment from 'moment';
import * as XLSX from "xlsx-js-style";

function flattenObject(obj) {
  const result = {};

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const flatObj = flattenObject(obj[key]);
      for (const nestedKey in flatObj) {
        result[`${key}.${nestedKey}`] = flatObj[nestedKey];
      }
    } else {
      result[key] = obj[key];
    }
  }

  return result;
}

export default {
  mixins: [apiCalls, user, scrollbarUtils],
  data() {
    return {
        searchTimeout: null,
      datesMenu: false,
      datesChangedRecently: false,
      tableHeaders: [],
      columnsAlwaysVisible: [],
      allHeaders: [],
      headersWithoutFixed: [],
      toggleStatus: false,
      expandAdGroupLevel: false,
      campaigns: [],
      snackbar: false,
      minAmountTime: 2000,
      maxAmountTime: 3000,
      timeout: '10000',
      color_snackbar: 'primary',
      customMsg: '',
      currentUserRole: '',
      showDialog: false,
      dialogNextStep: false,
      pagesNum: 0,
      search: '',
      debouncedSearch: '',
      dates: [],
      minDate: '',
      maxDate: '',
      dateRangeText: 'No date filter',
      socials: [
        {
          name: 'Facebook',
          value: 'fb',
          items: 0,
          filteredItems: 0,
          icon: 'fab fa-facebook-f',
          color: '#0671fe',
          class: 'fb-btn',
          iconClass: 'fb-btn-icon',
          btnCounter: 'fb-btn-counter',
        },
        {
          name: 'Instagram',
          value: 'ig',
          items: 0,
          filteredItems: 0,
          icon: 'fab fa-instagram',
          color: '#d066a2',
          class: 'ig-btn',
          iconClass: 'ig-btn-icon',
          btnCounter: 'ig-btn-counter',
        },
        {
          name: 'Google',
          value: 'yt',
          items: 0,
          filteredItems: 0,
          color: '#FF0000',
          icon: 'fab fa-google',
          class: 'yt-btn',
          iconClass: 'yt-btn-icon',
          btnCounter: 'yt-btn-counter',
        },
        {
          name: 'Tik Tok',
          value: 'tk',
          items: 0,
          color: '#69c9d0',
          filteredItems: 0,
          icon: 'fab fa-tiktok',
          class: 'tk-btn',
          iconClass: 'tk-btn-icon',
          btnCounter: 'tk-btn-counter',
        },
      ],
      firstSearchDone: false,
      columnsSelectionModalVisibility: false,
      dataLoaded: false,
      socialFilter: [],
      selectedRows: [],
      expanded: [],
      customExpanded: [], // Array di tutti gli item (rows) espansi
      singleExpand: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 500, // Elementi visualizzati nel dt
      show: [10, 25, 50, 100], // Elementi della dropdown "Mostra"
      toggle_exclusive: [],
      templatesData: [],
      manager_basic: '',
      userSelection: [],
    };
  },
  computed: {
    filtered_columns() {
      return this.userSelection
        .filter((val) => val.checked)
        .map((val) => val.value);
    },
  },
  watch: {
    dataLoaded(newer, older) {
      if (newer === true && older === false && this.debouncedSearch !== '') {
        this.recalcCountersCampaigns();
      }
    },
    datesMenu(newVal) {
      if (!newVal) {
        this.datesMenuClosed();
      }
    },
    dates: {
      deep: true, // This ensures the watcher looks for deep changes inside the array
      handler(newVal, oldVal) {
        this.datesChanged(newVal, oldVal);
      }
    },
      async search(newValue) {
          // Clear any existing timeout to reset the delay
          if (this.searchTimeout) {
              clearTimeout(this.searchTimeout);
          }

          // Set a new timeout to call searchFunction after 1 second
          this.searchTimeout = setTimeout(async () => {
              this.debouncedSearch = newValue;
              await this.searchFunction();
          }, 1000); // Delay in milliseconds
      },
  },
  mounted() {
    this.routeLoaded();
    this.columnsAlwaysVisible = this.tableHeaders.filter(
      (header, index) => index <= 3,
    );
    this.headersWithoutFixed = this.tableHeaders.filter((h, i) => i > 3);
    this.currentUserRole = this.$store.getters.currentUser.sub_role;
    this.manager_basic = constants.ROLES.MANAGER_BASIC;

    this.userSelection = this.headersWithoutFixed.map((el) => ({
      text: el.text,
      value: el.value,
      checked: el.visible,
    }));
    // this.userSelection = this.$store.getters.currentUser.user_meta.filtered_columns;
    this.searchFunction();
  },
  created() {
    this.$store.commit('companies/setCompanies');

    const today = new Date();
    const threeYearsAgo = new Date(new Date().setFullYear(today.getFullYear() - 3));

    this.minDate = threeYearsAgo.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    this.maxDate = today.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'

    // Array of objects with key: value, in which key is
    // the param that comes from API, and value is its label
    this.allHeaders = [
      { name: 'Nome Campagna' },
      { social_name: 'Platform' },
      { format: 'Format' },
      { start_date: 'Data creazione' },
      { status: 'Stato' },
      { amount_spent: 'Amount Spent' },
      { impressions: 'Impressions' },
      { reach: 'Reach' }, // solo FB e TT
      { clicks: 'Clicks' },
      { cpc: 'CPC' },
      { reactions: 'Reactions' }, // solo FB e TT
      { comments: 'Comments' }, // solo FB e TT
      { shares: 'Shares' }, // solo FB e TT
      { views: 'Views' }, // (solo Youtube e Tiktok)
      { cpv: 'CPV' }, // (solo Youtube e Tiktok)
      { frequency: 'Frequency' }, // solo FB e TT
      { cpm: 'CPM' },
      { ctr: 'CTR' },
      { thruplay: 'Thruplays' }, // Only GG
      { cost_per_thruplay: 'Cost per Thruplay' }, // Only GG
      { video_watched_3s: '3-second video plays' }, // Only GG
      { cpv_3s_watched: 'Cost-per-3-second video plays' }, // Only GG
      { video_watched_6s: '6-second video plays' }, // Only TT
      { cpv_6s_watched: 'Cost-per-6-second video plays' }, // Only TT
      { video_views_p25: 'Views 25%' },
      { video_views_p50: 'Views 50%' },
      { video_views_p75: 'Views 75%' },
      { video_views_p100: 'Views 100%' },
      { clicks_on_music_disc: 'Music clicks' }, // Only TT
      { follows: 'Paying followers' }, // Only TT
      { starts: 'Starts' }, // solo FB e TT
      { ends: 'Ends' }, // Only GG
    ];

    const colCnt = this.allHeaders.length;
    const isUserMetaFilteredColumnsFilled = 'user_meta' in this.$store.getters.currentUser
            && 'filtered_columns' in this.$store.getters.currentUser.user_meta;
    for (let i = 0; i < colCnt; i += 1) {
      // eslint-disable-next-line vue/max-len

      const isUserMetaFilteredColumnsSet = (param) => isUserMetaFilteredColumnsFilled
                && this.$store.getters.currentUser.user_meta.filtered_columns.includes(
                  param,
                );
      this.tableHeaders.push({
        text: Object.values(this.allHeaders[i])[0],
        align:
                    // La colonna deve essere visibile se i è maggiore di due e
                    // se è presente all'interno dell'array oppure se l'array è vuoto
                    i <= 3
                    || !isUserMetaFilteredColumnsFilled
                    || isUserMetaFilteredColumnsSet(
                      Object.keys(this.allHeaders[i])[0],
                    )
                      ? 'start'
                      : ' d-none',
        position: i,
        sortable: false,
        // Filtra gli elementi in base alla funzione data su questa colonna
        // Prendo tutti i valori oppure Prendo quelli inclusi nel filtro
        filter:
                    Object.keys(this.allHeaders[i])[0] === 'social_name'
                      ? (f) => this.socialFilter.length === 0
                              || this.socialFilter.includes(
                                this.getSocialRawFromName(f),
                              )
                      : null,
        visible:
                    i <= 3
                    || !isUserMetaFilteredColumnsFilled
                    || isUserMetaFilteredColumnsSet(
                      Object.keys(this.allHeaders[i])[0],
                    ),
        value: Object.keys(this.allHeaders[i])[0],
        width: this.calcWidth(i),
        class: i < 3 ? 'fixed' : '',
        cellClass: `overflow-hidden text-truncate ${
          i < 3 ? 'fixed' : ''
        }`,
      });
    }
  },
  methods: {
    datesMenuClosed() {
      if (this.datesChangedRecently) {
        this.datesChangedRecently = false; // Reset flag
        this.campaigns.filter(c => c.selected).forEach(item => this.updateCampaignValues(item));
      }
    },
    updateCampaignValues(item) {
      item.isCampaignDataLoading = true;
      const social = this.detectSocial(item.platformRaw);
      item.since = this.dates?.[0] ?? null;
      item.until = this.dates?.[1] ?? null;
      if (
          ('VUE_APP_MAKE_THIRD_API_CALLS' in process.env
              && process.env.VUE_APP_MAKE_THIRD_API_CALLS === 'true') // if defined and true
          || !('VUE_APP_MAKE_THIRD_API_CALLS' in process.env)
      ) {
        this.getCampaignInsights(item.id, {
          social,
          account_id: item.account_id,
          since: item.since,
          until: item.until,
        })
            .then((response) => {
              // eslint-disable-next-line no-prototype-builtins
              if (
                  response
                  && Object.hasOwn(response, 'data')
                  && response.data.length > 0
              ) {
                if (typeof response.data[0] === 'object') {
                  this.allHeaders
                      .filter((header, index) => index > 3)
                      .forEach((headerObj) => {
                        // eslint-disable-next-line no-param-reassign,vue/max-len
                        if (Object.keys(headerObj)[0] in response.data[0].data) {
                          item[Object.keys(headerObj)[0]] = response.data[0].data[Object.keys(headerObj)[0]]
                        }
                        item[Object.keys(headerObj)[0]] = item[Object.keys(headerObj)[0]] ?? '-';
                      });
                }
              }

              // eslint-disable-next-line no-param-reassign
              item.isCampaignDataLoading = false;
              this.$forceUpdate();
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit('toggleSnackbar', {
                message: `Errore nel caricamento dei dati relativi alla campagna selezionata. Errore tecnico: ${e}`,
                color: constants.ERROR_SNACKBAR,
              });
                item.isCampaignDataLoading = false;
                this.deselectItem(item);
            });
      } else if (
          'VUE_APP_USE_MOCK_FRONTEND_SERVER' in process.env
          && process.env.VUE_APP_USE_MOCK_FRONTEND_SERVER === 'true'
      ) {
        setTimeout(() => {
          // eslint-disable-next-line no-param-reassign
          item.isCampaignDataLoading = false;
          this.$forceUpdate();
        }, this.randomIntFromInterval(this.minAmountTime, this.maxAmountTime));
      }
    },
    datesChanged(newVal, oldVal) {
      if (newVal && newVal[0]) {
        this.dateRangeText = moment(newVal[0]).format('DD/MM/YY') + ' - ';
        this.dateRangeText += newVal[1] ? moment(newVal[1]).format('DD/MM/YY') : '∞'
      } else {
        this.dateRangeText = 'No date filter';
      }
      this.datesChangedRecently = true;
    },
    titleCase(str) {
      return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    renameProperties(arr) {
      return arr.map(obj => {
        const newObj = {};
        Object.keys(obj).forEach(key => {
          const newKey = this.titleCase(key.replace(/_/g, ' '));
          newObj[newKey] = obj[key];
        });
        return newObj;
      });
    },

      excelHeaders(arr) {
          return Object.keys(arr[0]).map(key => {
              return this.titleCase(key.replace(/_/g, ' '));
          });
      },

      excelData() {
          const campaigns = JSON.parse(JSON.stringify(this.campaigns)).filter(c => c.selected).map(obj => {
              delete obj.isCampaignDataLoading;
              delete obj.isAdGroupsLoading;
              delete obj.id;
              delete obj.selected;
              delete obj.platformRaw;
              delete obj.account_id;
              delete obj.campaign_name;
              obj.platform = obj.social_name;
              delete obj.social_name;
              return obj;
          });
          let data = campaigns.map(obj => {
              const newObj = [];
              Object.keys(obj).forEach(key => {
                  newObj.push(obj[key] ?? '');
              });
              return newObj;
          });
          return [this.excelHeaders(campaigns), ...data];
      },

      exportToExcel() {
          const data = this.excelData();
          const fr = Array.from({length: this.excelHeaders(data).length}, (_, i) => i === 0 ? 'EXPLODING BANDS' : '');
          const sr = Array.from({length: this.excelHeaders(data).length}, (_, i) => i === 0 ? 'Report Campagne' : '');
          const tr = Array.from({length: this.excelHeaders(data).length}, (_, i) => '');
          var q = [fr, sr, tr, ...data];
          const ws = XLSX.utils.aoa_to_sheet(q);

          const calculateMaxWidths = (data) => {
              let maxWidths = [];
              data.forEach(row => {
                  row.forEach((cell, index) => {
                      // Estimate the width of the cell's content
                      const contentWidth = cell.toString().length;
                      maxWidths[index] = Math.max(maxWidths[index] || 0, contentWidth);
                  });
              });
              return maxWidths;
          };

          const setColumnWidths = (ws, maxWidths) => {
              ws['!cols'] = maxWidths.map(width => ({
                  // Adjust width here; you might want to add a constant to account for padding/margins
                  wch: width + 2
              }));
          };
          const maxWidths = calculateMaxWidths(q);
          setColumnWidths(ws, maxWidths);
          const wb = XLSX.utils.book_new();
          const cellStyle = {
              font: {bold: true},
              fill: {
                  fgColor: {rgb: '6AC5AF'}
              }
          };
          const rowsToBold = [1, 4];
          const range = XLSX.utils.decode_range(ws['!ref']);

          rowsToBold.forEach(row => {
              for (let C = range.s.c; C <= range.e.c; ++C) {
                  const address = XLSX.utils.encode_col(C) + row;
                  if (!ws[address]) continue;
                  if (!ws[address].s) ws[address].s = {};
                  Object.assign(ws[address].s, cellStyle);
              }
          });

          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          XLSX.writeFile(wb, this.debouncedSearch + "_" + this.dateRangeText + ".xlsx");
      },
    async searchFunction() {
      if (true) {
        if (!this.firstSearchDone) {
          this.firstSearchDone = true;
          const results = await this.getCampaigns();
          if (results.apiErrors.length > 0) {
            results.apiErrors.forEach((apiError) => {
              this.$notification.error(apiError.message, {
                title: `Errore dal social ${apiError.social} nell'ottenimento delle campagne.`,
                showCloseIcn: true,
                infiniteTimer: true,
                messageIsHTML: true,
              });
            });
          }

          // Assegno il numero di tutti gli elementi delle api
          this.socials.find(
              (social) => social.value === 'fb',
          ).items = this.fbTotCampaigns;
          this.socials.find(
              (social) => social.value === 'ig',
          ).items = this.igTotCampaigns;
          this.socials.find(
              (social) => social.value === 'yt',
          ).items = this.googleTotCampaigns;
          this.socials.find(
              (social) => social.value === 'tk',
          ).items = this.tiktokTotCampaigns;

          this.campaigns = results.campaigns;

          // Miscelo l'array
          /*this.campaigns = results.campaigns.sort(
              () => Math.random() - 0.5,
          );*/

          // Filtro campagne per azienda del manager
          /*if (
              this.$store.getters.ruolo
              !== constants.ROLES.ADMINISTRATOR
          ) {
            this.campaigns = this.campaigns.filter((campaign) => campaign.campaign_name.includes(
                this.$store.getters.currentUser.company_name,
            ));
          }*/

          this.campaigns = this.campaigns.map((item) => ({
            isCampaignDataLoading: true,
            isAdGroupsLoading: true,
            id: item.id,
            selected: false,
            social_name: this.formatSocialName(item),
            platform: this.getSocialName(item.social),
            format: this.formatFormat(item),
            platformRaw: item.social,
            account_id: item.account_id,
            name: this.formatCampaignName(item.campaign_name),
            campaign_name: item.campaign_name,
            start_date: item.start_date,
            starts: item.start_date,
            ends: item.end_date,
            status: this.formatStatus(item.status)
          }));

          this.dataLoaded = true; // Imposto il caricamento a completato
          this.pagesNum = Math.round(
              // Imposto le pagine presenti al numero delle righe presenti diviso
              // gli elementi per pag
              this.campaigns.length / this.itemsPerPage,
          );
        }
        this.recalcCountersCampaigns();
      }
    },
    formatStatus(status) {
        return status ? 'ACTIVE' : 'NOT ACTIVE';
      return ['CAMPAIGN_STATUS_ENABLE', 'ENABLED', 'ACTIVE'].indexOf(status) > -1 ? 'ACTIVE' : 'NOT ACTIVE';
    },
    recalcCountersCampaigns() {
      this.updateFilteredWithSearch();
      const sumFiltered = this.updateFiltered();

      if (sumFiltered !== 0 && this.debouncedSearch !== '') this.calcPagesNum(sumFiltered);
    },
    updateFilteredColumns() {
      this.$store.commit('changeStatusSomethingLoading', true);
      this.updateUser({
        id: this.$store.getters.idUser,
        usermeta: {
          filtered_columns: this.filtered_columns,
        },
      })
        .then((res) => {
          if (String(res.status).match(/^2/)) {
            // eslint-disable-next-line no-param-reassign,no-return-assign
            this.tableHeaders
              .filter((col, i) => i > 3)
              .forEach((header) => {
                header.align = ' d-none';
                header.visible = false;
              }); // Setto tutti gli header a visualizzazione none
            this.filtered_columns.forEach((column) => this.changeHeaders(true, column));

            this.$store.commit('updateUserMeta', {
              filtered_columns: this.filtered_columns,
            });

            this.$emit('updateOk', res.data);
            this.$store.commit('toggleSnackbar', {
              message: 'Operazione effettuata correttamente',
              color: constants.SUCCESS_MESSAGE_SNACKBAR,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          this.$store.commit('toggleSnackbar', {
            message: `L'utente NON è stato modificato correttamente: ${e}`,
            color: constants.ERROR_SNACKBAR,
          });
        })
        .finally(() => {
          this.columnsSelectionModalVisibility = false;
          this.$store.commit('changeStatusSomethingLoading', false);
        });
    },
    isCampaignExpandable(isSelected) {
      return isSelected && this.currentUserRole !== this.manager_basic;
    },
    getSocialName(platform) {
      let platformName = 'N.D.';
      const social = this.socials.filter(
        (innerSocial) => innerSocial.value === platform,
      );
      if (social.length > 0) {
        platformName = social[0].name;
      }
      return platformName;
    },
    formatCampaignName(campaignName) {
      const squareBracketRegex = /\[(.*?)\]/;
      const doubleQuotesRegex = /"(.*?)"/;
      const bracketsRegex = /\((.*?)\)/;

      const squareBracketMatch = campaignName.match(squareBracketRegex);
      const doubleQuotesMatch = campaignName.match(doubleQuotesRegex);
      const bracketsMatch = campaignName.match(bracketsRegex);

      const result = {
        squareBracket: squareBracketMatch ? "[" + squareBracketMatch[1] + "]" : '',
        doubleQuotes: doubleQuotesMatch ? "\"" + doubleQuotesMatch[1] + "\"" : '',
        brackets: bracketsMatch ? "(" + bracketsMatch[1] + ")" : ''
      };

      return [result.squareBracket, result.doubleQuotes, result.brackets].join(" ");
    },
    formatSocialName(item) {
      let str = this.getFormat(item);
      if (str.startsWith("YT ")) {
        return "YouTube";
      } else if (str.startsWith("GGL ")) {
        return "Google";
      } else {
        return this.getSocialName(item.social);
      }
    },
    getFormat(item) {
      const format = item.format;
      const campaignName = item.campaign_name;
      const pipesRegex = /\|(.*?)\|/;
      const pipesMatch = campaignName.match(pipesRegex);
      if (pipesMatch) {
        return pipesMatch[1];
      }
      return format ?? '';
    },
    formatFormat(item) {
      return this.getFormat(item).replace(/IG|FB|YT|GGL/g, '').trim();
    },
    getSocialRawFromName(platformName) {
      if (platformName === 'YouTube') {
        platformName = 'Google';
      }
      let platformRaw = 'N.D.';
      const social = this.socials.filter(
        (innerSocial) => innerSocial.name === platformName,
      );
      if (social.length > 0) {
        platformRaw = social[0].value;
      }
      return platformRaw;
    },
    // isColumnVisible(colName) {
    //  return this.headersWithoutFixed
    //    .filter((item) => item.isVisible)
    //    .map((item) => item.name)
    //    .includes(colName);
    // },
    changeHeaders(e, columnKey) {
      this.tableHeaders.find((i) => i.value === columnKey).align = e
        ? 'start'
        : ' d-none';
      this.tableHeaders.find((i) => i.value === columnKey).visible = e;
    },
    isAdGroupExpanded(item, adGroupId) {
      // Observer
      const indexExpanded = this.customExpanded.findIndex(
        (i) => i.id === item.id,
      );
      return (
        indexExpanded !== -1
                && this.customExpanded[indexExpanded].adGroupsExpanded.length
                    > 0
                && this.customExpanded[indexExpanded].adGroupsExpanded.indexOf(
                  adGroupId,
                ) !== -1
      );
    },
    setCampaignExpanded(isExpanded, expand, item) {
      // clicking on down arrow
      if (isExpanded) expand(false);
      else expand(true);

      const indexCampaignSelected = this.campaigns.findIndex(
        (i) => i.id === item.id,
      );

      if (isExpanded) {
        const indexExpanded = this.expanded.findIndex(
          (i) => i === item,
        );
        this.expanded.splice(indexExpanded, 1);

        const indexCustomExpanded = this.customExpanded.findIndex(
          (i) => i.id === item.id,
        );
        this.customExpanded.splice(indexCustomExpanded, 1);
      } else {
        this.expanded.push(item);
        this.customExpanded.push({ id: item.id, adGroupsExpanded: [] });

        // if (item.isAdGroupsLoading) {
        const social = this.detectSocial(item.platformRaw);
        if (
          ('VUE_APP_MAKE_THIRD_API_CALLS' in process.env
                        && process.env.VUE_APP_MAKE_THIRD_API_CALLS === 'true') // if defined and true
                    || !('VUE_APP_MAKE_THIRD_API_CALLS' in process.env)
        ) {
          this.getAdSetsWithInsights(item.id, {
            social,
            account_id: item.account_id,
            since: this.dates?.[0] ?? null,
            until: this.dates?.[1] ?? null,
          })
            .then((response) => {
              // eslint-disable-next-line no-prototype-builtins
              if (
                response
                                && Object.hasOwn(response, 'data')
                                && response.data.length > 0
                                && typeof response.data[0] === 'object'
              ) {
                const adGroups = response.data[0].data.ad_groups;
                this.campaigns[
                  indexCampaignSelected
                ].adGroups = [];
                adGroups.forEach((adGroup) => {
                  const tmp = [];

                  this.allHeaders
                  // .filter((header, index) => index > 2,)
                    .forEach((headerObj) => {
                      const headerName = Object.keys(
                        headerObj,
                      )[0];

                      tmp[headerName] = headerName in adGroup
                        ? adGroup[headerName]
                        : '-';
                      tmp.id = adGroup.id;
                    });
                  tmp.isAdsLoading = true;
                  this.campaigns[
                    indexCampaignSelected
                  ].adGroups.push(tmp);
                });
              }
              // eslint-disable-next-line no-param-reassign
              this.campaigns[
                indexCampaignSelected
              ].isAdGroupsLoading = false;
              this.$forceUpdate();
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit('toggleSnackbar', {
                message: `Errore nel caricamento dei dati relativi alla campagna selezionata. Errore tecnico: ${e}`,
                color: constants.ERROR_SNACKBAR,
              });
            });
        } else if (
          'VUE_APP_USE_MOCK_FRONTEND_SERVER' in process.env
                    && process.env.VUE_APP_USE_MOCK_FRONTEND_SERVER === 'true'
        ) {
          setTimeout(() => {
            // eslint-disable-next-line no-param-reassign
            item.isAdGroupsLoading = false;
          }, this.randomIntFromInterval(this.minAmountTime, this.maxAmountTime));
        }
        // }
      }
      // eslint-disable-next-line no-param-reassign
    },
    toggleCheckboxGetCampaignsInsights(event, on, item, select) {
      // eslint-disable-next-line no-param-reassign
      item.selected = event;
      select(event);
      if (!event) {
        this.deselectItem(item)
      } else {
        this.updateCampaignValues(item);
      }
    },
      deselectItem(item) {
          // unchecking
          const indexExpanded = this.expanded.findIndex(
              (i) => i === item,
          );

          this.expanded.splice(indexExpanded, 1);

          // this.expanded[indexExpanded].adGroupsExpanded = [];
          this.customExpanded[indexExpanded].adGroupsExpanded = [];
      },
    toggleExpandGroupLevel(item, adGroupId) {
      console.log('adGroupId: ', adGroupId);
      console.log('item: ', item);
      // Happens on click on arrow right on AdGroups
      // For expanding adGroups and get insights of ads
      // Fn used for adding metadata to item in customExpanded

      // Find item via itself in expanded array (it will be for sure because this fn can
      // be called only if campaign is expanded)
      const indexItemInExpanded = this.expanded.findIndex(
        (i) => i === item,
      );

      // Adding metainfo to customExpanded arr with same id (they're pushed in same moment)
      const indexAdGroupSelected = this.expanded[
        indexItemInExpanded
      ].adGroups.findIndex((i) => i.id === adGroupId);
      const indexCampaignSelected = this.campaigns.findIndex(
        (i) => i.id === item.id,
      );
      let indexId = -1;
      if (
        this.customExpanded[indexItemInExpanded].adGroupsExpanded
          .length > 0
                // eslint-disable-next-line no-cond-assign
                && (indexId = this.customExpanded[
                  indexItemInExpanded
                ].adGroupsExpanded.indexOf(adGroupId)) !== -1
      ) {
        // Closing adGroup already expanded
        this.customExpanded[
          indexItemInExpanded
        ].adGroupsExpanded.splice(indexId, 1);
      } else {
        this.customExpanded[indexItemInExpanded].adGroupsExpanded.push(
          adGroupId,
        );
        const social = this.detectSocial(item.platformRaw);
        if (
          ('VUE_APP_MAKE_THIRD_API_CALLS' in process.env
                        && process.env.VUE_APP_MAKE_THIRD_API_CALLS === 'true') // if defined and true
                    || !('VUE_APP_MAKE_THIRD_API_CALLS' in process.env)
        ) {
          this.getAdsWithInsights(item.id, {
            social,
            account_id: item.account_id,
            id_adGroup: adGroupId,
            since: this.dates?.[0] ?? null,
            until: this.dates?.[1] ?? null,
          })
            .then((response) => {
              // eslint-disable-next-line no-prototype-builtins
              const indexAdGroupSelectedC = this.campaigns[
                indexCampaignSelected
              ].adGroups.findIndex((i) => i.id === adGroupId);
              if (
                response
                                && Object.hasOwn(response, 'data')
                                && response.data.length > 0
                                && typeof response.data[0] === 'object'
              ) {
                // eslint-disable-next-line no-param-reassign

                this.campaigns[indexCampaignSelected].adGroups[
                  indexAdGroupSelectedC
                ].ads = [];

                const adsArr = response.data[0].data.ads;
                adsArr.forEach((ad) => {
                  const tmp = [];
                  this.allHeaders
                  // .filter((header, index) => index > 2,)
                    .forEach((headerObj) => {
                      const headerName = Object.keys(
                        headerObj,
                      )[0];

                      tmp[headerName] = headerName in ad
                        ? ad[headerName]
                        : '-';
                      tmp.id = ad.id;
                    });
                  this.campaigns[
                    indexCampaignSelected
                  ].adGroups[indexAdGroupSelectedC].ads.push(
                    tmp,
                  );
                });
              }
              // eslint-disable-next-line no-param-reassign
              this.campaigns[indexCampaignSelected].adGroups[
                indexAdGroupSelectedC
              ].isAdsLoading = false;
              this.$forceUpdate();
            })
            .catch((e) => {
              console.log(e);
              this.$store.commit('toggleSnackbar', {
                message: `Errore nel caricamento dei dati relativi alla campagna selezionata. Errore tecnico: ${e}`,
                color: constants.ERROR_SNACKBAR,
              });
            });
        } else if (
          'VUE_APP_USE_MOCK_FRONTEND_SERVER' in process.env
                    && process.env.VUE_APP_USE_MOCK_FRONTEND_SERVER === 'true'
        ) {
          setTimeout(() => {
            // eslint-disable-next-line no-param-reassign
            item.adGroups[
              indexAdGroupSelected
            ].isAdsLoading = false;
            this.$forceUpdate();
          }, this.randomIntFromInterval(this.minAmountTime, this.maxAmountTime));
        }
      }
    },
    toggleSearch(id) {
      this.tableHeaders[id].isSearchActive = !this.tableHeaders[id]
        .isSearchActive;
      this.tableHeaders[id].valueFilter = '';
    },
    toggleSort(id) {
      this.sortBy = this.headersFiltered[id].value;
      this.sortDesc = !this.sortDesc;
    },
    sortMode(value) {
      if (this.sortBy === value) {
        return this.sortDesc ? '-descending' : '-ascending';
      }
      return '';
    },
    updateFilteredWithSearch() {
      this.socials.forEach((social) => {
        // eslint-disable-next-line no-param-reassign
        social.filteredItems = 0;
      });
      this.campaigns.forEach((campaign) => {
        // Se il nome della campagna include la ricerca

        /*  if (this.socialFilter.length > 0) {
                    // Se ci sono dei filtri abilitati
                     i social dei risultati devono essere anche nei filtri
                } else */
        if (
          campaign.name
            .toLowerCase()
            .trim()
            .includes(this.debouncedSearch.toLowerCase().trim())
        ) {
          switch (
            campaign.platformRaw // Aggiungo l'elemento al numero totale dei filtrati
          ) {
            case 'fb':
              this.socials.find(
                (social) => social.value === 'fb',
              ).filteredItems += 1;
              break;

            case 'ig':
              this.socials.find(
                (social) => social.value === 'ig',
              ).filteredItems += 1;

              break;
            case 'yt':
            case 'gg':
              this.socials.find(
                (social) => social.value === 'yt',
              ).filteredItems += 1;
              break;
            case 'tk':
              this.socials.find(
                (social) => social.value === 'tk',
              ).filteredItems += 1;
              break;
            default:
              break;
          }
        }
      });
    },
    updateFiltered() {
      let sumFiltered = 0;

      // Il num degli elementi è uguale agli elementi dei 3 social se non ci
      // sono filtri, altrimenti devo selezionare gli elementi solo del/dei
      // social filtrati
      if (this.socialFilter.length > 0) {
        // prendo i filtrati dei social selezionati
        this.socials.forEach((social) => {
          // Per ogni social
          if (this.socialFilter.includes(social.value)) sumFiltered += social.filteredItems;
        });
      } else {
        // Sommo tutti gli elementi filtrati dalla ricerca
        this.socials.forEach((social) => {
          // Altrimenti il numero di righe sarà uguale a quello di tutte le
          // righe filtrate dei social
          sumFiltered += social.filteredItems;
        });
      }
      return sumFiltered;
    },
    calcPagesNum(items) {
      this.pagesNum = Math.ceil(items / this.itemsPerPage);
    },
    resetCounters() {
      // Funzione scatenata all'inserimento di un testo (NON ANCORA o al click di un filtro)
      // Funzione che aggiorna il numero degli elementi filtrati (solo se è stata fatta una ricerca)

      // Ogni volta che cerco un testo, resetto gli elementi filtrati

      // Ha senso filtrare solo se il testo è riempito oppure se è stato inserito almeno un filtro
      if (this.debouncedSearch === '' && this.socialFilter.length === 0) this.calcPagesNum(this.calcTotalItems);

      if (!this.dataLoaded) {
        this.socials.forEach((social) => {
          // eslint-disable-next-line no-param-reassign
          social.filteredItems = '-';
        });
        return;
      }

      this.updateFilteredWithSearch();

      const sumFiltered = this.updateFiltered();

      if (sumFiltered !== 0 && this.debouncedSearch !== '') this.calcPagesNum(sumFiltered);
      else if (this.debouncedSearch === '') this.calcPagesNum(this.campaigns.length);
      else if (sumFiltered === 0 && this.debouncedSearch !== '') this.calcPagesNum(0);
    },
    customFilter(value, search, item) {
      return item.campaign_name
          .toLowerCase()
          .includes(search.toLowerCase());
      // Filtro i risultati
      if (this.socialFilter.length > 0) {
        // Se ci sono dei filtri abilitati aggiorno la paginazione,
        // il numero degli elementi sarà uguale a
        return (
          item.campaign_name
            .toLowerCase()
            .includes(search.toLowerCase())
                    && this.socialFilter.includes(item.social.toLowerCase())
        );
      }
      return item.campaign_name
        .toLowerCase()
        .includes(search.toLowerCase());
    },
    toggleElement(el) {
      // Callback che viene richiamato al click di un filtro,
      // inserisce se non presente (altrimenti rimuove) un filtro
      const vm = this;
      const index = vm.socialFilter.indexOf(el);
      if (index > -1) {
        vm.socialFilter.splice(index, 1);
      } else vm.socialFilter.push(el);

      if (vm.socialFilter.length > 0) {
        // Per ogni social abilitato sommo gli elementi filtrati
        let sumFilteredItems = 0;
        this.socials.forEach((social) => {
          // Per ogni social
          if (this.socialFilter.includes(social.value)) {
            if (this.debouncedSearch === '') sumFilteredItems += social.items;
            else sumFilteredItems += social.filteredItems;
          }
        });
        this.calcPagesNum(sumFilteredItems);
      } else {
        // Se nessun filtro è abilitato, gli elementi sono quelli
        let sumFilteredItemsWithoutFilters = 0;
        this.socials.forEach((social) => {
          // Per ogni social
          if (this.debouncedSearch === '') sumFilteredItemsWithoutFilters += social.items;
          else sumFilteredItemsWithoutFilters += social.filteredItems;
        });
        this.calcPagesNum(sumFilteredItemsWithoutFilters);
      }
    },
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    calcWidth(item) {
      switch (item.position) {
        case 0:
          return '350px';
        case 1:
        case 2:
        case 3:
          return '100px';
        default:
          // eslint-disable-next-line no-case-declarations
          return `${Math.max(
            +(50 / (this.tableHeaders.length - 3)).toFixed(2),
            100,
          )}px`;
      }
    },
    detectSocial(platformRaw) {
      let social = '';
      switch (platformRaw) {
        case 'fb':
        case 'ig':
          social = 'facebook';
          break;
        case 'yt':
          social = 'google';
          break;
        case 'tk':
          social = 'tiktok';
          break;
        default:
          social = 'nd';
      }
      return social;
    },
  },
};
</script>

<style scoped>
.grid-container {
    display: inline-grid;
    grid-template-columns: auto;
    width: 100%;
    height: 100%;
    align-items: center;
}

.dashboard-table.table-layout-fixed table {
    table-layout: fixed;
    z-index: 9999;
}

.dashboard-table tbody td {
    white-space: nowrap;
}

.dashboard-table thead th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    white-space: nowrap;
    top: 0;
    background: #eaebf6;
}

.dashboard-table div.v-date-picker-table > table > tbody > tr > td {
  padding: 0 !important;
}

::v-deep .dashboard-table td:first-child,
.dashboard-table th:first-child {
    /* Freccia giu o chbx */
    position: sticky;
    left: 0;
    z-index: 1;
}

::v-deep .dashboard-table td:nth-child(2),
.dashboard-table th:nth-child(2) {
    /* Freccia giu o chbx */
    position: sticky;
    left: 50px;
    z-index: 1;
}

::v-deep .dashboard-table td:nth-child(3),
.dashboard-table th:nth-child(3) {
    /* Nome campagna */
    position: sticky;
    /*left: 10%; /* 20 + 5 (prev) */
    left: 100px;
    z-index: 1;
}

::v-deep .dashboard-table td:nth-child(4),
.dashboard-table th:nth-child(4) {
    /* platform */
    position: sticky;
    /*left:30%; /* 10 + 20 + 5 (prev) */
    left: 450px;
    z-index: 1;
}

::v-deep .dashboard-table td:nth-child(5),
.dashboard-table th:nth-child(5) {
    /* format */
    position: sticky;
    /*left:40%; /* 10 + 10 + 20 + 5 (prev) */
    left: 550px;
    z-index: 1;
}

.dashboard-table th:first-child,
.dashboard-table th:nth-child(2),
.dashboard-table th:nth-child(3),
.dashboard-table th:nth-child(4),
.dashboard-table th:nth-child(5) {
    z-index: 4;
}

::v-deep .dashboard-table td:first-child,
::v-deep .dashboard-table td:nth-child(2),
::v-deep .dashboard-table td:nth-child(3),
::v-deep .dashboard-table td:nth-child(4),
::v-deep .dashboard-table td:nth-child(5) {
    background: white;
    z-index: 3;
}

::v-deep .dashboard-table th:nth-child(5),
::v-deep .dashboard-table td:nth-child(5) {
    border-right: 1px solid #b4b4b4;
}

.v-pagination__item {
    border: 1px solid rgba(39, 35, 35, 0.2);
    border-radius: unset !important;
    box-shadow: unset !important;
}

.v-pagination__navigation i {
    color: #4934c8 !important;
}

.width_min_con {
    width: min-content;
}

.v-application--is-ltr .v-btn-toggle > .v-btn.v-btn:not(:first-child) {
    border-left-width: 1px !important;
}

.custom_ses_input .v-input__append-inner {
    margin-top: 5px !important;
}

.custom_ses_input .v-input__slot {
    height: 35px !important;
    min-height: unset !important;
}

.text-transformation-none {
    text-transform: none;
}

.eb_custom_filtering .v-btn--active,
.eb_custom_filtering .v-btn--active .v-chip__content,
.ses_custom_btn {
    background-color: var(--v-primary-base) !important;
    color: #fff !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.disabled .v-btn {
    background-color: rgb(0, 0, 0, 0.2) !important;
}

.vb > .vb-dragger {
    z-index: 5;
    width: 12px;
    right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
        height 100ms ease-out;
    transition: background-color 100ms ease-out, margin 100ms ease-out,
        height 100ms ease-out;
    background-color: rgb(47 47 47 / 10%);
    /* margin: 5px 5px 5px 0; */
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgb(47 47 47 / 10%);
}

.previewsAlert {
    position: relative;
    margin-left: 320px;
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
    background-color: rgb(47 47 47 / 10%);
    margin: 0px;
    height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(99, 101, 105, 0.5);
    margin: 0px;
    height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(99, 101, 105, 0.5);
}

.custom_dialog {
    box-shadow: unset !important;
    overflow: unset !important;
}

.custom-size {
    height: 26px;
    width: auto;
    padding: 0.3rem 0 0.3rem 0;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

li {
    padding: 0.3rem 0 0.3rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.li-custom-size {
    padding-left: 50px;
}

.v-application ul {
    padding-left: 0 !important;
}

/* CLASSI PER BOTTONI */
.fb-btn,
.ig-btn,
.yt-btn,
.tk-btn {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.fb-btn-icon {
    color: #0671fe !important;
}
.fb-btn-counter {
    background: #0668e1 !important ;
    background-color: rgba(6, 104, 225, 5%) !important;
    color: #0671fe !important;
}
.ig-btn-icon {
    color: #d066a2 !important;
}
.yt-btn-icon {
    color: #ff0000 !important;
}
.tk-btn-icon {
    color: #69c9d0 !important;
}
.ig-btn-counter {
    background: #f56040 !important;
    background-color: rgba(225, 0, 0, 5%) !important;
    color: #f56040 !important;
}
.yt-btn-icon {
    color: #4285f4 !important;
}
.yt-btn-counter {
    background: #ff0000 !important;
    background-color: rgba(225, 0, 0, 5%) !important;
    color: #e10606 !important;
}

.tk-btn-icon {
    color: #00b0ab !important;
}
.tk-btn-counter {
    background: #00b0ab !important;
    background-color: rgba(0, 208, 202, 5%) !important;
    color: #00b0ab !important;
}

.fb-btn:hover,
.fb-active,
.fb-btn:active {
    background-color: #0671fe !important;
    font-size: 16px;
    font-weight: 600;
    color: white !important;
}
.ig-btn:hover,
.ig-active,
.ig-btn:active {
    background-color: #d066a2 !important;
}

.yt-btn:hover,
.yt-active,
.yt-btn::selection {
    background-color: #ff0000 !important;
    font-size: 16px;
    font-weight: 600;
    color: white !important;
}

.tk-btn:hover,
.tk-active,
.tk-btn::selection {
    font-size: 16px;
    font-weight: 600;
    background-color: #00b0ab !important;
    color: white !important;
}

.fb-btn:hover .fb-btn-icon,
.ig-btn:hover .ig-btn-icon,
.yt-btn:hover .yt-btn-icon,
.tk-btn:hover .tk-btn-icon,
.fb-active .fb-btn-icon,
.ig-active .ig-btn-icon,
.yt-active .yt-btn-icon,
.tk-active .tk-btn-icon {
    color: white !important;
}

.counter-btn-color:hover,
.counter-btn-color:active {
    background-color: rgba(0, 0, 0, 10%) !important;
    color: white !important;
}

.customgreen-color {
    color: #05ffb4 !important;
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
</style>
